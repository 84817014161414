import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import SVG from "react-inlinesvg";

import {
  getUserPersonalData,
  deleteUserFavoritesRequest,
} from "../../API/userActions";
import { getDetailedCardRequest } from "../../API/cardsAction";

import Form from "../../Components/Main/Form/Form";
import Header from "../../Components/Main/Header/Header";
import Footer from "../../Components/Main/Footer/Footer";

import IconDeleteSVG from "../../static/images/iconClick_actions/delete.svg";
import "./PersonalAccout.scss";

const btnStyle = {
  width: "116px",
  height: "40px",
  margin: 0,
};

const PersonalAccout = () => {
  const [loadingStarted, toggleLoadingStart] = useState(false);
  const [formValues, setFormValues] = useState({});

  const [userFavorites, setUserFavorites] = useState([]);

  useEffect(() => {
    toggleLoadingStart(true);
    // получаем данные пользователя в форму
    getUserPersonalData()
      .then((response) => {
        const user = response.data;
        const stateUser = {
          id: user._id,
          name: user.name,
          email: user.email,
          phone: user.phone,
          password: "",
          favorites: user.favorites,
        };
        setFormValues(stateUser);
        return stateUser;
      })
      .then(async (stateUser) => {
        // по id избранных карточек получает объект даты и записываем в стейт
        // ловим ошибку до выполнения промиса
        //(чтобы не ругался если вдруг какая-то карточка была ранее удалена, но при этом добавлена в избранное)
        const arr = stateUser.favorites.map((item, index) =>
          getDetailedCardRequest(item)
            .then((response) => {
              return response.data;
            })
            .catch((error) => console.log(error))
        );
        return await Promise.all(arr);
      })
      .then((result) => {
        setUserFavorites(result);
        toggleLoadingStart(false);
      })
      .catch((error) => {
        console.log(error);
      });
  }, []);

  // проверяем тип карточки которая прилетела (для перехода поссылке на детализир карточку)
  const determinePageOfCard = (favoritesType) => {
    if (favoritesType === "secondary") {
      return "flats";
    } else if (favoritesType === "house") {
      return "houses";
    } else if (favoritesType === "commercial") {
      return "commercial";
    } else if (favoritesType === "primary") {
      return "primary";
    }
  };

  //удаляем объект из избранного
  const deleteUserFavorites = (cardID, index) => {
    deleteUserFavoritesRequest(cardID).then((response) => {
      // после удаления проверем методом фильтер совпадают ли индексы -условие пройдено ? вернет true и оставит в списке : false - удалит
      const newFavorites = userFavorites.filter(
        (stateItem, stateIndex) => stateIndex !== index
      );
      setUserFavorites(newFavorites);
    });
  };

  return (
    <div className="page-container">
      <Header />
      <div className="container">
        <div className="account-wrapper">
          <div className="account-data">
            <Form
              type={"personalAccount"}
              btnText={"Сохранить"}
              linkText={"Отменить"}
              formHeader={"Личный кабинет"}
              submitToGlobal={"changeUserData"}
              goToPage={"cancelInput"}
              formValues={formValues}
              btnStyle={btnStyle}
            />
          </div>
          <div className="account-history">
            <h2 className="account__header">Избранное</h2>
            <div className="account__favorites">
              {loadingStarted ? (
                <p className="loader">Loading...</p>
              ) : (
                // eslint-disable-next-line
                userFavorites.map((item, index) => {
                  if (item !== undefined) {
                    return (
                      <div className="favorites__wrapper" key={index}>
                        <Link
                          className="favorites__link"
                          to={{
                            pathname:
                              determinePageOfCard(item.type) + `/${item._id}`,
                          }}
                        >
                          {item.name}
                        </Link>
                        <SVG
                          style={{ cursor: "pointer" }}
                          src={IconDeleteSVG}
                          onClick={() => deleteUserFavorites(item._id, index)}
                        />
                      </div>
                    );
                  }
                })
              )}
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </div>
  );
};

export default PersonalAccout;

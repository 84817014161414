const validate = (values) => {
  let errors = {};
  if (!values.phone) {
    errors.phone = "Обязательное поле";
  } else if (!/^\+380\d{3}\d{2}\d{2}\d{2}$/.test(values.phone)) {
    errors.phone =
      "Неверный формат номера. Пример верного номера: +380670000000";
  }

  if (!values.email) {
    errors.email = "Обязательное поле";
  } else if (!/\S+@\S+\.\S+/.test(values.email)) {
    errors.email =
      "Неверный формат почты. Пример верного формата: name@mail.com";
  }

  if (!values.name) {
    errors.name = "Обязательное поле";
  } else if (values.name.length < 2) {
    errors.name = "Имя слишком короткое";
  } else if (values.name.length > 30) {
    errors.name = "Имя слишком длинное";
  }

  return errors;
};

export default validate;

import React from "react";
import { useHistory } from "react-router-dom";

import Button from "../Button/Button";

import "./VacancySlides.scss";

function VacancySlides({ vacancy }) {
  const history = useHistory();

  return (
    <div className="vacancy-content">
      <h2 className="vacancy-content__title">{vacancy.name}</h2>
      <p className="vacancy-content__text">{vacancy.text}</p>
      <Button
        className="btn-log"
        type="button"
        text={"Связаться"}
        onClick={() => {
          history.push({
            pathname: "company/vacancy",
            state: { name: vacancy.name, text: vacancy.text }
          });
        }}
      />
    </div>
  );
}

export default VacancySlides;

import React, {useEffect, useState} from "react";
import Select from "react-select";

import Filter from "./Filter";

import {getDistrictsList, getStreetsList, getSubDistrictsList,} from "../../../../API/filtersActions";

import {customStyles} from "../SelectCustomStyles";
import "../SelectForm.scss";

const RenderFilterAddress = ({handleSelected, emptyfyAll, initalState, selected}) => {
    const defaultSelect = {
        label: "Выберите",
        value: undefined,
    };

    let [storageSelected, setStorageSelected] = useState({});
    const [districtOptions, setDistrictOptions] = useState([]);
    const [subDistrictOptions, setSubdistrictOptions] = useState([]);
    const [streetOptions, setStreetOptions] = useState([]);

    const [districtFilters, setDistrictFilters] = useState(defaultSelect);
    const [subDistrictFilters, setSubdistrictFilters] = useState(defaultSelect);
    const [streetFilters, setStreetFilters] = useState(defaultSelect);

    // передаем данные о выбранном в родительский компонент -
    // handleSelected(filterGroup, filterName, filterNewValue)
    useEffect(() => {
        if (districtFilters.value) {
            handleSelected("public", "district", districtFilters.value);
        }
        if (subDistrictFilters.value) {
            handleSelected("public", "subDistrict", subDistrictFilters.value);
        }
        if (streetFilters.value) {
            handleSelected("public", "street", streetFilters.label);
        }

        // eslint-disable-next-line
    }, [districtFilters, subDistrictFilters, streetFilters]);

    // очищаем все селекты если emptyfyAll из пропсов = true (если пользователь кликнул на кнопку отмена)
    useEffect(() => {
        return () => {
            setDistrictFilters(defaultSelect);
            setSubdistrictFilters(defaultSelect);
            setStreetFilters(defaultSelect);
            handleSelected("public", "district", undefined);
            handleSelected("public", "subDistrict", undefined);
            handleSelected("public", "street", undefined);
        };
        // eslint-disable-next-line
    }, [emptyfyAll]);

    // const defaultSelected = selected.public.district;

    useEffect(() => {
        if (sessionStorage.getItem('filter')) {
            storageSelected = JSON.parse(sessionStorage.getItem('filter'));
            setStorageSelected(storageSelected)
        }

        getDistrictsList()
            .then((response) => {
                return response.data.map((item) => {
                    return {
                        label: item.name,
                        value: item._id,
                    };
                });
            })
            .then((options) => {
                setDistrictOptions(options);

                if (storageSelected.public !== undefined && storageSelected.public.district !== undefined) {
                    //console.log('DISTRICT', storageSelected.public.district);
                    let district = options.find((item) => {
                        return item.value === storageSelected.public.district
                    })
                    if (district !== undefined) {
                        setDistrictFilters(district);
                    }
                }
            });
    }, []);

    useEffect(() => {
        if (initalState) {
            setDistrictFilters({
                label: initalState.name,
                value: initalState._id,
            });
        }
    }, [initalState]);

    useEffect(() => {
        if (districtFilters.value) {
            getSubDistrictsList(districtFilters.value)
                .then((response) => {
                    return response.data.map((item) => {
                        return {
                            label: item.name,
                            value: item._id,
                        };
                    });
                })
                .then((options) => {
                    setSubdistrictOptions(options);

                    if (storageSelected.public !== undefined && storageSelected.public.subDistrict !== undefined) {
                        //console.log('SUBDISTRICT', storageSelected.public.subDistrict);
                        let subDistrict = options.find((item) => {
                            return item.value === storageSelected.public.subDistrict
                        })
                        if (subDistrict !== undefined) {
                            setSubdistrictFilters(subDistrict);
                        }
                    }
                });
        }
    }, [districtFilters.value]);

    useEffect(() => {
        if (districtFilters.value) {
            getStreetsList(districtFilters.value, subDistrictFilters.value)
                .then((response) => {
                    return response.data.map((item, index) => {
                        return {
                            label: item,
                            value: index,
                        };
                    });
                })
                .then((options) => {
                    setStreetOptions(options);

                    if (storageSelected.public !== undefined && storageSelected.public.street !== undefined) {
                        //console.log('STREET', storageSelected.public.street);
                        let street = options.find((item) => {
                            return item.label === storageSelected.public.street
                        })
                        if (street !== undefined) {
                            setStreetFilters(street);
                        }
                    }
                });
        }
    }, [districtFilters.value, subDistrictFilters.value]);

    return (
        <form className="select">
            <div className="select__input">
                <label className="select__input__label">Район</label>
                <Select
                    placeholder={"Выберите"}
                    isClearable
                    value={districtFilters}
                    options={districtOptions}
                    onChange={(district) => {
                        if (district) {
                            setDistrictFilters(district);
                        } else {
                            setDistrictFilters(defaultSelect);
                            handleSelected("public", "district", undefined);
                        }
                    }}
                    styles={customStyles}
                />
            </div>
            <div className="select__input">
                <label className="select__input__label">Микрорайон</label>
                {districtFilters.value ? (
                    <Filter
                        value={subDistrictFilters}
                        options={subDistrictOptions}
                        onChange={(subDistrict) => {
                            if (subDistrict) {
                                setSubdistrictFilters(subDistrict);
                            } else {
                                setSubdistrictFilters(defaultSelect);
                            }
                        }}
                    />
                ) : (
                    <Select
                        isDisabled={true}
                        placeholder={"Выберите"}
                        styles={customStyles}
                    />
                )}
            </div>
            <div className="select__input">
                <label className="select__input__label">Улица</label>
                {districtFilters.value ? (
                    <Filter
                        value={streetFilters}
                        options={streetOptions}
                        onChange={(street) => {
                            if (street) {
                                setStreetFilters(street);
                            } else {
                                setStreetFilters(defaultSelect);
                            }
                        }}
                    />
                ) : (
                    <Select
                        isDisabled={true}
                        placeholder={"Выберите"}
                        styles={customStyles}
                    />
                )}
            </div>
        </form>
    );
};

export default RenderFilterAddress;

import React, { useState, useEffect } from "react";
import SVG from "react-inlinesvg";
import useWindowWidth from "../../CustomHook/useWindowWidth";

import Header from "../../Components/Main/Header/Header";
import Footer from "../../Components/Main/Footer/Footer";
import Button from "../../Components/Generic/Button/Button";
import ComplexCard from "./ComplexCard";
import MapContainer from "../../Components/Main/Map/MapContainer";

import RenderFilterComplex from "../../Components/Main/SelectForm/RenderFilterComplex/RenderFilterComplex";

import IconFilterUpSVG from "../../static/images/iconClick_actions/filter_up.svg";
import IconFilterDownSVG from "../../static/images/iconClick_actions/filter_down.svg";
import IconFiltersBtnSVG from "../../static/images/iconClick_actions/filters_btn.svg";

import { getAllComplexesRequest } from "../../API/complexesActions";

import "./ComplexAndFilters.scss";

const cardStyles = {
  display: "flex",
  flexDirection: "column",
};


const changeSortIconsColor = (sortBy, state, sortType) => {
  const sort = state.list.sort;

  const result =
    sort.by === sortBy
      ? sort.type === sortType
      ? { fill: "black" }
      : { fill: "grey" }
      : { fill: "grey" };

  return result;
};

const ComplexAndFilters = ({ type, link, publicType, state }) => {
  const defaultSelectedOptions = {
    list: {
      amount: 10,
      sort: {
        by: undefined,
        type: undefined,
      },
    },
    filters: {},
    public: {
      district: (state && state.district)?state.district:undefined,
      isNewComplex: (state && state.publicFilter=="isNewComplex")?true:undefined,
      isComplete: (state && state.publicFilter=="isComplete")?true:undefined,
      isElite: (state && state.publicFilter=="isElite")?true:undefined,
      isCredit: (state && state.publicFilter=="isCredit")?true:undefined,
      isInexpensive: (state && state.publicFilter=="isInexpensive")?true:undefined,
      isPromotion: (state && state.publicFilter=="isPromotion")?true:undefined,
    },
    ranges: {},
  };

  const [cardsForRender, setCardsForRender] = useState([]);
  const [loadingStarted, toggleLoadingStart] = useState(false);
  const [mapContainerData, setMapContainerData] = useState([]);
  const [selected, setSelected] = useState(defaultSelectedOptions);
  const windowWidth = useWindowWidth();
  const [mapVisible, setMapVisible] = useState(false);
  const [filtersVisible, setFiltersVisible] = useState(false);
  const [showMoreFilters, setShowMoreFilters] = useState(false);
  const [emptyfyAll, setEmptyfyAll] = useState(false);


  const handleSelected = (filterGroup, filterName, filterNewValue) => {
    // const newObject = JSON.parse(JSON.stringify(selected));
    // newObject[filterGroup][filterName] = filterNewValue;
    // setSelected(newObject);
    setSelected((prevState) => ({
      ...prevState,
      [filterGroup]: {
        ...prevState[filterGroup],
        [filterName]: filterNewValue,
      },
    }));
  };

  const getNewCardsData = (options) => {
    return getAllComplexesRequest(options)
      .then((response) => {
        let data = response.data;
        setCardsForRender(data);
        return data;
      })

      .then((data) => {
        /*
        const mapData = data.map(
          ({ _id, name, coordinates, district, subDistrict, street }) => {
            return {
              pathname: `${link}/${_id}`,
              name: name,
              address: district + " " + subDistrict + " " + street,
              coordinates: coordinates,
            };
          }
        );
        setMapContainerData(mapData);
         */
      });
  };

  const useFilters = () => {
    if (windowWidth < 992) {
      getNewCardsData(selected);
      setFiltersVisible(false);
    } else {
      getNewCardsData(selected);
    }
  };

  const useFiltersType = (t) => {
    setSelected((prevState) => ({
      ...prevState,
      public: {
        ...prevState['public'],
        isNewComplex: undefined,
        isComplete: undefined,
        isPromotion: undefined,
        isRenovated: undefined,
        [t]: true,
      },
    }));

  };

  const useFiltersTypeNew = () => {
    useFiltersType('isNewComplex');
  }

  const useFiltersTypeComplete = () => {
    useFiltersType('isComplete');
  }
  const useFiltersTypePromotion = () => {
    useFiltersType('isPromotion');
  }
  const useFiltersTypeRenovated = () => {
    useFiltersType('isRenovated');
  }

  const sortFunction = (event, sortBy, state, setState) => {
    const list = state.list;
    const sort = state.list.sort;
      if (sort.by !== sortBy) {
        setState({
          ...state,
          list: { ...list, sort: { by: sortBy, type: 1 } },
        });
        return;
      }
      if (sort.type === 1) {
        setState({
          ...state,
          list: { ...list, sort: { by: sortBy, type: -1 } },
        });
        return;
      }
      if (sort.type === -1) {
        setState({
          ...state,
          list: { ...list, sort: { by: undefined, type: undefined } },
        });
        return;
      }
  };

  useEffect(() => {
    toggleLoadingStart(true);
    getNewCardsData(selected).then(() => {
      toggleLoadingStart(false);
    });
    // eslint-disable-next-line
  }, [selected.list.sort, selected.public]);

  useEffect(() => {
    toggleLoadingStart(true);
    getNewCardsData(selected).then(() => {
      toggleLoadingStart(false);
    });
    // eslint-disable-next-line
  }, []);

  const toggleMapVisibility = () => {
    setMapVisible(!mapVisible);
  };

  const toggleFiltersVisibility = () => {
    setFiltersVisible(!filtersVisible);
  };

  const toggleShowMoreFilters = () => {
    setShowMoreFilters(!showMoreFilters);
  };

  const emptyfySelectedFilters = () => {
    setSelected(defaultSelectedOptions);
    getNewCardsData(defaultSelectedOptions);
    setEmptyfyAll(!emptyfyAll);
    setFiltersVisible(false);
  };

  return (
    <div>
      <Header />
      <div className="page-container">
        <div className="container">
          <div
            className="wrapper__btn-mob"
            style={
              windowWidth > 992 ? { display: "none" } : { display: "flex" }
            }
          >
          </div>
        </div>
        <div
          className="filters-wrapper"
          style={
            !filtersVisible && windowWidth < 992
              ? { display: "none" }
              : filtersVisible && windowWidth < 992
              ? { display: "block" }
              : { display: "block" }
          }
        >
          <div className="filters">
            <RenderFilterComplex
              handleSelected={handleSelected}
              type={type}
              emptyfyAll={emptyfyAll}
              initalState={(state && state.district)?state.district:null}
            />
            <Button
              className="btn-big btn-big--filter"
              text={"Сброс"}
              onClick={emptyfySelectedFilters}
            />
          </div>
        </div>
        <div className="content-container">
          {loadingStarted ? (
            <p className="loader-full">Loading...</p>
          ) : (
            <div className="cards-map">
              <div
                className="cards-wrapper-complex"
              >
                {loadingStarted ? null : (
                  <div>
                    <div className="card-results">
                      <div className="results-text">
                        <p>Результаты:</p>
                      </div>
                      <div>
                        <Button
                          className="btn-filter btn--filter--blue"
                          text={"НОВЫЕ"}
                          onClick={useFiltersTypeNew}
                        />
                        <Button
                          className="btn-filter btn--filter--green"
                          text={"СДАННЫЕ"}
                          onClick={useFiltersTypeComplete}
                        />
                        <Button
                          className="btn-filter btn--filter--red"
                          text={"АКЦИОННАЯ ПРОДАЖА"}
                          onClick={useFiltersTypePromotion}
                        />
                        <Button
                          className="btn-filter btn--filter--orange"
                          text={"КВАРТИРЫ С РЕМОНТОМ"}
                          onClick={useFiltersTypeRenovated}
                        />
                      </div>
                      <div className="card-results__sort">
                        <p>Сортировать по</p>
                        <div
                          className="sort__item"
                          onClick={(event) =>
                            sortFunction(event, "price", selected, setSelected)
                          }
                        >
                          <div
                            className="filter__icons"
                            style={{
                              cursor: "pointer",
                            }}
                          >
                            <SVG
                              src={IconFilterUpSVG}
                              style={changeSortIconsColor("price", selected, 1)}
                            />
                            <SVG
                              src={IconFilterDownSVG}
                              style={changeSortIconsColor(
                                "price",
                                selected,
                                -1
                              )}
                            />
                          </div>
                          <p>Цена</p>
                        </div>
                        <div
                          className="sort__item "
                          onClick={(event) =>
                            sortFunction(event, "date", selected, setSelected)
                          }
                        >
                          <div className="filter__icons">
                            <SVG
                              src={IconFilterUpSVG}
                              style={changeSortIconsColor("date", selected, 1)}
                            />
                            <SVG
                              src={IconFilterDownSVG}
                              style={changeSortIconsColor("date", selected, -1)}
                            />
                          </div>
                          <p>Дата</p>
                        </div>
                      </div>
                    </div>
                    <div className="card-content-complex">
                      {cardsForRender
                        ? cardsForRender.map(function (item, index) {
                          return (
                            <ComplexCard
                              key={index}
                              card={item}
                              link={link}
                              publicType={publicType}
                              cardStyles={cardStyles}
                            />
                          );
                        })
                        : null}
                      <Button
                        className="btn-small"
                        text={"Показать еще"}
                        onClick={() =>
                          handleSelected(
                            "list",
                            "amount",
                            selected.list.amount + 10
                          )
                        }
                      />
                    </div>
                  </div>
                )}
              </div>
            </div>
          )}
        </div>
        <Footer />
      </div>
    </div>
  );
};

export default ComplexAndFilters;

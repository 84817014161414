import React from "react";
import { Link } from "react-router-dom";

const styles = {
  wrapper: {
    maxWidth: "370px",
    paddingTop: "200px",
    margin: "0 auto"
  },

  header: {
    fontSize: "36px",
    color: "#D97D34",
    marginBottom: "30px"
  },

  link: {
    fontSize: "20px",
    color: "#2F2F2F"
  }
};

const NoMatch = () => {
  return (
    <div className="container">
      <div className="no-match" style={styles.wrapper}>
        <h2 className="no-match__header" style={styles.header}>
          404 page not found
        </h2>
        <Link className="no-match__link" style={styles.link} to="/">
          Back to main
        </Link>
      </div>
    </div>
  );
};

export default NoMatch;

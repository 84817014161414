import React, { useState, useEffect } from "react";
import Select from "react-select";

import Filter from "./Filter";

import {
  getDistrictsList,
  getSubDistrictsList,
  getStreetsComplexList,
  getStreetsList
} from "../../../../API/filtersActions";

import { customStyles } from "../SelectCustomStyles";
import "../SelectForm.scss";

const RenderFilterAddress = ({ handleSelected, emptyfyAll, initalState, flats, children }) => {
  const defaultSelect = {
    label: "Выберите",
    value: undefined,
  };

  const [districtOptions, setDistrictOptions] = useState([]);
  const [subDistrictOptions, setSubDistrictOptions] = useState([]);
  const [streetOptions, setStreetOptions] = useState([]);
  const [districtFilters, setDistrictFilters] = useState(defaultSelect);
  const [subDistrictFilters, setSubdistrictFilters] = useState(defaultSelect);
  const [streetFilters, setStreetFilters] = useState(defaultSelect);

  // передаем данные о выбранном в родительский компонент -
  // handleSelected(filterGroup, filterName, filterNewValue)
  useEffect(() => {
    if (districtFilters.value)
      handleSelected("public", "district", districtFilters.value);
    if (subDistrictFilters.value)
      handleSelected("public", "subDistrict", subDistrictFilters.value);
    if (streetFilters.value)
      handleSelected("public", "street", streetFilters.label);

    // eslint-disable-next-line
  }, [districtFilters, subDistrictFilters, streetFilters]);

  // очищаем все селекты если emptyfyAll из пропсов = true (если пользователь кликнул на кнопку отмена)
  useEffect(() => {
    return () => {
      setDistrictFilters(defaultSelect);
      setSubdistrictFilters(defaultSelect);
      setStreetFilters(defaultSelect);
      handleSelected("public", "district", undefined);
      handleSelected("public", "subDistrict", undefined);
      handleSelected("public", "street", undefined);
    };
    // eslint-disable-next-line
  }, [emptyfyAll]);

  // const defaultSelected = selected.public.district;

  useEffect(() => {
    getDistrictsList()
      .then((response) => {
        const result = response.data.map((item) => {
          return {
            label: item.name,
            value: item._id,
          };
        });
        return result;
      })
      .then((options) => {
        setDistrictOptions(options);
      });
  }, []);

  useEffect(() => {
    if (initalState) {
      setDistrictFilters({
        label: initalState.name,
        value: initalState._id,
      });
    }
  }, [initalState]);

  useEffect(() => {
    if (districtFilters.value)
      getSubDistrictsList(districtFilters.value)
        .then((response) => {
          const result = response.data.map((item) => {
            return {
              label: item.name,
              value: item._id,
            };
          });
          return result;
        })
        .then((options) => {
          setSubDistrictOptions(options);
        });
  }, [districtFilters.value]);

  useEffect(() => {
    if (districtFilters.value)
      if (flats) {
        getStreetsList(districtFilters.value, subDistrictFilters.value)
          .then((response) => {
            const result = response.data.map((item, index) => {
              return {
                label: item,
                value: index,
              };
            });
            return result;
          })
          .then((options) => {
            setStreetOptions(options);
          });
      } else {
        getStreetsComplexList(districtFilters.value, subDistrictFilters.value)
          .then((response) => {
            const result = response.data.map((item, index) => {
              return {
                label: item,
                value: index,
              };
            });
            return result;
          })
          .then((options) => {
            setStreetOptions(options);
          });
      }
  }, [districtFilters.value, subDistrictFilters.value]);

  return (
    <div className="select__complex">
      <div className="select__input">
        <label className="select__input__label">Район</label>
        <Select
          placeholder={"Выберите"}
          isClearable
          value={districtFilters}
          options={districtOptions}
          onChange={(district) => {
            if (district) {
              setDistrictFilters(district);
            } else {
              setDistrictFilters(defaultSelect);
              handleSelected("public", "district", undefined);
            }
          }}
          styles={customStyles}
        />
      </div>
      <div className="select__input">
        <label className="select__input__label">Микрорайон</label>
        {districtFilters.value ? (
          <Filter
            value={subDistrictFilters}
            options={subDistrictOptions}
            onChange={(subDistrict) => {
              if (subDistrict) {
                setSubdistrictFilters(subDistrict);
              } else {
                setSubdistrictFilters(defaultSelect);
              }
            }}
          />
        ) : (
          <Select
            isDisabled={true}
            placeholder={"Выберите"}
            styles={customStyles}
          />
        )}
      </div>
      <div className="select__input">
        <label className="select__input__label">Улица</label>
        {districtFilters.value ? (
          <Filter
            value={streetFilters}
            options={streetOptions}
            onChange={(street) => {
              if (street) {
                setStreetFilters(street);
              } else {
                setStreetFilters(defaultSelect);
              }
            }}
          />
        ) : (
          <Select
            isDisabled={true}
            placeholder={"Выберите"}
            styles={customStyles}
          />
        )}
      </div>
      {children}
    </div>
  );
};

export default RenderFilterAddress;

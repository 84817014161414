import React, { useState, useEffect } from "react";
import Select from "react-select";
import { useHistory } from "react-router-dom";

import Header from "../../AdminGeneric/Header/AdminHeader";
import Menu from "../../AdminGeneric/Menu/AdminMenu";
import RowHeader from "../../AdminGeneric/Table/RowHeader";
import Row from "../../AdminGeneric/Table/Row";
import Button from "../../../Components/Generic/Button/Button";

import {
  getAllVacanciesRequest,
  updateVacancyRequest,
} from "../../../API/vacanciesAction";

import { customStyles } from "../SelectCustomStyles";

const styles = {
  bntStyle: {
    margin: "16px 0 16px 0",
  },

  rowStyle: {
    marginRight: "5%",
  },
};

const statusOptions = [
  { value: true, label: "Сделать актуальной" },
  { value: false, label: "Закрыть" },
  { value: "edit", label: "Изменить" },
];

const Vacancies = (props) => {
  const [loadingStarted, toggleLoadingStart] = useState(false);
  const [allVacancies, setAllVacancies] = useState([]);
  const [requestObject, setRequestObject] = useState({
    list: {
      sort: {
        by: undefined,
        type: undefined,
      },
    },
    public: {},
  });
  const history = useHistory();

  useEffect(() => {
    toggleLoadingStart(true);
    getAllVacanciesRequest(requestObject)
      .then((response) => {
        setAllVacancies(response.data);
      })
      .then(() => {
        toggleLoadingStart(false);
      });
  }, [requestObject]);

  const handleSelected = (filterGroup, filterName, filterNewValue) => {
    const newObject = JSON.parse(JSON.stringify(requestObject));

    newObject[filterGroup][filterName] = filterNewValue;
    setRequestObject(newObject);
  };

  // изменение вакансии либо ее статуса (активна\неактивна)
  const selectedOption = (vacancy, index, event) => {
    if (event.value === "edit") {
      history.push({
        pathname: "/admin/edit-vacancy",
        state: {
          vacancyBody: vacancy,
          submitFunction: "updateVacancyRequest",
          request: "editVacancy",
        },
      });
    } else {
      const newVacancy = {
        ...vacancy,
        active: event.value,
        name: vacancy.name,
        text: vacancy.text,
      };
      updateVacancyRequest(vacancy._id, newVacancy)
        .then(() => {
          const newVacanciesArr = JSON.parse(JSON.stringify(allVacancies));
          newVacanciesArr[index] = newVacancy;
          setAllVacancies(newVacanciesArr);
        })
        .catch((err) => console.log(err));
    }
  };

  // Переход на добавление новой вакансии
  const goToAddingVacancy = () => {
    history.push({
      pathname: "/admin/add-vacancy",
      state: {
        vacancyBody: { active: true, name: ``, text: `` },
        submitFunction: "createVacancyRequest",
      },
    });
  };

  const handleVacancyDelete = (index) => {
    const newArr = allVacancies.filter(
      (item, vacancyindex) => vacancyindex !== index
    );
    setAllVacancies(newArr);
  };

  return (
    <div>
      <Header />
      <div className="admin-wrapper">
        <Menu />
        <div className="admin-content">
          <Button
            className="adminBtn--add"
            type="submit"
            text={"Добавить"}
            style={styles.bntStyle}
            onClick={goToAddingVacancy}
          />
          <RowHeader
            pageType={"vacancy"}
            state={requestObject}
            setState={setRequestObject}
          />
          {loadingStarted ? (
            <p className="loader-admin">Loading...</p>
          ) : (
            allVacancies.map(function (item, index) {
              return (
                <Row
                  pageType={"vacancy"}
                  key={index}
                  id={item._id}
                  index={index}
                  publicId={item.publicId}
                  name={item.name}
                  date={item.createdDate}
                  handleDelete={handleVacancyDelete}
                >
                  <p></p>
                  <Button
                    className="adminBtn--condition"
                    style={
                      item.active === false
                        ? {
                            background: "#ED1C24",
                            display: "flex",
                            justifyContent: "center",
                          }
                        : {
                            background: "#36AF47",
                            display: "flex",
                            justifyContent: "center",
                          }
                    }
                    text={item.active === false ? "Закрыта" : "Актуальна"}
                  />

                  <p></p>
                  <Select
                    className="adminBtn--action"
                    placeholder={"Действия"}
                    styles={customStyles}
                    onChange={(event) => selectedOption(item, index, event)}
                    options={statusOptions}
                  />
                </Row>
              );
            })
          )}

          <Button
            className="adminBtn--delete"
            style={{
              background: "#7F8FA4",
              marginRight: 0,
              marginTop: "20px",
              width: "106px",
            }}
            text={"Показать еще"}
            onClick={() =>
              handleSelected("list", "amount", requestObject.list.amount + 10)
            }
          />
        </div>
      </div>
    </div>
  );
};

export default Vacancies;

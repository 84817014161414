import React from "react";
import { NavLink } from "react-router-dom";

import Logo from "../../../Components/Generic/Logo/Logo";
import SVG from "react-inlinesvg";

import IconUserSVG from "../../../static/images/user.svg";

const styles = {
  logoTelStyle: {
    display: "none"
  },

  logoStyle: {
    paddingTop: "22px"
  }
};

const AdminHeader = props => {
  return (
    <div className="header--admin">
      <Logo style={styles.logoTelStyle} logoStyle={styles.logoStyle} />{" "}
      <NavLink to="/account">
        <SVG className="menu__link__svg" src={IconUserSVG} />
      </NavLink>
    </div>
  );
};

export default AdminHeader;

import axios from "axios";

import config from "./config";

const vacancyEndpoint = "vacancies/";

const myStorage = window.localStorage;

// получение всех
export const getAllVacanciesRequest = (data) => {
  return axios.post(config.url + vacancyEndpoint, data);
};

// создание новой
export const createVacancyRequest = (data) => {
  const token = myStorage.getItem("access-token");
  if (!token) return;
  return axios.post(config.url + vacancyEndpoint + "create", data, {
    headers: { Authorization: "Bearer " + token },
  });
};

// обновление - активна / нет / изменение вакансии
export const updateVacancyRequest = (vacancyId, data) => {
  const token = myStorage.getItem("access-token");
  if (!token) return;
  return axios.put(config.url + vacancyEndpoint + vacancyId, data, {
    headers: { Authorization: "Bearer " + token },
  });
};

// удаление вакансии
export const deleteVacancyRequest = (vacancyId) => {
  const token = myStorage.getItem("access-token");
  if (!token) return;
  return axios.delete(config.url + vacancyEndpoint + vacancyId, {
    headers: { Authorization: "Bearer " + token },
  });
};

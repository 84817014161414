// eslint-disable-next-line
import React, { useState, useEffect } from "react";

const useWindowWidth = () => {
  const [windowWidth, setWindowWidth] = useState(undefined);

  useEffect(() => {
    const updateWindowDimensions = () => {
      setWindowWidth(window.innerWidth);
    };

    window.addEventListener("resize", updateWindowDimensions);

    updateWindowDimensions();

    return () => window.removeEventListener("resize", updateWindowDimensions);
  });

  return windowWidth;
};

export default useWindowWidth;

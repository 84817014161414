import React, { useState } from "react";
import { useHistory } from "react-router-dom";
import { useToasts } from "react-toast-notifications";

import Breadcrumbs from "../../Breadcrumbs/Breadcrumbs";
import Header from "../AdminGeneric/Header/AdminHeader";
import Menu from "../AdminGeneric/Menu/AdminMenu";
import Buttons from "../AdminGeneric/Buttons/Buttons";

import { VacancyData } from "./Data/VacancyData";

import {
  createVacancyRequest,
  updateVacancyRequest,
} from "../../API/vacanciesAction";

import "./Form.scss";

const { nameGroup, textGroup } = VacancyData;

const checkIsStatePropExist = (gotData) => {
  return gotData !== undefined ? gotData : "";
};

const AddOrEditVacancy = (props) => {
  const { addToast } = useToasts();
  const history = useHistory();
  const vacancy = checkIsStatePropExist(props.location.state.vacancyBody);
  const vacancyId = checkIsStatePropExist(props.location.state.vacancyBody._id);
  const submitFunction = checkIsStatePropExist(
    props.location.state.submitFunction
  );

  const [formData, setFormData] = useState({
    active: vacancy.active,
    name: vacancy.name,
    text: vacancy.text,
  });

  const changeFormData = (name, value) => {
    setFormData({
      ...formData,
      [name]: value,
    });
  };

  // функция для рендера полей формы
  const renderInputForm = (array) => {
    return array.map(({ Component, label, name, placeholder, type }, index) => {
      return formData[name] !== undefined ? (
        <div className="admin-form__item item" key={index}>
          <label className="admin-item__label">{label}</label>
          <Component
            key={index}
            type={type}
            placeholder={placeholder}
            name={name}
            value={formData[name]}
            changeValue={changeFormData}
          />
        </div>
      ) : null;
    });
  };

  // функция отправки формы
  const handleFormSubmit = (data, event) => {
    event.preventDefault();
    if (submitFunction === "updateVacancyRequest") {
      updateVacancyRequest(vacancyId, data)
        .then((response) => {
          addToast("Вакансия успешно изменена", {
            appearance: "success",
            autoDismiss: true,
          });
          return new Promise((resolve) => {
            setTimeout(() => {
              resolve(() => {
                history.push({
                  pathname: "/admin/vacancies",
                });
              });
            }, 1000);
          });
        })
        .then((goToVacancies) => {
          history.push({
            pathname: "/admin/vacancies",
          });
        })
        .catch((error) => {
          addToast(
            "Вакансию изменить не удалось. Проверьте правильность заполнения полей формы. Они не должны быть пустыми.",
            {
              appearance: "error",
              autoDismiss: true,
            }
          );
        });
    } else if (submitFunction === "createVacancyRequest") {
      createVacancyRequest(data)
        .then((response) => {
          addToast("Вакансия успешно добавлена", {
            appearance: "success",
            autoDismiss: true,
          });
          return new Promise((resolve) => {
            setTimeout(() => {
              resolve(() => goToVacancies());
            }, 1000);
          });
        })
        .then((goToVacancies) => {
          goToVacancies();
        })
        .catch((error) => {
          addToast(
            "Вакансию создать не удалось. Проверьте правильность заполнения полей формы. Они не должны быть пустыми.",
            {
              appearance: "error",
              autoDismiss: true,
            }
          );
        });
    }
  };

  const goToVacancies = () => {
    history.push("/admin/vacancies");
  };

  return (
    <div>
      <Header />
      <div className="admin-wrapper">
        <Menu />
        <div className="admin-content">
          <Breadcrumbs />
          <form
            className="admin-form"
            onSubmit={(event) => handleFormSubmit(formData, event)}
          >
            <div>{renderInputForm(nameGroup)}</div>

            <div>{renderInputForm(textGroup)}</div>

            <Buttons
              disabled={null}
              textAdd={"Добавить"}
              cancelInput={goToVacancies}
            />
          </form>
        </div>
      </div>
    </div>
  );
};

export default AddOrEditVacancy;

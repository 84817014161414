import React from "react";

import Breadcrumbs from "../../../Breadcrumbs/Breadcrumbs";

import Logo from "../../Generic/Logo/Logo";
import Menu from "../../Main/Menu/Menu";

import "./Header.scss";

function Header() {
  return (
    <div>
      <div className="header">
        <Logo />
        <Menu />
      </div>
      <div className="content-container">
        {/* <div className="container"> */}
        <Breadcrumbs />
        {/* </div> */}
      </div>
    </div>
  );
}

export default Header;

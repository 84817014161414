import React from "react";

const CustomTextArea = ({ group, placeholder, value, name, changeValue }) => {
  return (
    <textarea
      className="admin-item__field admin-item__field--textarea"
      type="text"
      placeholder={placeholder}
      name={name}
      group={group}
      value={typeof value === "object" ? value[name] : value}
      onChange={(event) =>
        typeof value === "object"
          ? changeValue(group, { ...value, [name]: event.target.value })
          : changeValue(name, event.target.value)
      }
    />
  );
};

export default CustomTextArea;

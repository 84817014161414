import React from "react";

const styles = {
  display: "inherit",
  objectFit: "cover",
  width: "100%",
  height: "480px",
  margin: "0 auto",
};

function DetailedCardSlider(props) {
  return (
    <div>
      <img style={styles} alt="#" src={props.img}></img>
    </div>
  );
}

export default DetailedCardSlider;

export const customStyles = {
  control: (base) => ({
    ...base,
    height: "36px",
    background: "linear-gradient(0deg, #F2F4F7 0%, #FFFFFF 100%)",
  }),

  option: (styles, { data, isDisabled, isFocused, isSelected }) => ({
    ...styles,
    color: isDisabled
      ? null
      : isFocused
      ? "#2EA2F8"
      : isSelected
      ? "#2EA2F8"
      : null,

    background: isDisabled
      ? null
      : isSelected
      ? "#F1F4F8"
      : isFocused
      ? "#F1F4F8"
      : null,
  }),
  indicatorSeparator: () => {},
  dropdownIndicator: (provided, state) => ({
    ...provided,
    transform: state.selectProps.menuIsOpen && "rotate(180deg)",
    padding: "1px",
  }),
};

import React from "react";
import { NavLink } from "react-router-dom";
import withBreadcrumbs from "react-router-breadcrumbs-hoc";
import BreadcrumbsRoute from "./BreadcrumbsRoute";
import "./Breadcrumbs.scss";

const PureBreadcrumbs = (props) => {
  const breadcrumbs = props.breadcrumbs;
  let itemArr = [];
  // eslint-disable-next-line
  breadcrumbs.map(({ breadcrumb, match }, index) => {
    itemArr.push(
      <div className="breadcrumb-item" key={match.url}>
        <NavLink to={match.url || ""}>{breadcrumb}</NavLink>
      </div>
    );
    if (index < breadcrumbs.length - 1) {
      itemArr.push(
        <span className="breadcrumb-separator" key={index}>
          >
        </span>
      );
    }
  });
  return (
    <div
      className="breadcrumbs"
      style={props.location.pathname === "/" ? { display: "none" } : null}
    >
      {itemArr}
    </div>
  );
};

export default withBreadcrumbs(BreadcrumbsRoute)(PureBreadcrumbs);

import React from "react";
// import TestReview from "../../../static/images/test-review.png";
import "./ReviewSlides.scss";

function ReviewSlides(props) {
  return (
    <div>
      <div className="review-content">
        <img className="review-content__img" alt="#" src={props.item.image}></img>
        <p className="review-content__text">{props.item.description}</p>
        <p className="review-content__author">{props.item.name}</p>
      </div>
    </div>
  );
}

export default ReviewSlides;

import React, {useState, useEffect} from "react";
import useWindowWidth from "../../../../CustomHook/useWindowWidth";

import {Range} from "rc-slider";

import RenderFilterCheckbox from "../RenderFilterCheckbox/RenderFilterCheckbox";

import "rc-slider/assets/index.css";
import "../../../../styles/parts/ranges.scss"
import "../SelectForm.scss";

import {getRangeByNameRequest} from "../../../../API/filtersActions";

const pageRanges = {
    secondary: [
        {
            link: "price",
        },
        {
            link: "space",
        },
        {
            link: "floor",
        },
        {
            link: "rooms",
        },

        {
            link: "allFloors",
        },
    ],

    primary: [
        {
            link: "price",
        },
        {
            link: "floor",
        },
    ],

    house: [
        {
            link: "price",
        },
        {
            link: "space",
        },
        {
            link: "yard",
        },
        {
            link: "allFloors",
        },
    ],

    commercial: [
        {
            link: "price",
        },
        {
            link: "space",
        },
    ],
};

const translations = {
    space: "Площадь",
    rooms: "Кол-во комнат",
    floor: "Этаж",
    allFloors: "Этажность",
    price: "Цена",
    yard: "Кол-во соток",
};

const RenderFilterRanges = ({handleSelected, type, emptyfyAll, initalState}) => {
    const [rangeData, setRangeData] = useState([]);
    const [value, setValue] = useState({});
    const [defaultValue, setDefaultValue] = useState({});
    const windowWidth = useWindowWidth();

    useEffect(() => {
        let storageSelected = {}
        if (sessionStorage.getItem('filter')) {
            const temp = JSON.parse(sessionStorage.getItem('filter'));
            if(temp.ranges) {
                for (let key in temp.ranges) {
                    const label = key;
                    const min = temp.ranges[key].min;
                    const max = temp.ranges[key].max;
                    storageSelected[label] = [min, max];
                }
            }
        }

        const data = Promise.all(
            pageRanges[type].map((item) => {
                return getRangeByNameRequest(item.link, type).then((response) => {
                    return response.data;
                });
            })
        );
        data
            .then((response) => {
                setRangeData(response);
                return response;
            })
            .then((response) => {
                const result = {};
                // eslint-disable-next-line
                response.map((item) => {
                    const label = Object.keys(item)[0];
                    const min = item[label].min;
                    const max = item[label].max;
                    result[label] = [min, max];
                });

                const tempResult = {...result, ...storageSelected}

                setValue(tempResult);
                setDefaultValue(result);
            });
        // eslint-disable-next-line
    }, []);

    useEffect(() => {
        if (initalState)
            setValue((prevState) => ({
                ...prevState,
                [initalState.label]: [initalState.min, initalState.max],
            }));
    }, [rangeData, initalState]);

    useEffect(() => {
        for (let key in value) {
            handleSelected("ranges", key, {
                min: value[key][0],
                max: value[key][1],
            });
        }
        // eslint-disable-next-line
    }, [value]);

    useEffect(() => {
        // eslint-disable-next-line
        rangeData.map((item) => {
            for (let key in item) {
                handleSelected("ranges", key, item[key]);
            }
        });
        setValue(defaultValue);
        // eslint-disable-next-line
    }, [emptyfyAll]);

    return (
        <div>
            <form
                className="range"
                style={
                    type === "primary" || type === "commercial"
                        ? {flexDirection: "column"}
                        : {flexDirection: "row", justifyContent: "center"}
                }
            >
                {windowWidth > 1254 || windowWidth < 640
                    ? rangeData.map((item, index) => {
                        const label = Object.keys(item)[0];
                        const min = item[label].min;
                        const max = item[label].max;
                        const tipValue = value[label];

                        return (
                            <div className="range__input" key={index}>
                                <label className="range__input__label">
                                    {translations[label]}
                                </label>
                                <Range
                                    min={Number.isInteger(min) ? min : Math.floor(min)}
                                    max={Number.isInteger(max) ? max : Math.floor(max)}
                                    // min={min}
                                    // max={max}
                                    value={value[label]}
                                    label={label}
                                    //ЭТО ДЕЛАЕТ КОМПОНЕНТ УПРАВЛЯЕМЫМ
                                    onChange={(event) => {
                                        setValue((prevState) => ({
                                            ...prevState,
                                            [label]: event,
                                        }));
                                    }}
                                    emptyfyAll={emptyfyAll}
                                />
                                <div
                                    style={{
                                        display: "flex",
                                        justifyContent: "space-between",
                                    }}
                                >
                                    <p className="tip-value">
                                        {tipValue !== undefined ? tipValue[0] : min}
                                    </p>
                                    <p className="tip-value">
                                        {tipValue !== undefined ? tipValue[1] : max}
                                    </p>
                                </div>
                            </div>
                        );
                    })
                    : rangeData
                        .filter((item, index) => index < 3)
                        .map((item, index) => {
                            const label = Object.keys(item)[0];
                            const min = item[label].min;
                            const max = item[label].max;
                            const tipValue = value[label];
                            return (
                                <div className="range__input" key={index}>
                                    <label className="range__input__label">
                                        {translations[label]}
                                    </label>
                                    <Range
                                        min={Number.isInteger(min) ? min : Math.floor(min)}
                                        max={Number.isInteger(max) ? max : Math.floor(max)}
                                        // min={min}
                                        // max={max}
                                        label={label}
                                        value={value[label]}
                                        onChange={(event) => {
                                            setValue((prevState) => ({
                                                ...prevState,
                                                [label]: event,
                                            }));
                                        }}
                                        emptyfyAll={emptyfyAll}
                                    />
                                    <div
                                        style={{
                                            display: "flex",
                                            justifyContent: "space-between",
                                        }}
                                    >
                                        <p className="tip-value">
                                            {tipValue !== undefined ? tipValue[0] : min}
                                        </p>
                                        <p className="tip-value">
                                            {tipValue !== undefined ? tipValue[1] : max}
                                        </p>
                                    </div>
                                </div>
                            );
                        })}
                {windowWidth > 1254 || windowWidth < 640 ? (
                    <RenderFilterCheckbox
                        handleSelected={handleSelected}
                        emptyfyAll={emptyfyAll}
                    />
                ) : null}
            </form>
        </div>
    );
};

export default RenderFilterRanges;

import axios from "axios";

import config from "./config";

const complexEndpoint = "buildings/complexes/";
const buildingEndpoint = "buildings/";

const myStorage = window.localStorage;

const complexData = {
  list: {
    amount: 20,
    sort: {},
  },
  public: {},
};
// создание
export const createComplexRequest = (data) => {
  const token = myStorage.getItem("access-token");
  if (!token) return;
  return axios.post(config.url + complexEndpoint + "create", data, {
    headers: { Authorization: "Bearer " + token },
  });
};

// получение всех
export const getAllComplexesRequest = (data) => {
  const token = myStorage.getItem("access-token");
  if (token) {
    return axios.post(config.url + complexEndpoint, data, {
      headers: {Authorization: "Bearer " + token},
    });
  }
  return axios.post(config.url + complexEndpoint, data);
};

// получение всех акций
export const getComplexPromotionsRequest = (complexId, data) => {
  const token = myStorage.getItem("access-token");
  if (!token) return;
  return axios.post(config.url + complexEndpoint + complexId + "/promotions", data, {
    headers: { Authorization: "Bearer " + token },
  });
};
// получение акции
export const getComlextPromotionByIdRequest = (complexId, itemId, data) => {
  const token = myStorage.getItem("access-token");
  if (!token) return;
  return axios.get(config.url + complexEndpoint + complexId + "/promotion/edit/" + itemId, {
    headers: { Authorization: "Bearer " + token },
  });
};

// добавление акции
export const addComplexPromotionRequest = (complexId, data) => {
  const token = myStorage.getItem("access-token");
  if (!token) return;
  return axios.post(config.url + complexEndpoint + complexId + "/promotion/add", data, {
    headers: { Authorization: "Bearer " + token },
  });
};

// удаление акции
export const deleteComplexPromotionRequest = (complexId, itemId) => {
  const token = myStorage.getItem("access-token");
  if (!token) return;
  return axios.delete(config.url + complexEndpoint + complexId + "/promotion/" + itemId, {
    headers: { Authorization: "Bearer " + token },
  });
};

// получение всех планировок
export const getComplexLayoutsRequest = (complexId, data) => {
  const token = myStorage.getItem("access-token");
  if (!token) return;
  return axios.post(config.url + complexEndpoint + complexId + "/layouts", data, {
    headers: { Authorization: "Bearer " + token },
  });
};
// получение планировки
export const getComlextLayoutByIdRequest = (complexId, itemId, data) => {
  const token = myStorage.getItem("access-token");
  if (!token) return;
  return axios.get(config.url + complexEndpoint + complexId + "/layout/edit/" + itemId, {
    headers: { Authorization: "Bearer " + token },
  });
};

// добавление планировки
export const addComplexLayoutRequest = (complexId, data) => {
  const token = myStorage.getItem("access-token");
  if (!token) return;
  return axios.post(config.url + complexEndpoint + complexId + "/layout/add", data, {
    headers: { Authorization: "Bearer " + token },
  });
};

// удаление планировки
export const deleteComplexLayoutRequest = (complexId, itemId) => {
  const token = myStorage.getItem("access-token");
  if (!token) return;
  return axios.delete(config.url + complexEndpoint + complexId + "/layout/" + itemId, {
    headers: { Authorization: "Bearer " + token },
  });
};


// удаление комплекса
export const deleteComplexRequest = (complexId) => {
  const token = myStorage.getItem("access-token");
  if (!token) return;
  return axios.delete(config.url + complexEndpoint + complexId, {
    headers: { Authorization: "Bearer " + token },
  });
};

//получить карточку по id (options - raw) для update (не доступна юзеру)
export const getComplexById = (complexId) => {

  const token = myStorage.getItem("access-token");
  if (token) {
    return axios.get(config.url + complexEndpoint + complexId, {
      params: {raw: true},
      headers: {Authorization: "Bearer " + token},
    });
  }
  return axios.get(config.url + complexEndpoint + complexId, {
    params: { raw: true }
  });
};

// обновление данных комплекса
export const updateComplexRequest = (data) => {
  const token = myStorage.getItem("access-token");
  if (!token) return;
  return axios.put(config.url + complexEndpoint, data, {
    headers: {
      "Content-Type": "application/json",
      Authorization: "Bearer " + token,
    },
  });
};

// получение списка застройщиков
export const getBuildersList = () => {
  return axios.get(config.url + buildingEndpoint + "builders/");
};

// получение property комплекса
export const getComplexPropertyByName = (name) => {
  const token = myStorage.getItem("access-token");
  if (!token) return;
  return axios.get(config.url + buildingEndpoint + "properties/" + name, {
    headers: { Authorization: "Bearer " + token },
  });
};

// получение sample комплекса (поля которые должны быть в форме комплекса)
export const getComplexSample = (cardType) => {
  const token = myStorage.getItem("access-token");
  if (!token) return;
  return axios.get(config.url + buildingEndpoint + "complexes/sample", {
    headers: { Authorization: "Bearer " + token },
  });
};

export const getComplexNames = () => {
  const token = myStorage.getItem("access-token");
  if (!token) return;
  return axios.post(config.url + complexEndpoint + "?", complexData, {
    params: { names: true },
    headers: { Authorization: "Bearer " + token },
  });
};

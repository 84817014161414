const configDEV = {
    url: "https://parlament-api.oa.r.appspot.com/",
    urlForPage: "https://parlament-front.oa.r.appspot.com",

    // url: "http://localhost:4000/",
    // urlForPage: "http://localhost:3000",
};

const configPROD = {
    url: "https://parlament-server.oa.r.appspot.com/",
    urlForPage: "http://parlament.od.ua",
};

export default process.env.REACT_APP_ENV === "production"
    ? configPROD
    : configDEV;

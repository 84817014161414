import React from "react";
import { NavLink } from "react-router-dom";

import useWindowWidth from "../../../CustomHook/useWindowWidth";

import "./LargeMenu.scss";

// районы для фильтрации
const districtSuvorov = {
  name: "Cуворовский район",
  _id: "5e7cf055abcae12f0ce29e84",
};
const districtKiev = {
  name: "Киевский район",
  _id: "5e7cf088abcae12f0ce29ea7",
};
const districtPrimor = {
  name: "Приморский район",
  _id: "5e7cf068abcae12f0ce29e90",
};
const districtMalinov = {
  name: "Малиновский  район",
  _id: "5e7cf07babcae12f0ce29e98",
};

const menuData = {
  flatsGeneral: [
    {
      className: "submenu__item--title",
      text: "Квартиры в Одессе",
      path: "/flats",
    },
    {
      text: "Однокомнатные",
      path: "/flats",
      state: { min: 1, max: 1, label: "rooms" },
    },
    {
      text: "Двухкомнатные",
      path: "/flats",
      state: { min: 2, max: 2, label: "rooms" },
    },
    {
      text: "Трёхкомнатные",
      path: "/flats",
      state: { min: 3, max: 3, label: "rooms" },
    },
    {
      text: "Многокомнатные",
      path: "/flats",
      state: { min: 4, max: 10, label: "rooms" },
    },
  ],
  flatsCategories: [
    {
      className: "submenu__item--title",
      text: "Категории квартир",
      path: "/flats",
    },
    {
      text: "Недорогие квартиры",
      path: "/flats",
    },
    {
      text: "Квартиры в коммуне",
      path: "/flats",
    },
    {
      text: "Квартиры у моря",
      path: "/flats",
    },
    {
      text: "Элитные квартиры",
      path: "/flats",
    },
  ],
  flatsDistricts: [
    {
      className: "submenu__item--title",
      text: "Квартиры по районам",
      path: "/flats",
    },
    {
      text: "Квартиры в Суворовском районе",
      path: "/flats",
      state: districtSuvorov,
    },
    {
      text: "Квартиры в Киевском районе",
      path: "/flats",
      state: districtKiev,
    },
    {
      text: "Квартиры в Малиновском районе",
      path: "/flats",
      state: districtMalinov,
    },
    {
      text: "Квартиры в Приморском районе",
      path: "/flats",
      state: districtPrimor,
    },
  ],
  primaryGeneral: [
    {
      text: "Новые ЖК 2020",
      path: "/complex",
      state: {
        publicFilter: "isNewComplex"
      }
    },
    {
      text: "Сданные новострои",
      path: "/complex",
      state: {
        publicFilter: "isComplete"
      }
    },
    {
      text: "Элитный новострой",
      path: "/complex",
      state: {
        publicFilter: "isElite"
      }
    },
    {
      text: "Новострой в расрочку",
      path: "/complex",
      state: {
        publicFilter: "isCredit"
      }
    },
    {
      text: "Недорогой новострой",
      path: "/complex",
      state: {
        publicFilter: "isInexpensive"
      }
    },
    {
      text: "Акционная продажа",
      path: "/complex",
      state: {
        publicFilter: "isPromotion"
      }
    },
  ],
  primaryDistricts: [
    {
      className: "submenu__item--title",
      text: "Новострои по районам",
      path: "/complex",
    },
    {
      text: "ЖК в Суворовском районе",
      path: "/complex",
      state: {
        district: districtSuvorov
      },
    },
    {
      text: "ЖК в Киевском районе",
      path: "/complex",
      state: {
        district: districtKiev
      },
    },
    {
      text: "ЖК в Малиновском районе",
      path: "/complex",
      state: {
        district: districtMalinov
      },
    },
    {
      text: "ЖК в Приморском районе",
      path: "/complex",
      state: {
        district: districtPrimor
      },
    },
  ],
  houseGeneral: [
    {
      text: "Дома/участки",
      path: "/houses",
    },
    {
      text: "Таунхаусы",
      path: "/houses",
    },
    {
      text: "Дома",
      path: "/houses",
    },
    {
      text: "Участки",
      path: "/houses",
    },
  ],
  commercialGeneral: [
    {
      text: "Готовый бизнес",
      path: "/commercial",
    },
    {
      text: "Под офис",
      path: "/commercial",
    },
    {
      text: "Офис",
      path: "/commercial",
    },
    {
      text: "Склады",
      path: "/commercial",
    },
    {
      text: "Торговые площадки",
      path: "/commercial",
    },
  ],
};

const LargeMenu = ({ hover, type, active }) => {
  const windowWidth = useWindowWidth();

  const renderMenuData = (itemType) => {
    return menuData[itemType].map((item, index) => {
      let { className, text, path, state } = item;
      return (
        <li
          key={index}
          className={
            item.className ? "submenu__item " + className : "submenu__item"
          }
        >
          <NavLink
            key={index}
            to={{
              pathname: path,
              state: state,
            }}
            className="submenu__link"
          >
            {text}
          </NavLink>
        </li>
      );
    });
  };

  return (
    <div
      className="submenu-wrapper"
      style={
        windowWidth > 992
          ? hover === type
            ? { opacity: 1, visibility: "visible" }
            : { opacity: 0, visibility: "hidden" }
          : active === type
          ? { display: "flex" }
          : { display: "none" }
      }
    >
      {type === "flats" ? (
        <nav className="submenu submenu--flats">
          <ul className="submenu__list">{renderMenuData("flatsGeneral")}</ul>
          <ul className="submenu__list">{renderMenuData("flatsCategories")}</ul>
          <ul className="submenu__list">{renderMenuData("flatsDistricts")}</ul>
        </nav>
      ) : null}
      {type === "primary" ? (
        <nav className="submenu submenu--primary">
          <ul className="submenu__list">{renderMenuData("primaryGeneral")}</ul>
          <ul className="submenu__list">
            {renderMenuData("primaryDistricts")}
          </ul>
        </nav>
      ) : null}
      {type === "house" ? (
        <nav className="submenu submenu--house">
          <ul className="submenu__list submenu__list--flex">
            {renderMenuData("houseGeneral")}
          </ul>
        </nav>
      ) : null}
      {type === "commercial" ? (
        <nav className="submenu submenu--commercial">
          <ul className="submenu__list submenu__list--flex">
            {renderMenuData("commercialGeneral")}
          </ul>
        </nav>
      ) : null}
    </div>
  );
};

export default LargeMenu;

import React, { useState, useEffect } from "react";
import useWindowWidth from "../../CustomHook/useWindowWidth";

import Header from "../../Components/Main/Header/Header";
import Footer from "../../Components/Main/Footer/Footer";
import Button from "../../Components/Generic/Button/Button";
import MapContainer from "../../Components/Main/Map/MapClusterContainer";
import { Range } from "rc-slider";

import { getAllCardsRequestMap } from "../../API/cardsAction";

import "./Map.scss";
import RenderFilterComplex from "../../Components/Main/SelectForm/RenderFilterComplex/RenderFilterComplex";
import {getRangeByNameRequest} from "../../API/filtersActions";

import "rc-slider/assets/index.css";
import "../../styles/parts/ranges.scss";
import useDebounce from "../../CustomHook/useDebounce";

const MapAndFilters = ({ link, builder, state }) => {
  const defaultSelectedOptions = {
    list: {
      amount: 1000,
      sort: {
        by: undefined,
        type: undefined,
      },
    },
    filters: {},
    public: {
      builder: builder ? builder.builder : undefined,
    },
    ranges: {
      price: {
        min: 0,
        max: 0,
      }
    },
  };

  const [loadingStarted, toggleLoadingStart] = useState(false);
  const [mapContainerData, setMapContainerData] = useState([]);
  const [selected, setSelected] = useState(defaultSelectedOptions);
  const [rangePrice, setRangePrice] = useState({});
  const windowWidth = useWindowWidth();
  const [filtersVisible, setFiltersVisible] = useState(false);
  const [showMoreFilters, setShowMoreFilters] = useState(false);
  const [emptyfyAll, setEmptyfyAll] = useState(false);

  const debouncedRanges = useDebounce(selected.ranges, 750);

  const handleSelected = (filterGroup, filterName, filterNewValue) => {
    // const newObject = JSON.parse(JSON.stringify(selected));
    // newObject[filterGroup][filterName] = filterNewValue;
    // setSelected(newObject);
    setSelected((prevState) => ({
      ...prevState,
      [filterGroup]: {
        ...prevState[filterGroup],
        [filterName]: filterNewValue,
      },
    }));
  };

  const getNewCardsData = (options) => {
    return getAllCardsRequestMap(options)
      .then((response) => {
        let data = response.data;
        /*
        const mapData = data.map(
          ({ _id, name, coordinates, district, subDistrict, street }) => {
            return {
              pathname: `${link}/${_id}`,
              name: name,
              address: district + " " + subDistrict + " " + street,
              coordinates: coordinates,
            };
          }
        );
        */
        const mapData = [];
        data.forEach(({ _id, name, price, coordinates, district, subDistrict, street, backgroundImage }) => {
          if (coordinates && coordinates.latitude && coordinates.longitude){
            mapData.push({
              pathname: `${link}/${_id}`,
              name: name,
              price: price,
              address: district + " " + subDistrict + " " + street,
              backgroundImage: backgroundImage,
              coordinates: coordinates,
            });
          }
        });
        setMapContainerData(mapData);
      });
  };

  const updateData = () => {
    toggleLoadingStart(true);
    getNewCardsData(selected).then(() => {
      toggleLoadingStart(false);
    });
  };

  useEffect(() => {
    toggleLoadingStart(true);
    getNewCardsData(selected).then(() => {
      toggleLoadingStart(false);
    }).then(() => {
      return getRangeByNameRequest('price');
    }).then((response) => {
      return response.data.price;
    }).then((response) => {
      setRangePrice({
        min: response.min,
        max: response.max,
      });

      setSelected((prevState) => ({
        ...prevState,
        ranges: {
          ...prevState.ranges,
          price: {
            min: response.min,
            max: response.max,
          }
        },
      }));
    });
    // eslint-disable-next-line
  }, []);

  useEffect(updateData, [selected.public]);

  useEffect(() => {
    if (debouncedRanges) {
      updateData();
    }
    // eslint-disable-next-line
  }, [debouncedRanges]);

  const emptyfySelectedFilters = () => {
    defaultSelectedOptions.ranges.price.min = rangePrice.min;
    defaultSelectedOptions.ranges.price.max = rangePrice.max;

    setSelected(defaultSelectedOptions);
    setEmptyfyAll(!emptyfyAll);
    setFiltersVisible(false);
  };

  return (
    <div>
      <Header />
      <div className="page-container">
        <div className="container">
          <div
            className="wrapper__btn-mob"
            style={
              windowWidth > 992 ? { display: "none" } : { display: "flex" }
            }
          >
          </div>
        </div>
        <div
          className="filters-wrapper"
          style={
            !filtersVisible && windowWidth < 992
              ? { display: "none" }
              : filtersVisible && windowWidth < 992
              ? { display: "block" }
              : { display: "block" }
          }
        >
          <div className="filters">
            <RenderFilterComplex
              handleSelected={handleSelected}
              emptyfyAll={emptyfyAll}
              initalState={(state && state.district)?state.district:null}
              flats={true}
            >
              <div className="range__input">
                <label className="range__input__label">Цена</label>
                <Range
                    min={rangePrice.min}
                    max={rangePrice.max}
                    value={[selected.ranges.price.min, selected.ranges.price.max]}
                    onChange={(event) => {
                      setSelected((prevState) => ({
                        ...prevState,
                        ranges: {
                          ...prevState.ranges,
                          price: {
                            min: event[0],
                            max: event[1],
                          }
                        },
                      }))
                    }}
                />
                <div
                    style={{
                      display: "flex",
                      justifyContent: "space-between",
                    }}
                >
                  <p className="tip-value">{selected.ranges.price.min}</p>
                  <p className="tip-value">{selected.ranges.price.max}</p>
                </div>
              </div>
            </RenderFilterComplex>
            <Button
              className="btn-big btn-big--filter"
              text={"Сброс"}
              onClick={emptyfySelectedFilters}
            />
          </div>
        </div>
        <div className="content-map-container">
          {loadingStarted ? (
            <p className="loader-full">Loading...</p>
          ) : (
            <div className="map">
              <MapContainer data={mapContainerData} />
            </div>
          )}
        </div>
        <Footer />
      </div>
    </div>
  );
};

export default MapAndFilters;

import React from "react";
import ModalWindow from "../Modal/Modal";

import "./Feedback.scss";

const Feedback = props => {
  return (
    <div className="feedback" style={props.style}>
      <div className="feedback__content">
        <h2 className="feedback__text">
          Остались вопросы? свяжитесь с нами и мы вам ответим
        </h2>
        <ModalWindow currentPage={props.currentPage} />
      </div>
    </div>
  );
};

export default Feedback;

import React, {useState, useEffect} from "react";
import SVG from "react-inlinesvg";
import useWindowWidth from "../../CustomHook/useWindowWidth";

import Header from "../../Components/Main/Header/Header";
import Footer from "../../Components/Main/Footer/Footer";
import Button from "../../Components/Generic/Button/Button";
import Card from "./Card";
import MapContainer from "../../Components/Main/Map/MapContainer";

import RenderFilterAddress from "../../Components/Main/SelectForm/RenderFilterAddress/RenderFilterAddress";
import RenderFilterCategories from "../../Components/Main/SelectForm/RenderFilterCategories/RenderFilterCategories";
import RenderFilterRanges from "../../Components/Main/SelectForm/RenderFilterRanges/RenderFilterRanges";

import IconFilterUpSVG from "../../static/images/iconClick_actions/filter_up.svg";
import IconFilterDownSVG from "../../static/images/iconClick_actions/filter_down.svg";
import IconFiltersBtnSVG from "../../static/images/iconClick_actions/filters_btn.svg";

import {getAllCardsRequest, getAllCardsCountRequest} from "../../API/cardsAction";
import {Link} from "react-router-dom";

import "./CardsAndFilters.scss";

const cardStyles = {
    display: "flex",
    flexDirection: "row",
    maxWidth: "600px",
};

const changeSortIconsColor = (sortBy, state, sortType) => {
    const sort = state.list.sort;

    const result =
        sort.by === sortBy
            ? sort.type === sortType
                ? {fill: "black"}
                : {fill: "grey"}
            : {fill: "grey"};

    return result;
};

const CardsAndFilters = ({type, link, publicType, builder, state}) => {
    const defaultSelectedOptions = {
        list: {
            amount: 10,
            page: 1,
            sort: {
                by: undefined,
                type: undefined,
            },
        },
        filters: {},
        public: {
            type,
            builder: builder ? builder.builder : undefined,
        },
        ranges: {},
    };

    const [cardsForRender, setCardsForRender] = useState([]);
    const [pagination, setPagination] = useState({
        count: 0,
        pages: 1,
    });
    let [isPageChange] = useState(false);

    const [loadingStarted, toggleLoadingStart] = useState(false);
    const [mapContainerData, setMapContainerData] = useState([]);
    const [selected, setSelected] = useState(defaultSelectedOptions);
    const windowWidth = useWindowWidth();
    const [mapVisible, setMapVisible] = useState(false);
    const [filtersVisible, setFiltersVisible] = useState(false);
    const [showMoreFilters, setShowMoreFilters] = useState(false);
    const [emptyfyAll, setEmptyfyAll] = useState(false);

    const handleSelected = (filterGroup, filterName, filterNewValue) => {
        // const newObject = JSON.parse(JSON.stringify(selected));
        // newObject[filterGroup][filterName] = filterNewValue;
        // setSelected(newObject);

        // console.log(filterGroup, filterName, filterNewValue);

        setSelected((prevState) => ({
            ...prevState,
            [filterGroup]: {
                ...prevState[filterGroup],
                [filterName]: filterNewValue,
            },
        }));
    };

    const sortFunction = (sortBy) => {
        const list = selected.list;
        const sort = selected.list.sort;

        let newObject = {
            ...selected,
            list: {...list, sort: {by: undefined, type: undefined}},
        }

        if (sort.by !== sortBy || sort.type === 1) {
            newObject.list.sort.by = sortBy
        }

        if (sort.by !== sortBy) {
            newObject.list.sort.type = 1
        } else if (sort.type === 1) {
            newObject.list.sort.type = -1
        }

        sessionStorage.setItem('filter', JSON.stringify(newObject))

        setSelected(newObject);
        toggleLoadingStart(true);
        getNewCardsData(newObject).then(() => {
            toggleLoadingStart(false);
        });
    };

    /*const moreFunction = () => {
      let newObject = {...selected}

      newObject.list.amount += 10;
      setSelected(newObject);

      toggleLoadingStart(true);
      getNewCardsData(newObject).then(() => {
        toggleLoadingStart(false);
      });
    }*/

    const getNewCardsData = (options) => {
        if (!isPageChange) {
            options.list.page = 1;
            return getAllCardsCountRequest(options).then((response) => {
                pagination.count = response.data;
                pagination.pages = Math.ceil(response.data / 10);
                setPagination(pagination);

                return getAllCardsRequest(options);
            }).then((response) => {
                let data = response.data;
                setCardsForRender(data);
                return data;
            }).then((data) => {
                const mapData = data.map(
                    ({_id, name, coordinates, district, subDistrict, street}) => {
                        return {
                            pathname: `${link}/${_id}`,
                            name: name,
                            address: district + " " + subDistrict + " " + street,
                            coordinates: coordinates,
                        };
                    }
                );
                setMapContainerData(mapData);
            });
        }

        return getAllCardsRequest(options).then((response) => {
            let data = response.data;
            setCardsForRender(data);
            return data;
        }).then((data) => {
            const mapData = data.map(
                ({_id, name, coordinates, district, subDistrict, street}) => {
                    return {
                        pathname: `${link}/${_id}`,
                        name: name,
                        address: district + " " + subDistrict + " " + street,
                        coordinates: coordinates,
                    };
                }
            );
            setMapContainerData(mapData);
        });
    };

    const useFilters = () => {
        isPageChange = false;
        if (windowWidth < 992) {
            setFiltersVisible(false);
        }

        sessionStorage.setItem('filter', JSON.stringify(selected))

        toggleLoadingStart(true);
        getNewCardsData(selected).then(() => {
            isPageChange = true;
            toggleLoadingStart(false);
        })
    };

    useEffect(() => {
        let storageSelected = {...defaultSelectedOptions}
        if (sessionStorage.getItem('filter')) {
            storageSelected = JSON.parse(sessionStorage.getItem('filter'));
        }

        toggleLoadingStart(true);
        getNewCardsData(storageSelected).then(() => {
            toggleLoadingStart(false);
            isPageChange = true;
        });
        // eslint-disable-next-line
    }, []);

    const toggleMapVisibility = () => {
        setMapVisible(!mapVisible);
    };

    const toggleFiltersVisibility = () => {
        setFiltersVisible(!filtersVisible);
    };

    const toggleShowMoreFilters = () => {
        setShowMoreFilters(!showMoreFilters);
    };

    const emptyfySelectedFilters = () => {
        isPageChange = false;

        setSelected(defaultSelectedOptions);
        sessionStorage.removeItem('filter')

        toggleLoadingStart(true);
        getNewCardsData(defaultSelectedOptions).then(() => {
            isPageChange = true;
            toggleLoadingStart(false);
        });

        setEmptyfyAll(!emptyfyAll);
        setFiltersVisible(false);
    };

    const handleChangePage = (page) => {
        isPageChange = true;

        const newObject = JSON.parse(JSON.stringify(selected));
        newObject["list"]["page"] = page;
        setSelected(newObject);

        sessionStorage.setItem('filter', JSON.stringify(newObject))

        toggleLoadingStart(true);
        getNewCardsData(newObject).then(() => {
            toggleLoadingStart(false);
        });
    }


    return (
        <div>
            <Header/>
            <div className="page-container">
                <div className="container">
                    <div
                        className="wrapper__btn-mob"
                        style={
                            windowWidth > 992 ? {display: "none"} : {display: "flex"}
                        }
                    >
                        <SVG
                            src={IconFiltersBtnSVG}
                            style={{marginRight: "8px"}}
                            onClick={toggleFiltersVisibility}
                        />
                        <Button
                            className="btn-big btn-big--mob"
                            text={"Список"}
                            onClick={toggleMapVisibility}
                            disabled={!mapVisible ? true : false}
                            style={
                                !mapVisible
                                    ? {
                                        background: "#3A2E94",
                                        color: "#FFF",
                                        marginRight: "8px",
                                    }
                                    : {background: "#FFF", marginRight: "8px"}
                            }
                        />
                        <Button
                            className="btn-big btn-big--mob"
                            text={"Карта"}
                            onClick={toggleMapVisibility}
                            disabled={mapVisible ? true : false}
                            style={
                                mapVisible
                                    ? {background: "#3A2E94", color: "#FFF"}
                                    : {background: "#FFF"}
                            }
                        />
                    </div>
                </div>
                <div
                    className="filters-wrapper"
                    style={
                        !filtersVisible && windowWidth < 992
                            ? {display: "none"}
                            : filtersVisible && windowWidth < 992
                                ? {display: "block"}
                                : {display: "block"}
                    }
                >
                    <div className="filters">
                        <RenderFilterAddress
                            handleSelected={handleSelected}
                            type={type}
                            emptyfyAll={emptyfyAll}
                            initalState={state}
                        />
                        <RenderFilterRanges
                            handleSelected={handleSelected}
                            type={type}
                            emptyfyAll={emptyfyAll}
                            initalState={state}
                        />
                        <div
                            style={
                                showMoreFilters && windowWidth < 900
                                    ? {display: "flex"}
                                    : !showMoreFilters && windowWidth < 900
                                        ? {display: "none"}
                                        : {display: "flex"}
                            }
                        >
                            <RenderFilterCategories
                                handleSelected={handleSelected}
                                type={type}
                                emptyfyAll={emptyfyAll}
                            />
                        </div>

                        {windowWidth < 900 ? (
                            <p
                                onClick={toggleShowMoreFilters}
                                style={{
                                    width: "100%",
                                    fontSize: "14px",
                                    marginBottom: "16px",
                                }}
                            >
                                {showMoreFilters ? "Скрыть фильтры" : "Показать еще фильтры..."}
                            </p>
                        ) : null}
                    </div>
                    <div className="btn-big__wrapper">
                        <Button
                            className="btn-big btn-big--filter"
                            text={"Поиск"}
                            onClick={useFilters}
                        />
                        <Button
                            className="btn-big btn-big--filter"
                            text={"Отмена"}
                            onClick={emptyfySelectedFilters}
                        />
                        <Link className="find-map" to="/map">Поиск на карте</Link>
                    </div>
                </div>
                <div className="content-container">
                    {loadingStarted ? (
                        <p className="loader-full">Loading...</p>
                    ) : (
                        <div className="cards-map">
                            <div
                                className="cards-wrapper"
                                style={
                                    mapVisible && windowWidth < 992
                                        ? {display: "none"}
                                        : !mapVisible && windowWidth < 992
                                            ? {display: "block"}
                                            : {display: "block"}
                                }
                            >
                                {loadingStarted ? null : (
                                    <div>
                                        {cardsForRender.length < 1
                                            ? null
                                            : (
                                                <div>
                                                    <div className="card-results">
                                                        <div className="results-text">
                                                            <p>Результаты:</p>
                                                        </div>
                                                        <div className="card-results__sort">
                                                            <p>Сортировать по</p>
                                                            <div
                                                                className="sort__item"
                                                                onClick={(event) =>
                                                                    sortFunction("price")
                                                                }
                                                            >
                                                                <div
                                                                    className="filter__icons"
                                                                    style={{
                                                                        cursor: "pointer",
                                                                    }}
                                                                >
                                                                    <SVG
                                                                        src={IconFilterUpSVG}
                                                                        style={changeSortIconsColor("price", selected, 1)}
                                                                    />
                                                                    <SVG
                                                                        src={IconFilterDownSVG}
                                                                        style={changeSortIconsColor(
                                                                            "price",
                                                                            selected,
                                                                            -1
                                                                        )}
                                                                    />
                                                                </div>
                                                                <p>Цена</p>
                                                            </div>
                                                            <div
                                                                className="sort__item "
                                                                onClick={(event) =>
                                                                    sortFunction("date")
                                                                }
                                                            >
                                                                <div className="filter__icons">
                                                                    <SVG
                                                                        src={IconFilterUpSVG}
                                                                        style={changeSortIconsColor("date", selected, 1)}
                                                                    />
                                                                    <SVG
                                                                        src={IconFilterDownSVG}
                                                                        style={changeSortIconsColor("date", selected, -1)}
                                                                    />
                                                                </div>
                                                                <p>Дата</p>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="card-content">
                                                        {cardsForRender.map(function (item, index) {
                                                            const uniqueKey = index + '-' + item.publicId + '-' + Date.now();
                                                            return (<Card key={uniqueKey} card={item} link={link} publicType={publicType} cardStyles={cardStyles}/>);
                                                        })}
                                                    </div>
                                                    <div style={{padding: 10}}>
                                                        {pagination.pages && pagination.pages > 1 ? (
                                                            <div className="pagination">
                                                                {[...Array(pagination.pages)].map((x, i) =>
                                                                    <div className={`pagination__item ${selected.list.page === (i + 1) ? "active" : ""}`} onClick={() => handleChangePage(i + 1)}>{i + 1}</div>
                                                                )}
                                                            </div>
                                                        ) : null}
                                                    </div>
                                                </div>
                                            )}
                                    </div>
                                )}
                            </div>
                            <div
                                style={
                                    !mapVisible && windowWidth < 992
                                        ? {display: "none"}
                                        : mapVisible && windowWidth < 992
                                            ? {display: "block"}
                                            : {display: "block"}
                                }
                            >
                                <MapContainer data={mapContainerData}/>
                            </div>
                        </div>
                    )}
                </div>
                <Footer/>
            </div>
        </div>
    );
};

export default CardsAndFilters;

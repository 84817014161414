import React from "react";
import { Route, Redirect } from "react-router-dom";
import useGlobal from "./store";

const ProtectedRoute = ({ component: Component, control, ...rest }) => {
  // eslint-disable-next-line
  const [globalState, globalActions] = useGlobal();

   return globalState.user.control === undefined ? null : (
    <Route
      {...rest}
      render={(props) => {
        if (globalState.user.control) {
          return <Component {...props} />;
        } else {
          return (
            <Redirect
              to={{
                pathname: `*`,
                state: {
                  from: props.location,
                },
              }}
            />
          );
        }
      }}
    />
  );
};

export default ProtectedRoute;

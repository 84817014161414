import React, { useState, useEffect } from "react";
import encode from "./EncodeFile";
import SVG from "react-inlinesvg";

import IconDeleteSVG from "../../../static/images/iconClick_actions/delete.svg";

const CustomFileInput = ({
  value,
  name,
  returnas,
  text,
  multiple,
  group,
  changeValue,
}) => {
  const inputRef = React.createRef();
  const [uploadedImages, setUploadedImages] = useState(value);
  useEffect(() => {
    changeValue("file", {
      extension: ".pdf",
      ...value,
      [name]: uploadedImages,
    });
    // eslint-disable-next-line
  }, [uploadedImages]);

  return (
    <div className="dropzone-cv__wrapper">
      <div className="dropzone-cv">
        <input
          className="uploadFile "
          type="file"
          name={name}
          id={name}
          accept={"application/pdf"}
          returnas={returnas}
          multiple={multiple}
          group={group}
          ref={inputRef}
          onInput={async () => {
            const convertedBg = await encode(inputRef.current.files[0]);
            setUploadedImages(convertedBg);
          }}
        />
        <label htmlFor={name}>{text}</label>
      </div>
      <div className="droped-names" style={{ position: "relative" }}>
        <ul className="droped-list">
          {uploadedImages ? (
            <li className="droped-item">
              <SVG
                className="droped-svg"
                onClick={() => setUploadedImages(undefined)}
                src={IconDeleteSVG}
              />
              File
            </li>
          ) : null}
        </ul>
      </div>
    </div>
  );
};

export default CustomFileInput;

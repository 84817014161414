const BreadcrumbsRoute = [
  {
    breadcrumb: "Главная",
    path: "/",
    exact: true,
    routes: [
      {
        breadcrumb: "Квартиры",
        path: "/map",
      },
      {
        breadcrumb: "Квартиры",
        path: "/flats",
      },
      {
        breadcrumb: "Новострои",
        path: "/primary",
      },
      {
        breadcrumb: "Жилые комплексы",
        path: "/complex",
      },
      {
        breadcrumb: "Дома и участки",
        path: "/houses",
      },
      {
        breadcrumb: "Коммерческая недвижимость",
        path: "/commercial",
      },
      {
        breadcrumb: "О компании",
        path: "/company",
      },
      {
        breadcrumb: "Вакансия",
        path: "/company/vacancy",
      },
      {
        breadcrumb: "Контакты",
        path: "/contacts",
      },
      {
        breadcrumb: "Вход",
        path: "/login",
      },
      {
        breadcrumb: "Регистрация",
        path: "/registration",
      },
      {
        breadcrumb: "Персональные данные",
        path: "/account",
      },
      {
        breadcrumb: "Админка",
        path: "/admin",
      },
      {
        breadcrumb: "Импорт объектов",
        path: "/admin/import-objects",
      },
      {
        breadcrumb: "Добавление объекта",
        path: "/admin/add-object",
      },
      {
        breadcrumb: "Добавление объекта",
        path: "/admin/add-object-type",
      },
      {
        breadcrumb: "Изменение объекта",
        path: "/admin/edit-object-type",
      },
      {
        breadcrumb: "Добавление жилого комплекса",
        path: "/admin/add-complex",
      },
      {
        breadcrumb: "Изменение жилого комплекса",
        path: "/admin/edit-complex",
      },
      {
        breadcrumb: "Добавление вакансии",
        path: "/admin/add-vacancy",
      },
      {
        breadcrumb: "Изменение вакансии",
        path: "/admin/edit-vacancy",
      },
      {
        breadcrumb: "Планировки",
        path: "/admin/complex/:id/layouts",
      },
      {
        breadcrumb: "Добавление планировки",
        path: "/admin/complex/:id/layouts/add",
      },
      {
        breadcrumb: "Акции",
        path: "/admin/complex/:id/promotions",
      },
      {
        breadcrumb: "Добавление акции",
        path: "/admin/complex/:id/promotions/add",
      },
      {
        breadcrumb: "Жилые комплексы",
        path: "/admin/complex",
      },
    ],
  },
];

export default BreadcrumbsRoute;

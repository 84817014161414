import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";

import GoogleMap from "google-map-react";

import "./Map.scss";

// маркер и при наведении на него - появление инфобокса с названием карточки
const MarkerInfo = ({ name, pathname }) => {
  const [hover, setHover] = useState(false);

  pathname = pathname || '#'

  const showInfoboxOnHoverOrNot = () => {
    if (name !== null) {
      return (
        <div
          className="infobox"
          style={hover ? { visibility: "visible" } : { visibility: "hidden" }}
        >
          {hover ? <p>{name}</p> : null}
        </div>
      );
    } else if (name === null) {
      return <div className="infobox" style={{ visibility: "hidden" }}></div>;
    }
  };

  return (
    <div
      onMouseEnter={() => setHover(true)}
      onMouseLeave={() => setHover(false)}
    >
      <Link to={pathname}>
        <div className="marker"></div>
      </Link>

      {showInfoboxOnHoverOrNot()}
    </div>
  );
};

const MapContainer = ({ data }) => {
  // eslint-disable-next-line
  const [zoom, setZoom] = useState(16);
  // eslint-disable-next-line
  const [center, setCenter] = useState({ lat: 46.584658, lng: 30.803381 });
  const [selectedPlace, setSelectedPlace] = useState([]);

  useEffect(() => {
    setSelectedPlace(data);
  }, [data]);

  return (
    <div className="map-container">
      <GoogleMap
        bootstrapURLKeys={{ key: "AIzaSyANl0l7X8xblLYXxlBglFCWvZuDfypYcRU" }}
        defaultCenter={center}
        defaultZoom={zoom}
      >
        <MarkerInfo
            className="marker"
            key="46584658_30803381"
            id="46584658_30803381"
            lat={center.lat}
            lng={center.lng}
            /*name={item.points[0].name}*/
            /*pathname={item.points[0].pathname}*/
        />
        {/*{selectedPlace
          ? selectedPlace.map((item, index) => {
              const { coordinates, name, pathname } = item;
              return (
                <MarkerInfo
                  className="marker"
                  key={index}
                  id={index}
                  lat={coordinates.latitude}
                  lng={coordinates.longitude}
                  name={name}
                  pathname={pathname}
                />
              );
            })
          : null}*/}
      </GoogleMap>
    </div>
  );
};

export default React.memo(MapContainer);

import React, {useEffect, useState} from "react";
import { useHistory } from "react-router-dom";
import { useToasts } from "react-toast-notifications";
import Header from "../../AdminGeneric/Header/AdminHeader";
import Menu from "../../AdminGeneric/Menu/AdminMenu";
import Breadcrumbs from "../../../Breadcrumbs/Breadcrumbs";
import Buttons from "../../AdminGeneric/Buttons/Buttons";
import TextInput from "../../Forms/CustomInputs/TextInput";
import FileInput from "../../Forms/CustomInputs/FileInput";
import Textarea from "../../Forms/CustomInputs/Textarea";
import {addComplexPromotionRequest, getComlextPromotionByIdRequest} from "../../../API/complexesActions";

const AddComplexPromotion = (props) => {
  const id = props.location.state.objectId;
  const itemId = props.location.state.itemId;
  const [formData, setFormData] = useState({
    name: '',
    description: '',
    startDate: (new Date()).toISOString(),
    finishDate: (new Date()).toISOString(),
  });
  const [loadingStarted, toggleLoadingStart] = useState(false);
  const { addToast } = useToasts();
  const history = useHistory();
  const goToMain = () => {
    history.push("/admin/complex/" + id + "/promotions");
  };

  // функция отправки формы
  const handleFormSubmit = (data, event) => {
    event.preventDefault();
    if (itemId !== null) {
      data.itemId = itemId;
    }
    data.startDate = (new Date(data.startDate)).toISOString();
    data.finishDate = (new Date(data.finishDate)).toISOString();
    addComplexPromotionRequest(id, data).then(()=>{
      addToast("Успешно обновлено", {
        appearance: "success",
        autoDismiss: true,
      });
      return new Promise((resolve) => {
        setTimeout(() => {
          resolve(() => goToMain());
        }, 1000);
      });
    })
      .then((goToMain) => {
        goToMain();
      }).catch(()=>{
      addToast(
        "Ошибка. Проверьте правильность заполнения полей формы. Они не должны быть пустыми.",
        {
          appearance: "error",
          autoDismiss: true,
        }
      );
    });
  };

  useEffect(() => {
    if (itemId !== null) {
      toggleLoadingStart(true);
      getComlextPromotionByIdRequest(id, itemId).then((response) => {
        setFormData(response.data);
        toggleLoadingStart(false);
      }).catch(() => {
        addToast(
          "Ошибка чтения",
          {
            appearance: "error",
            autoDismiss: true,
          }
        );
        setTimeout(() => {
          goToMain();
        }, 1000);
      });
    } else {
      setFormData(formData);
    }
    // eslint-disable-next-line
  }, []);

  const changeFormData = (name, value) => {
    setFormData({
      ...formData,
      [name]: value,
    });
  };

  return (
    <div>
      <Header />
      <div className="admin-wrapper">
        <Menu />
        <div className="admin-content">
          <Breadcrumbs />
          {loadingStarted ? (
            <p className="loader-admin">Loading...</p>
          ) : (
            <form
              className="admin-form"
              onSubmit={(event) => handleFormSubmit(formData, event)}
            >
              <div className="admin-item__wrapper admin-item__wrapper--custom">
                <div className="admin-form__item item">
                  <label className="admin-item__label">Акция</label>
                  <TextInput
                    className="admin-item__field"
                    type="text" placeholder="Введите акцию" name="name" value={formData.name} changeValue={changeFormData}/>
                </div>
              </div>

              <div className="admin-item__wrapper admin-item__wrapper--custom">
                <div className="admin-form__item item">
                  <label className="admin-item__label">Дата начала</label>
                  <TextInput
                    className="admin-item__field"
                    type="datetime-local" placeholder="Введите дату" name="startDate" value={formData.startDate} changeValue={changeFormData}/>
                </div>
              </div>

              <div className="admin-item__wrapper admin-item__wrapper--custom">
                <div className="admin-form__item item">
                  <label className="admin-item__label">Дата окончания</label>
                  <TextInput
                    className="admin-item__field"
                    type="datetime-local" placeholder="Введите дату" name="finishDate" value={formData.finishDate} changeValue={changeFormData}/>
                </div>
              </div>

              <div className="admin-item__wrapper admin-item__wrapper--custom">
                <div className="admin-form__item item">
                  <label className="admin-item__label">Описание</label>
                  <Textarea
                    className="admin-item__field"
                    type="text" placeholder="Описание" name="description" value={formData.description} changeValue={changeFormData}/>
                </div>
              </div>

              <Buttons
                disabled={null}
                textAdd={
                  itemId === null ? "Добавить" : "Изменить"
                }
              />
            </form>
          )}
        </div>
      </div>
    </div>
  );
}

export default AddComplexPromotion;

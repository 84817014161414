import React from "react";

import Button from "../../../Components/Generic/Button/Button";

import { deleteUserRequest } from "../../../API/userActions";
import { deleteVacancyRequest } from "../../../API/vacanciesAction";
import { deleteCardRequest } from "../../../API/cardsAction";
import { deleteComplexRequest } from "../../../API/complexesActions";

import "./Row.scss";

const Row = (props) => {
  const {
    id,
    pageType,
    publicId,
    name,
    date,
    children,
    style,
    index,
    handleDelete,
  } = props;

  // функция для преобразования формата даты
  const changeDateFormat = (date) => {
    const newDate = new Date(date);
    let day = newDate.getDate();
    if (day < 10) day = "0" + day;
    let month = newDate.getMonth() + 1;
    if (month < 10) month = "0" + month;
    let year = newDate.getFullYear();
    return day + "." + month + "." + year;
  };

  // функция определяющая какой айтем удалить в зависимости от типа страницы
  const deleteItemAccordingPageType = () => {
    if (pageType === "user") {
      deleteUserRequest(id).then(() => {
        handleDelete(index);
      });
    } else if (pageType === "vacancy") {
      deleteVacancyRequest(id).then(() => {
        handleDelete(index);
      });
    } else if (pageType === "object") {
      deleteCardRequest(id).then(() => {
        handleDelete(index);
      });
    } else if (pageType === "complex") {
      deleteComplexRequest(id).then(() => {
        handleDelete(index);
      });
    }
  };
  return (
    <div className="row-object" style={{
      maxWidth: "initial",
    }}>
      <p
        className="filter__item"
        style={
          pageType === "object"
            ? { maxWidth: "70px", marginRight: "3.5%" }
            : pageType === "complex"
            ? { marginRight: "10%" }
            : { maxWidth: "100px" }
        }
      >
        {publicId}
      </p>
      {/*<p className="filter__item filter__item--name" style={style}>{name}</p>*/}
      {children[0]}
      {children[15]}
      {children[16]}
      {children[2]}


      {children[4]}
      {children[5]}
      {children[6]}
      {children[7]}
      {children[8]}
      {children[9]}
      {children[10]}
      {children[11]}
      {children[12]}
      {children[13]}
      {children[14]}

      <p
        className="filter__item"
        style={
          pageType === "object"
            ? { maxWidth: "80px", marginRight: "3.5%" }
            : pageType === "complex"
            ? { marginRight: "10%" }
            : { maxWidth: "100px" }
        }
      >
        {date ? changeDateFormat(date) : "Не указано"}
      </p>

      {pageType === "complex" ? null : (
        <div className="filter__item " style={style}>
          {children[1]}
        </div>
      )}

      {children[3]}

      <Button
        className="adminBtn--delete"
        type="submit"
        text={"Удалить"}
        onClick={() => deleteItemAccordingPageType()}
      />
    </div>
  );
};

export default Row;

import React, {useState} from "react";
import {useToasts} from "react-toast-notifications";
import Breadcrumbs from "../../../Breadcrumbs/Breadcrumbs";
import Header from "../../AdminGeneric/Header/AdminHeader";
import Menu from "../../AdminGeneric/Menu/AdminMenu";

import encode from "../../Forms/EncodeFile";
import SVG from "react-inlinesvg";

import IconDeleteSVG from "../../../static/images/iconClick_actions/delete.svg";
import Button from "../../../Components/Generic/Button/Button";
import XLSX from "xlsx";
import Execute from "./execute";

import {
    importCardRequest,
} from "../../../API/cardsAction";
import "./Objects.scss";

const Row = (props) => {
    const {children} = props;
    return (
        <div className="row-object">
            {children[0]}
            {children[1]}
            {children[2]}
        </div>
    );
};

const CustomFileInput = ({value, name, returnas, text, multiple, group, changeValue}) => {
    const inputRef = React.createRef();
    const [uploadedImages, setUploadedImages] = useState(value);

    return (
        <div className="dropzone-cv__wrapper">
            <div className="dropzone-cv">
                <input
                    className="uploadFile "
                    type="file"
                    name={name}
                    id={name}
                    accept={['.xlsx', 'application/vnd.ms-excel']}
                    returnas={returnas}
                    multiple={multiple}
                    group={group}
                    ref={inputRef}
                    onInput={async () => {
                        const convertedBg = await encode(inputRef.current.files[0]);
                        setUploadedImages(convertedBg);
                    }}
                />
                <label htmlFor={name}>{text}</label>
            </div>
            <div className="droped-names" style={{position: "relative"}}>
                <ul className="droped-list">
                    {uploadedImages ? (
                        <li className="droped-item">
                            <SVG
                                className="droped-svg"
                                onClick={() => setUploadedImages(null)}
                                src={IconDeleteSVG}
                            />
                            File
                        </li>
                    ) : null}
                </ul>
            </div>
        </div>
    );
};

const mapExcel = [
    {name: 'publicId', label: "ID кв-ры"},
    {name: 'price', label: "Цена"},
    {name: 'floor', label: "Этаж"},
    {name: 'allFloors', label: "Этажность"},
    {name: 'square', label: "Квадрат"},
    {name: 'rooms', label: 'Комнат'},
    {name: 'material', label: 'Кир/Газ/Пан', group: "filters"},
    {name: 'secondaryClass', label: 'Планировка', group: "filters"},
    {name: 'docs', label: 'Студ/Обыч', group: "business"},
    {name: 'terms', label: 'Ч/50*50', group: "business"},
    {name: 'realtor', label: 'Риелтор'},
    {name: 'condition', label: 'Описание'}, // Ремонт
    {name: 'area', label: "Метраж"}, // Общая/Жилая/Кухня
    {name: 'address', label: "Адрес"}, // Улица, Номер дома, Номер квартиры
    {name: 'searchStreet', label: 'Дн/Доб'}, // Имя хозяина, номер телефона
    {name: 'owner', label: 'тел.хозяина'}, // Имя хозяина, номер телефона
    {name: 'photo', label: 'Фото'}, // Имя хозяина, номер телефона
    /*{name: 'name', label: 'Название'},
    {name: 'publicType', label: 'Тип'},
    {name: 'district', label: "Район"},
    {name: 'subDistrict', label: "Микрорайон"},
    {name: 'street', label: "Улица"},
    {name: 'price', label: "Цена"},
    {name: 'credit', label: "Рассрочка"},
    {name: 'publicUrgency', label: "Срочность"},
    {name: 'floor', label: 'Этаж'},
    {name: 'allFloors', label: 'Общая этажность'},
    {name: 'rooms', label: 'Кол-во комнат'},
    {name: 'space', label: 'Метраж'},
    {name: 'square', label: 'Квадрат'},
    {name: 'primaryClass', label: 'Класс жилья', group: "filters"},
    {name: 'condition', label: 'Ремонт', group: "filters"},
    {name: 'material', label: 'Материал', group: "filters"},
    {name: 'disposition', label: 'Расположение', group: "filters"},
    {name: 'description', label: 'Описание'},
    {name: 'realtor', label: 'Риелтор'},
    {name: 'terms', label: 'Условия сделки', group: "business"},
    {name: 'docs', label: 'Документы', group: "business"},
    {name: 'flatNum', label: 'Номер квартиры', group: "private"},
    {name: 'firstName', label: 'Имя владельца', group: "private"},
    {name: 'phone', label: 'Телефон', group: "private"},
    {name: 'houseNum', label: 'Номер дома / офиса', group: "private"},*/
];

const ImportObjects = () => {

    const {addToast} = useToasts();
    const [loadingStarted, toggleLoadingStart] = useState(false);
    const [errorCardsForRender, setErrorCardsForRender] = useState([]);

    const chunk = function (array, size) {
        let resultArray = [];

        for (let i = 0; i < array.length; i += size) {
            resultArray.push(array.slice(i, i + size));
        }

        return resultArray;
    }

    const handleImport = async (event) => {
        event.preventDefault();
        let fi = document.getElementById("file");

        setErrorCardsForRender([]);

        if (!fi || fi.files.length < 1) {
            addToast("Выберите файл для импорта", {
                appearance: "error",
                autoDismiss: true,
            });
            return;
        }
        toggleLoadingStart(true);
        const reader = new FileReader();

        reader.onload = function (e) {
            var data = e.target.result;
            var workbook = XLSX.read(data, {
                type: 'binary'
            });

            //let workBookSheets = Object.keys(workbook.Sheets);

            const arObj = [];
            workbook.SheetNames.forEach(function (sheetName) {
                const XL_row_object = XLSX.utils.sheet_to_row_object_array(workbook.Sheets[sheetName]);

                XL_row_object.forEach((item, index) => {
                    const obj = {sheet: sheetName};
                    mapExcel.forEach((mapItem) => {
                        obj[mapItem.name] = '';
                        if (item[mapItem.label]) {
                            obj[mapItem.name] = item[mapItem.label].toString().toLowerCase();
                        }
                    });

                    arObj.push(obj);
                });
            });

            if (arObj.length < 1) {
                toggleLoadingStart(false);
                addToast("Нет данных для импорта", {
                    appearance: "error",
                    autoDismiss: true,
                });
                return;
            }

            let act = new Execute({
                data: arObj,
                start: function (next) {
                    setTimeout(() => next(), 100);
                },
                process: function (next) {
                    importCardRequest({rows: this.rows}).then((response) => {
                        if (response.data.success) {
                            addToast("Данные успешно импортированы", {
                                appearance: "success",
                                autoDismiss: true,
                            });
                        } else {
                            let newErrorCardsForRender = [...errorCardsForRender, ...response.data.errors]
                            setErrorCardsForRender(newErrorCardsForRender);
                        }
                    }).catch((error) => {
                        addToast("Ошибка импорта файла Excel", {
                            appearance: "error",
                            autoDismiss: true,
                        });
                    }).then(() => {
                        setTimeout(() => next(), 100);
                    });
                },
                finish: function () {
                    toggleLoadingStart(false);
                }
            });

            act.start();

            // Chunk example
            //let newArObj = chunk(arObj, 100);
            //console.log(newArObj);
        };

        reader.onerror = function (ex) {
            toggleLoadingStart(false);
            addToast("Ошибка чтения файла Excel", {
                appearance: "error",
                autoDismiss: true,
            });
        };

        reader.readAsBinaryString(fi.files[0]);
    }

    return (
        <div>
            <Header/>
            <div className="admin-wrapper">
                <Menu/>
                <div className="admin-content">
                    <Breadcrumbs/>
                    {
                        !loadingStarted ?
                            <form onSubmit={handleImport}>
                                <CustomFileInput
                                    id="file"
                                    name="file"
                                    type="file"
                                    text={"Нажмите что бы загрузить xls"}
                                    multiple={false}
                                    returnas={""}
                                    value={""}
                                />
                                <Button
                                    className="adminBtn--add"
                                    type="submit"
                                    text={"Импорт"}
                                />
                            </form>
                            : ''
                    }
                    <div className="header-row">
                        <div className="filter__item"><p>Лист</p></div>
                        <div className="filter__item"><p>Строка</p></div>
                        <div className="filter__item"><p>Ошибка</p></div>
                    </div>

                    <div className="filter__item__error">
                    {loadingStarted ? (
                        <p className="loader-admin">Import...</p>
                    ) : (
                        errorCardsForRender.map((item, index) => {
                            return (
                                <Row
                                    key={index}
                                    index={index}
                                >
                                    <p className="filter__item col-num-1">
                                        {item.sheet}
                                    </p>
                                    <p className="filter__item col-num-2">
                                        {item.row}
                                    </p>
                                    <p className="filter__item col-num-3">
                                        {item.error}
                                    </p>
                                </Row>
                            );
                        })
                    )}
                    </div>
                </div>
            </div>
        </div>
    );
}

export default ImportObjects;

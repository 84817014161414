import React from "react";
import { useHistory } from "react-router-dom";
import Breadcrumbs from "../../../Breadcrumbs/Breadcrumbs";
import Select from "react-select";

import Header from "../../AdminGeneric/Header/AdminHeader";
import Menu from "../../AdminGeneric/Menu/AdminMenu";

import { customStyles } from "../SelectCustomStyles";

import "../../Forms/Form.scss";

const optionsType = [
  { value: "secondary", label: "Вторичка" },
  { value: "primary", label: "Первичка" },
  { value: "house", label: "Дома и участки" },
  { value: "commercial", label: "Коммерческая недвижимость" },
];

const AddObject = () => {
  const history = useHistory();

  const selectedType = (event) => {
    history.push({
      pathname: "/admin/add-object-type",
      state: {
        objectPublicType: event.value,
        requestFunction: "getCardSample",
        submitFunction: "createCardRequest",
      },
    });
  };

  return (
    <div>
      <Header />
      <div className="admin-wrapper">
        <Menu />
        <div className="admin-content">
          <Breadcrumbs />
          <div className="admin-select">
            <label className="admin-select__label">Тип объекта</label>
            <Select
              onChange={(event) => selectedType(event)}
              placeholder={"Выберите"}
              options={optionsType}
              styles={customStyles}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default AddObject;

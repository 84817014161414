import React, { useState, useEffect } from "react";
import {useHistory} from "react-router-dom";
import Breadcrumbs from "../../Breadcrumbs/Breadcrumbs";
import { useToasts } from "react-toast-notifications";

import Header from "../AdminGeneric/Header/AdminHeader";
import Menu from "../AdminGeneric/Menu/AdminMenu";
import Buttons from "../AdminGeneric/Buttons/Buttons";

import { ObjectTypeData } from "./Data/ObjectTypeData";

import {
  getCardSample,
  getCardById,
  updateCardRequest,
  createCardRequest,
} from "../../API/cardsAction";

import "./Form.scss";
import useGlobal from "../../store";

const styles = {
  marginBottom: "11px",
};

const {
  nameGroup,
  addressGroup,
  priceGroup,
  characteristicsGroup,
  objectInfoGroup,
  descriptionGroup,
  businessInfoGroup,
  privateInfoGroup,
  backgroundImageGroup,
  galleryImagesGroup,
} = ObjectTypeData;

const AddOrEditObjectType = (props) => {
  const [globalState, globalActions] = useGlobal();
  const [formData, setFormData] = useState();
  const [id, setId] = useState();
  const [type, setType] = useState();
  const [requestFunction, setRequestFunction] = useState();
  const [submitFunction, setSubmitFunction] = useState();
  const [receivedData, toggleReceivedData] = useState(false);
  const [loadingStarted, toggleLoadingStart] = useState(false);
  const { addToast } = useToasts();
  const history = useHistory();
  const search = new URLSearchParams(props.location.search)

  useState(() => {
    const objectId = search.get('objectId') ? search.get('objectId') : props.location.state.objectId
    setId(objectId);

    const objectPublicType = search.get('objectPublicType') ? search.get('objectPublicType') : props.location.state.objectPublicType
    setType(objectPublicType);

    const requestFunction = search.get('requestFunction') ? search.get('requestFunction') : props.location.state.requestFunction
    setRequestFunction(requestFunction);

    const submitFunction = search.get('submitFunction') ? search.get('submitFunction') : props.location.state.submitFunction
    setSubmitFunction(submitFunction);
  });

  const goToAdmin = () => {
    history.push("/admin");
  };

  useEffect(() => {
    toggleLoadingStart(true);
    if (requestFunction === "getCardSample") {
      getCardSample(type)
        .then((response) => {
          setFormData(response.data);
          toggleReceivedData(true);
        })
        .then(() => {
          toggleLoadingStart(false);
        });
    } else if (requestFunction === "getCardById") {
      getCardById(id)
        .then((response) => {
          setFormData(response.data);
          toggleReceivedData(true);
        })
        .then(() => {
          toggleLoadingStart(false);
        });
    }
    // eslint-disable-next-line
  }, []);

  const changeFormData = (name, value) => {
    setFormData({
      ...formData,
      [name]: value,
    });
  };

  // функция для рендера полей формы (в том числе и селектов)
  const renderInputForm = (array) => {
    return array.map(
      (
        { group, Component, label, name, parent, placeholder, type, request },
        index
      ) => {
        // проверяем наличие группы - если есть, берем значение которое лежит в группе, либо сразу значение formData
        const isExist = group ? formData[group][name] : formData[name];
        return isExist !== undefined ? (
          <div className="admin-form__item item" key={index}>
            <label className="admin-item__label">{label}</label>
            <Component
              key={index}
              parent={formData[parent]}
              type={type}
              placeholder={placeholder}
              group={group}
              name={name}
              checked={formData[name]}
              // если есть группа - возьми то что записано по этой группе в formData если нет - возьми значение
              value={group ? formData[group] : formData[name]}
              changeValue={changeFormData}
              request={request}
            />
          </div>
        ) : null;
      }
    );
  };

  // функция для рендера инпута тип файл (туда попадают картинки)
  const renderImages = (array) => {
    return array.map(
      (
        { Component, type, label, name, multiple, header, text, returnas },
        index
      ) => {
        return formData[name] !== undefined ? (
          <div className="admin-form__item item" key={index}>
            <label className="admin-item__label">{label}</label>
            <Component
              name={name}
              type={type}
              text={text}
              header={header}
              id={name}
              multiple={multiple}
              returnas={returnas}
              value={formData[name] === "" ? "" : formData[name]}
              changeValue={changeFormData}
            />
          </div>
        ) : null;
      }
    );
  };

  // функция отправки формы
  const handleFormSubmit = (data, event) => {
    event.preventDefault();
    if (submitFunction === "updateCardRequest") {
      updateCardRequest(data)
        .then((response) => {
          addToast("Объект успешно изменен", {
            appearance: "success",
            autoDismiss: true,
          });
          return new Promise((resolve) => {
            setTimeout(() => {
              resolve(() => goToAdmin());
            }, 1000);
          });
        })
        .then((goToAdmin) => {
          goToAdmin();
        })
        .catch((error) => {
          addToast(
            "Изменить объект не удалось. Проверьте правильность заполнения полей формы. Они не должны быть пустыми.",
            {
              appearance: "error",
              autoDismiss: true,
            }
          );
        });
    } else if (submitFunction === "createCardRequest") {
      createCardRequest(data)
        .then((response) => {
          addToast("Объект успешно добавлен", {
            appearance: "success",
            autoDismiss: true,
          });
          return new Promise((resolve) => {
            setTimeout(() => {
              resolve(() => goToAdmin());
            }, 1000);
          });
        })
        .then((goToAdmin) => {
          goToAdmin();
        })
        .catch((error) => {
          addToast(
            "Добавить объект не удалось. Проверьте правильность заполнения полей формы. Они не должны быть пустыми.",
            {
              appearance: "error",
              autoDismiss: true,
            }
          );
        });
    }
  };

  // определяем правильный вывод типа карточки
  const setRightTypeName = (type) => {
    if (type === "вторичка" || type === "secondary") {
      return "Вторичка";
    } else if (type === "первичка" || type === "primary") {
      return "Первичка";
    } else if (type === "коммерческая недвижимость" || type === "commercial") {
      return "Комерческая неджижимость";
    } else if (type === "дома и участки" || type === "house") {
      return "Дома и участки";
    }
  };

  return (
    <div>
      <Header />
      <div className="admin-wrapper">
        <Menu />
        <div className="admin-content">
          <Breadcrumbs />
          {loadingStarted ? (
            <p className="loader-admin">Loading...</p>
          ) : (
            <form
              className="admin-form"
              onSubmit={(event) => handleFormSubmit(formData, event)}
            >
              <div className="admin-item__wrapper">
                <div className="custom-select">
                  <div className="custom-select__wrapper">
                    <p
                      className="custom-select__label"
                      style={{ width: "100%" }}
                    >
                      {setRightTypeName(type)}
                    </p>
                  </div>
                </div>
              </div>
              <div className="admin-item__wrapper">
                {receivedData ? renderInputForm(nameGroup) : null}
              </div>
              <label className="admin-item__label admin-item__label--prev">
                Адресс
              </label>
              <div className="admin-item__wrapper admin-item__wrapper--custom">
                {receivedData ? renderInputForm(addressGroup) : null}
              </div>
              <div className="admin-item__wrapper">
                {receivedData ? renderInputForm(priceGroup) : null}
              </div>
              <label className="admin-item__label admin-item__label--prev">
                Характеристики
              </label>
              <div className="admin-item__wrapper admin-item__wrapper--characterist">
                {receivedData ? renderInputForm(characteristicsGroup) : null}
              </div>
              <label className="admin-item__label admin-item__label--prev">
                О жилье
              </label>
              <div className="admin-item__wrapper">
                {receivedData ? renderInputForm(objectInfoGroup) : null}
              </div>

              <div style={styles}>
                {receivedData ? renderImages(backgroundImageGroup) : null}
              </div>

              <div style={styles}>
                {receivedData ? renderImages(galleryImagesGroup) : null}
              </div>

              <div style={styles}>
                {receivedData ? renderInputForm(descriptionGroup) : null}
              </div>
              <label className="admin-item__label admin-item__label--prev">
                Юридические данные
              </label>
              <div className="admin-item__wrapper">
                {receivedData ? renderInputForm(businessInfoGroup) : null}
              </div>
              <label className="admin-item__label admin-item__label--prev">
                О владельце
              </label>
              <div className="admin-item__wrapper">
                {receivedData ? renderInputForm(privateInfoGroup) : null}
              </div>
              <Buttons isAdmin={globalState.user.role === 'admin'}
                disabled={null}
                textAdd={
                  requestFunction === "getCardSample" ? "Добавить" : "Изменить"
                }
                cancelInput={goToAdmin}
              />
            </form>
          )}
        </div>
      </div>
    </div>
  );
};

export default AddOrEditObjectType;

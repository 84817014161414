export const customStyles = {
  control: (base, state) => ({
    ...base,
    border: "none",
    borderColor: state.isFocused ? null : null,
    boxShadow: state.isFocused ? null : null,
    "&:hover": {
      borderColor: state.isFocused ? null : null,
    },
  }),

  option: (styles, { data, isDisabled, isFocused, isSelected }) => ({
    ...styles,
    color: isDisabled,
    // eslint-disable-next-line
    color: isFocused ? "white" : null,
     // eslint-disable-next-line
    color: isSelected ? "white" : null,

    background: isDisabled
      ? null
      : isSelected
      ? "linear-gradient(90deg, #d97d34 0%, #fff351 100%)"
      : isFocused
      ? "linear-gradient(90deg, #d97d34 0%, #fff351 100%)"
      : null,
  }),
  indicatorSeparator: () => {},
  dropdownIndicator: (provided, state) => ({
    ...provided,
    transform: state.selectProps.menuIsOpen && "rotate(180deg)",
  }),
};

import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import encode from "../EncodeFile";
import SVG from "react-inlinesvg";

import IconDeleteSVG from "../../../static/images/iconClick_actions/delete.svg";

const ImagePreview = ({ url, index, deleteImg }) => {
  const [hover, setHover] = useState(false);
  useEffect(() => {
    if (hover)
      setTimeout(() => {
        setHover(false);
      }, 2000);
  }, [hover]);

  return (
    <div>
      {hover ? <img className="droped-img" alt="#" src={url} /> : null}
      {url ? (
        <li
          className="droped-item"
          key={index}
          onMouseEnter={() => setHover(true)}
        >
          <SVG className="droped-svg" onClick={deleteImg} src={IconDeleteSVG} />
          <Link to={{ pathname: url }} target="_blank">Image</Link>
        </li>
      ) : null}
    </div>
  );
};

const showImagesList = (uploadedImages, deleteImage) => {
  if (!uploadedImages) {
    return;
  }
  return uploadedImages.map((item, index) => {
    return (
      <ImagePreview
        key={index}
        url={item}
        index={index + 1}
        deleteImg={() => deleteImage(index)}
      />
    );
  });
};

const CustomFileInput = ({
  value,
  name,
  returnas,
  text,
  multiple,
  header,
  group,
  changeValue,
}) => {

  const inputRef = React.createRef();
  const [uploadedImages, setUploadedImages] = useState(
    group ? value[name] : value
  );
  useEffect(() => {
    group
      ? changeValue(group, { ...value, [name]: uploadedImages })
      : changeValue(name, uploadedImages);
    // eslint-disable-next-line
  }, [uploadedImages]);

  const deleteImage = (index) => {
    const newUploadedImages = [...uploadedImages];
    newUploadedImages[index] = null;
    setUploadedImages([...newUploadedImages]);
  };

  return (
    <div className="dropzone-wrapper">
      <div className="uploadFile__wrapper">
        <input
          className="uploadFile "
          type="file"
          name={name}
          id={name}
          accept={"image/*"}
          returnas={returnas}
          multiple={multiple}
          group={group}
          ref={inputRef}
          onInput={async () => {
            if (typeof returnas === "string") {
              const convertedBg = await encode(inputRef.current.files[0]);
              setUploadedImages(convertedBg);
            }
            if (typeof returnas === "object") {
              const inputRefCurrentFiles = [...inputRef.current.files];
              const convertedImgs = await Promise.all(
                inputRefCurrentFiles.map(async (item) => await encode(item))
              );
              setUploadedImages([...uploadedImages, ...convertedImgs]);
            }
          }}
        />
        <label htmlFor={name}>{text}</label>
      </div>
      <div className="droped-names" style={{ position: "relative" }}>
        <h5 className="droped-names__header">{header}</h5>
        <ul className="droped-list">
          {typeof uploadedImages === "string" ? (
            <ImagePreview url={uploadedImages} index={1} />
          ) : (
            showImagesList(uploadedImages, deleteImage)
          )}
        </ul>
      </div>
    </div>
  );
};

export default CustomFileInput;

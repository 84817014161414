import CustomSelect from "../CustomInputs/Select";
import CustomInput from "../CustomInputs/TextInput";
import CustomTextArea from "../CustomInputs/Textarea";
import CustomFileInput from "../CustomInputs/FileInput";
import CustomCheckbox from "../CustomInputs/Checkbox";

import {
  getFilterByNameRequest,
  getDistrictsList,
  getSubDistrictsList,
} from "../../../API/filtersActions";
import { getComplexNames } from "../../../API/complexesActions";

export const ObjectTypeData = {
  nameGroup: [
    {
      label: null,
      placeholder: 'Активный',
      name: "active",
      type: "checkbox",
      Component: CustomCheckbox,
    },
    {
      label: "ID",
      placeholder: "ID",
      name: "publicId",
      type: "text",
      Component: CustomInput,
    },
    {
      label: "Название",
      placeholder: "Название",
      name: "name",
      type: "text",
      Component: CustomInput,
    },
  ],

  addressGroup: [
    {
      label: "Район",
      placeholder: "Район",
      name: "district",
      Component: CustomSelect,
      request: getDistrictsList,
    },

    {
      label: "Микрорайон",
      placeholder: "Микрорайон",
      name: "subDistrict",
      Component: CustomSelect,
      parent: "district",
      request: getSubDistrictsList,
    },

    {
      label: "Улица",
      placeholder: "Улица",
      name: "street",
      type: "text",
      Component: CustomInput,
    },
  ],

  priceGroup: [
    {
      label: "Цена",
      placeholder: "Цена",
      name: "price",
      type: "number",
      Component: CustomInput,
    },
    {
      label: "Рассрочка",
      placeholder: "Рассрочка",
      name: "credit",
      type: "text",
      Component: CustomInput,
    },
    {
      label: null,
      name: "urgency",
      type: "checkbox",
      Component: CustomCheckbox,
    },
  ],

  characteristicsGroup: [
    {
      label: "Этаж",
      placeholder: "Этаж",
      name: "floor",
      type: "number",
      Component: CustomInput,
    },
    {
      label: "Общая этажность",
      placeholder: "Общая этажность",
      name: "allFloors",
      type: "number",
      Component: CustomInput,
    },
    {
      label: "Кол-во комнат",
      placeholder: "Кол-во комнат",
      name: "rooms",
      type: "number",
      Component: CustomInput,
    },
    {
      label: "Метраж",
      placeholder: "Метраж",
      name: "space",
      type: "number",
      Component: CustomInput,
    },
    {
      label: "Кол-во соток",
      placeholder: "Кол-во соток",
      name: "yard",
      type: "number",
      Component: CustomInput,
    },
    {
      label: "Квадрат",
      placeholder: "Квадрат",
      name: "square",
      type: "number",
      Component: CustomSelect,
      request: function(name) {
        return new Promise((resolve, reject) => {
          setTimeout(() => {
            resolve({ data: [
              {_id: 0, name: ""},
              {_id: 1, name: "1"},
              {_id: 2, name: "2"},
              {_id: 3, name: "3"},
              {_id: 4, name: "4"},
              {_id: 5, name: "5"},
              {_id: 6, name: "6"},
            ]});
          },10);
        });
      },
    },
  ],

  objectInfoGroup: [
    {
      label: "Класс жилья",
      placeholder: "Класс жилья",
      name: "secondaryClass",
      group: "filters",
      Component: CustomSelect,
      request: getFilterByNameRequest,
    },
    {
      label: "Комплекс",
      placeholder: "Комплекс",
      name: "complex",
      Component: CustomSelect,
      request: getComplexNames,
    },
    {
      label: "Класс жилья",
      placeholder: "Класс жилья",
      name: "primaryClass",
      group: "filters",
      Component: CustomSelect,
      request: getFilterByNameRequest,
    },
    {
      label: "Ремонт",
      placeholder: "Ремонт",
      name: "condition",
      group: "filters",
      Component: CustomSelect,
      request: getFilterByNameRequest,
    },
    {
      label: "Материал",
      placeholder: "Материал",
      name: "material",
      group: "filters",
      Component: CustomSelect,
      request: getFilterByNameRequest,
    },
    {
      label: "Расположение",
      placeholder: "Расположение",
      name: "disposition",
      group: "filters",
      Component: CustomSelect,
      request: getFilterByNameRequest,
    },
  ],

  descriptionGroup: [
    {
      label: "Описание",
      placeholder: "Текст",
      name: "description",
      type: "text",
      Component: CustomTextArea,
    },
    {
      label: "Риелтор",
      placeholder: "Риелтор",
      name: "realtor",
      type: "text",
      Component: CustomInput,
    },
  ],

  businessInfoGroup: [
    {
      label: "Условия сделки",
      placeholder: "Условия сделки",
      name: "terms",
      group: "business",
      Component: CustomSelect,
      request: getFilterByNameRequest,
    },
    {
      label: "Документы",
      placeholder: "Документы",
      name: "docs",
      group: "business",
      Component: CustomSelect,
      request: getFilterByNameRequest,
    },
  ],

  privateInfoGroup: [
    {
      label: "Имя владельца",
      placeholder: "Имя владельца",
      name: "firstName",
      group: "private",
      type: "text",
      Component: CustomInput,
    },
    {
      label: "Номер телефона (+380)",
      placeholder: "Номер телефона (+380)",
      name: "phone",
      group: "private",
      type: "text",
      Component: CustomInput,
    },
    {
      label: "Номер квартиры",
      placeholder: "Номер квартиры",
      name: "flatNum",
      group: "private",
      type: "text",
      Component: CustomInput,
    },
    {
      label: "Номер дома / офиса",
      placeholder: "Номер дома / офиса",
      name: "houseNum",
      group: "private",
      type: "text",
      Component: CustomInput,
    },
  ],

  backgroundImageGroup: [
    {
      label: "Главное изображение",
      name: "backgroundImage",
      type: "file",
      multiple: false,
      returnas: "",
      header: "File:",
      text: "Click here to upload one image. Total no more than 10MB",
      Component: CustomFileInput,
    },
    {
      label: "Видео",
      placeholder: "Видео",
      name: "videoUrl",
      type: "text",
      Component: CustomInput,
    },
  ],

  galleryImagesGroup: [
    {
      label: "Галерея общая",
      name: "galleryImages",
      type: "file",
      multiple: true,
      returnas: [],
      header: "Files:",
      text: "Click here to upload images. Total no more than 10MB",
      Component: CustomFileInput,
    },
  ],
};

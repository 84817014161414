import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";

import Header from "../../Components/Main/Header/Header";
import SliderComp from "../../Components/Main/SliderComp/SliderComp";
import Feedback from "../../Components/Generic/Feedback/Feedback";
import Footer from "../../Components/Main/Footer/Footer";
import ReviewSlides from "../../Components/Generic/ReviewSlides/ReviewSlides";
import Card from "../../Pages/CardsAndFilters/Card";

import "./MainPage.scss";

// import MainBg from "../../static/images/mainBg.png";
import Review1 from "../../static/images/reviews/1.png";
import Review2 from "../../static/images/reviews/2.png";
import Review3 from "../../static/images/reviews/3.png";

import useWindowWidth from "../../CustomHook/useWindowWidth";
// import { generateLogosArray } from "../../Components/FunctionGeneration/FunctionGeneration";

import { getAllCardsRequest } from "../../API/cardsAction";
import { getBuildersList } from "../../API/complexesActions";
import config from "../../API/config";

const sliderPartnerSlyles = {
  height: "225px",
};

const sliderOfferSlyles = {
  height: "610px",
};

const sliderReviewSlyles = {
  height: "320px",
};

const feedBackStyle = {
  marginBottom: "40px",
};

const cardStyles = {
  display: "flex",
  flexDirection: "column",
  textAlign: "left",
  maxWidth: "312px",
  margin: "0 auto",
  minHeight: "550px",
};

const cardImgStyles = {
  margin: " 0 auto 28px",
  display: "block",
};

const MainPage = () => {
  const windowWidth = useWindowWidth();
  const [cards, setCards] = useState([]);
  const [buildersList, setBuildersList] = useState([]);
  // eslint-disable-next-line
  const [requiredCards, setRequiredCards] = useState({
    list: { amount: 6 },
    public: { urgency: true },
  });
  const [reviews, setReviews] = useState([]);

  useEffect(() => {
    getAllCardsRequest(requiredCards).then((response) => {
      const cardData = response.data;
      setCards(cardData);
    });
    getBuildersList().then((response) => {
      const buildersData = response.data;
      setBuildersList(buildersData);
    });

    let reviews = [
      {
        'description': 'АН Парламент помогли срочно продать квартиру. Такой оперативной работы я никогда не видела.\n' +
            'Всё прошло быстро, спокойно и с обоюдным удовлетворением всех сторон.',
        'name': 'Ирина',
        'image': Review1,
      },
      {
        'description': 'Мы с семьёй очень долго искали подходящую нам квартиру, с тремя детьми сложно найти что-то удобное, красивое и одновременно не заоблачно дорогое.\n' +
            'Найти нам удалось только благодаря специалистам АН Парламент, которые провели анализ имеющихся предложений на рынке и проведя переговоры с несколькими собственниками.\n' +
            'Всё семейство довольно выбранным новым домом)',
        'name': 'Людмила',
        'image': Review2,
      },
      {
        'description': 'Я был в поисках нескольких небольших помещений для открытия кофейни и магазина.\n' +
            'Многих нюансов я не знал, риелтора Агентства Парламент помогли мне разобраться и подобрать хорошие варианты.\n' +
            'Выходящие на фасад здания, и расположенные в проходном месте.\n' +
            'Они сопровождали меня с момента подбора до получения ключей.',
        'name': 'Дмитрий',
        'image': Review3,
      }
    ]
    setReviews(reviews)

    // eslint-disable-next-line
  }, []);

  // определяем какого типа карточка прилетела - исходя из этого передаем в Card link
  const determineLinkFollow = (cardType) => {
    if (cardType === "secondary") {
      return "flats";
    } else if (cardType === "house") {
      return "houses";
    } else if (cardType === "commercial") {
      return "commercial";
    } else if (cardType === "primary") {
      return "primary";
    }
  };

  return (
    <div>
      <Header />
      <div className="main">
        <div className="welcome-bg">
          {/* <img src={MainBg} alt="#" /> */}
          {/*<h1 className="welcome-bg__header">недвижимость вашей мечты</h1>*/}
        </div>

        <div className="offers">
          <div className="container">
            <div className="offers-wrapper">
              <h3 className="offers__title">Горячее предложение</h3>
              <h2 className="offers__header">
                Горячие предложения специально для вас
              </h2>
              <div className="slider-container" style={{ minHeight: "665px" }}>
                <SliderComp
                  dots={true}
                  slidesToShow={windowWidth > 1000 ? 3 : 2}
                  components={
                    cards
                      ? cards.map(function (item, index) {
                          return (
                            <Card
                              key={index}
                              card={item}
                              link={determineLinkFollow(item.type)}
                              cardStyles={cardStyles}
                              cardImgStyles={cardImgStyles}
                            />
                          );
                        })
                      : null
                  }
                  style={sliderOfferSlyles}
                />
              </div>
            </div>
          </div>
        </div>
        <div className="container">
          <div className="reviews">
            <h3 className="reviews__title">Что о нас говорят</h3>
            <h2 className="reviews__header">Отзывы наших клиентов</h2>
            <div className="slider-container">
              <SliderComp
                dots={true}
                slidesToShow={2}
                components={
                  reviews
                      ? reviews.map((item, index) => {
                        return (
                            <ReviewSlides
                                key={index}
                                item={item}
                            />
                        );
                      })
                      : null
                  /*generateLogosArray(ReviewSlides)*/
                }
                style={sliderReviewSlyles}
              />
            </div>
          </div>
        </div>
        <Feedback style={feedBackStyle} currentPage={config.urlForPage} />
        <div className="container">
          <div className="partners">
            <h3 className="partners__title">Наши партнеры</h3>
            <div className="slider-container">
              <SliderComp
                dots={windowWidth > 992 ? false : true}
                slidesToShow={3}
                components={
                  buildersList
                    ? buildersList.map((item, index) => {
                        let builder = item._id;
                        return (
                          <Link
                            key={index}
                            to={{
                              pathname: "/primary",
                              state: { builder },
                            }}
                          >
                            <img
                              className="partners__logo"
                              alt="#"
                              src={item.logo}
                            />
                          </Link>
                        );
                      })
                    : null
                }
                style={sliderPartnerSlyles}
              />
            </div>
          </div>
        </div>
        <div className="container">
          <div className="about-us">
            <h3 className="about-us__title">О компании</h3>
            <div className="about-us__info">
              <p className="info__text">
                Агентство недвижимости Парламент является одним из ведущих предприятий по предоставлению комплексного решения по поиску недвижимости в городе Одесса.
                Мы собрали команду из лучших специалистов своего дела, которые любят то что делают и делают это с полной отдачей.
              </p>
              <p className="info__text">
                Какова бы ни была ваша цель — найти новую квартиру, продать свой дом или управлять вашей собственностью, позвольте нам быть вашим доверенным консультантом по недвижимости.
              </p>
            </div>
          </div>
        </div>
        <Footer />
      </div>
    </div>
  );
};

export default MainPage;

import React, { useState, useEffect } from "react";
import SVG from "react-inlinesvg";
import XLSX from "xlsx";
import html2canvas from 'html2canvas';
import jsPDF from 'jspdf';

import Header from "../../Components/Main/Header/Header";
import Footer from "../../Components/Main/Footer/Footer";
import Button from "../../Components/Generic/Button/Button";
import MapContainer from "../../Components/Main/Map/MapContainer";
import Feedback from "../../Components/Generic/Feedback/Feedback";

import SliderComp from "../../Components/Main/SliderComp/SliderComp";
import DetailedCardSlider from "../../Components/Generic/DetailedCardSlider/DatailedCardSlider";
import Complex from "./Complex";

import IconAddressSVG from "../../static/images/cardIcons/address_icon.svg";
import IconAreaSVG from "../../static/images/cardIcons/area_icon.svg";
import IconRoomSVG from "../../static/images/cardIcons/rooms_icon.svg";
import IconTypeSVG from "../../static/images/cardIcons/type_icon.svg";
import IconAllFloorsSVG from "../../static/images/cardIcons/allFloors_icon.svg";
import IconFloorSVG from "../../static/images/cardIcons/floor_icon.svg";
import IconMaterialSVG from "../../static/images/cardIcons/material_icon.svg";
import IconRepairSVG from "../../static/images/cardIcons/condition_icon.svg";
import IconUrgencySVG from "../../static/images/cardIcons/urgency_icon.svg";
import IconBuilderSVG from "../../static/images/cardIcons/builder_icon.svg";
import IconClassSVG from "../../static/images/cardIcons/class_icon.svg";
import IconComplexSVG from "../../static/images/cardIcons/complex_icon.svg";
import IconCreditSVG from "../../static/images/cardIcons/credit_icon.svg";
import IconDispositionSVG from "../../static/images/cardIcons/disposition_icon.svg";
import IconYardSVG from "../../static/images/cardIcons/yard_icon.svg";

import { getDetailedCardRequest } from "../../API/cardsAction";
import config from "../../API/config";

import "./DetailedCard.scss";
import {Helmet} from "react-helmet";

const feedBackStyle = {
  marginBottom: 0,
};

const detailedCardSliderSlyles = {
  height: "480px",
};

const checkExisting = (item) => {
  return item !== undefined ? item : "Нет данных";
};

const convertAddress = (district, subDistrict, street) => {
  return `${checkExisting(district)} ${checkExisting(
    subDistrict
  )} ${checkExisting(street)}`;
};

const newRules = {
  secondary: [
    {
      type: "address",
      decorator: null,
      name: null,
      icon: IconAddressSVG,
      divider: null,
      className: "",
    },
    {
      type: "space",
      decorator: " м²",
      name: "Площадь",
      icon: IconAreaSVG,
      divider: " - ",
      className: "",
    },
    {
      type: "publicType",
      decorator: null,
      name: "Тип",
      icon: IconTypeSVG,
      divider: " - ",
      className: "",
    },

    {
      type: "rooms",
      decorator: null,
      name: "Кол-во комнат",
      icon: IconRoomSVG,
      divider: " - ",
      className: "",
    },

    {
      type: "material",
      decorator: null,
      name: "Материал",
      icon: IconMaterialSVG,
      divider: " - ",
      className: "",
      group: "filters",
    },

    {
      type: "floor",
      decorator: "",
      name: "Этаж ",
      icon: IconFloorSVG,
      divider: " - ",
      className: "",
    },

    {
      type: "condition",
      decorator: "",
      name: "Ремонт",
      icon: IconRepairSVG,
      divider: " - ",
      className: "",
      group: "filters",
    },

    {
      type: "allFloors",
      decorator: "",
      name: "Кол-во этажей",
      icon: IconAllFloorsSVG,
      divider: " - ",
      className: "",
    },

    {
      type: "publicUrgency",
      decorator: null,
      name: "Срочность",
      icon: IconUrgencySVG,
      divider: " - ",
      className: "",
    },

    {
      type: "price",
      decorator: "$",
      name: null,
      icon: null,
      divider: null,
      className: " item--price",
    },
  ],
  primary: [
    {
      type: "address",
      decorator: null,
      name: null,
      icon: IconAddressSVG,
      divider: null,
      className: "",
    },

    {
      type: "complex",
      decorator: null,
      name: "ЖК",
      icon: IconComplexSVG,
      divider: " - ",
      className: "",
    },

    {
      type: "builder",
      decorator: null,
      name: "Компания застройщик",
      icon: IconBuilderSVG,
      divider: " - ",
      className: "",
    },

    {
      type: "primaryClass",
      decorator: null,
      name: "Класс жилья",
      icon: IconClassSVG,
      divider: " - ",
      className: "",
      group: "filters",
    },

    {
      type: "material",
      decorator: null,
      name: "Материал",
      icon: IconMaterialSVG,
      divider: " - ",
      className: "",
      group: "filters",
    },

    // {
    //   type: "readiness",
    //   decorator: "",
    //   name: "Сроки сдачи",
    //   icon: IconReadinessSVG,
    //   divider: " - ",
    //   className: ""
    // },

    {
      type: "credit",
      decorator: "",
      name: "Рассрочка",
      icon: IconCreditSVG,
      divider: " - ",
      className: "",
    },

    {
      type: "publicUrgency",
      decorator: null,
      name: "Срочность",
      icon: IconUrgencySVG,
      divider: " - ",
      className: "",
    },

    {
      type: "price",
      decorator: "$",
      name: null,
      icon: null,
      divider: null,
      className: " item--price",
    },
  ],
  house: [
    {
      type: "address",
      decorator: null,
      name: null,
      icon: IconAddressSVG,
      divider: null,
      className: "",
    },
    {
      type: "space",
      decorator: " м²",
      name: "Площадь",
      icon: IconAreaSVG,
      divider: " - ",
      className: "",
    },

    {
      type: "material",
      decorator: null,
      name: "Материал",
      icon: IconMaterialSVG,
      divider: " - ",
      className: "",
      group: "filters",
    },

    {
      type: "condition",
      decorator: "",
      name: "Ремонт",
      icon: IconRepairSVG,
      divider: " - ",
      className: "",
      group: "filters",
    },
    {
      type: "yard",
      decorator: null,
      name: "Кол-во соток",
      icon: IconYardSVG,
      divider: " - ",
      className: "",
    },

    {
      type: "allFloors",
      decorator: null,
      name: "Кол-во этажей",
      icon: IconAllFloorsSVG,
      divider: " - ",
      className: "",
    },

    {
      type: "price",
      decorator: "$",
      name: null,
      icon: null,
      divider: null,
      className: " item--price",
    },
  ],
  commercial: [
    {
      type: "address",
      decorator: null,
      name: null,
      icon: IconAddressSVG,
      divider: null,
      className: "",
    },
    {
      type: "space",
      decorator: " м²",
      name: "Площадь",
      icon: IconAreaSVG,
      divider: " - ",
      className: "",
    },
    {
      type: "disposition",
      decorator: null,
      name: "Расположение",
      icon: IconDispositionSVG,
      divider: " - ",
      className: "",
      group: "filters",
    },

    {
      type: "condition",
      decorator: "",
      name: "Ремонт",
      icon: IconRepairSVG,
      divider: " - ",
      className: "",
      group: "filters",
    },

    {
      type: "price",
      decorator: "$",
      name: null,
      icon: null,
      divider: null,
      className: " item--price",
    },
  ],
};

const privateAndBusinessData = [
  { name: "houseNum", text: "Номер дома" },
  { name: "flatNum", text: "Номер квартиры" },
  { name: "firstName", text: "Владелец" },
  { name: "phone", text: "Номер телефона" },
  { name: "terms", text: "Условия сделки" },
  { name: "docs", text: "Документация" },
];

const Field = ({ item }) => {
  const { decorator, name, icon, text, divider, className } = item;
  return (
    <p className={"characterisric__item" + className}>
      {icon ? <SVG className="item__icon" src={icon} /> : null}
      {name}
      {divider}
      {text}
      {decorator}
    </p>
  );
};

const DetailedCard = (props) => {
  const [newCardInfo, setNewCardInfo] = useState([]);
  const [privateAndBusinessInfo, setPrivateAndBusinessInfo] = useState([]);
  const [cardTitle, setCardTitle] = useState();
  const [cardDescription, setCardDescription] = useState();
  const [cardCoordinates, setCardCoordinates] = useState();
  const [loadingStarted, toggleLoadingStarted] = useState(false);
  const [cardImg, setCardImg] = useState();
  const [cardVideo, setCardVideo] = useState();
  const [complexData, setComplexData] = useState();
  const [dataForExelExport, setDataForExelExport] = useState([]);
  const id = props.location.pathname.split("/")[2];
  const page = props.location.pathname;

  const converDataToObjectForExport = (char, data) => ({
    Характеристики: char,
    Данные: data,
  });

  useEffect(() => {
    toggleLoadingStarted(true);
    getDetailedCardRequest(id)
      .then((response) => {
        let cardData = response.data;

        setCardCoordinates(cardData.coordinates);
        if (cardData.videoUrl){
          setCardVideo(cardData.videoUrl);
        }

        let galleryImages = cardData.galleryImages;
        const images = galleryImages.map((item, index) => {
          return <DetailedCardSlider img={item} key={index} />;
        });
        if (cardData.complexData) setComplexData(cardData.complexData);
        setCardImg(images);

        return cardData;
      })
      .then((card) => {
        const type = card.type;
        const newCardData = newRules[type].map((item) => {
          if (item.type === "address") {
            const { district, subDistrict, street } = card;
            return {
              ...item,
              text: convertAddress(district, subDistrict, street),
            };
          } else {
            const { type, group } = item;
            return {
              ...item,
              text: `${checkExisting(group ? card[group][type] : card[type])}`,
            };
          }
        });
        setNewCardInfo(newCardData);

        // заголовок карточки
        const title = card.name;
        setCardTitle(title)

        // описание (текcт) карточки
        const description = card.description;
        setCardDescription(description);

        // приватная и бизнес инфа
        const secretInfo = card.private
          ? [{ ...card.private, ...card.business }]
          : [];
        setPrivateAndBusinessInfo(secretInfo);

        const returnArr = [secretInfo, newCardData, description];
        return returnArr;
      })
      .then((returnArr) => {
        const [secretInfo, newCardData, description] = returnArr;
        // клонируем массив с инфой о карточке (для дайнейего экспорта в exel)
        const clonedData = JSON.parse(JSON.stringify(newCardData));
        const newDataArr = clonedData.map((item, index) => {
          switch (item.type) {
            case "address":
              return converDataToObjectForExport("Адрес", item.text);
            case "space":
              return converDataToObjectForExport(
                item.name,
                item.text + item.decorator
              );
            case "price":
              return converDataToObjectForExport(
                "Цена",
                item.text + item.decorator
              );
            default:
              return converDataToObjectForExport(item.name, item.text);
          }
        });

        const newPrivateAndBusinessArr = privateAndBusinessData.map((item) => {
          const { text, name } = item;
          return secretInfo.length !== 0
            ? converDataToObjectForExport(text, secretInfo[0][name])
            : null;
        });

        const newDataForExcel = [
          ...newDataArr,
          converDataToObjectForExport("Описание", description),
          ...newPrivateAndBusinessArr,
        ];
        setDataForExelExport(newDataForExcel);
      })
      .then(() => {
        toggleLoadingStarted(false);
      });
    // eslint-disable-next-line
  }, []);

  // функция конвертации в xlsx и экспорта
  const exportExelDoc = () => {
    let wb = XLSX.utils.book_new(); // создаем новый workbook (book_new () - служебная функция, вернет пустой объект воркбука)
    let exelDoc = XLSX.utils.json_to_sheet(dataForExelExport); // создаем worksheet для конвертации в xlsx - в кач аргумента - массив объектов
    XLSX.utils.book_append_sheet(wb, exelDoc, "card"); // добавляем worksheet к workbook
    XLSX.writeFile(wb, "Информация по объекту.xlsx");
  };

  const exportPDFDoc = () => {
    let id = "printtopdf";
    let iframe = document.getElementById(id);
    if (iframe) {document.body.removeChild(iframe);}
    iframe = document.createElement('iframe');
    iframe.id = id;
    iframe.width = "1010";
    iframe.height = "0";
    let s = '<!DOCTYPE html><html xmlns="http://www.w3.org/1999/xhtml"><head><title></title><style type="text/css">body, html, * {margin:0;padding:0;}body {width: 900px; padding:1cm;margin: 0 auto;}table, table td, table th{border:1px solid #000;border-collapse: collapse;}table td,table th{padding:5px;}</style></head><body>';
    s+='<table cellpadding="0" cellspacing="0" width="100%"><tr><th width="1%">Характеристики</th><th>Данные</th></tr>';
    dataForExelExport.forEach((item)=>{
        s+="<tr><td>"+item['Характеристики'].toString()+"</td><td>"+item['Данные'].toString()+"</td></tr>";
    });
    s += '</table></body></html>';
    document.body.appendChild(iframe);
    iframe.contentWindow.document.open();
    iframe.contentWindow.document.write(s);
    iframe.contentWindow.document.close();
    html2canvas(iframe.contentWindow.document.body,{
      scale: 1
    }).then(function (canvas) {

      var imgData = canvas.toDataURL('image/png');
      var imgWidth = 210;
      var pageHeight = 295;
      var imgHeight = canvas.height * imgWidth / canvas.width;
      var heightLeft = imgHeight;

      var doc = new jsPDF('p', 'mm');
      var position = 0;
      doc.addImage(imgData, 'PNG', 0, position, imgWidth, imgHeight);

      heightLeft -= pageHeight;

      while (heightLeft >= 0) {
        position = heightLeft - imgHeight;
        doc.addPage();
        doc.addImage(imgData, 'PNG', 0, position, imgWidth, imgHeight);
        heightLeft -= pageHeight;
      }

      doc.save('Информация по объекту.pdf');
      document.body.removeChild(iframe);
    });
  };

  return (
    <div className="page-container">
      <Header />

      {cardDescription ? (
        <Helmet
          title={cardTitle}
          meta={[
            {"name": "description", "content": cardDescription}
          ]}
        />
      ) : (
        <Helmet
          title={cardTitle}
        />
      )}

      <div className="main-bg">
        {loadingStarted ? (
          <p className="slider-loader">Loading...</p>
        ) : (
          <SliderComp
            dots={true}
            slidesToShow={1}
            components={cardImg}
            style={detailedCardSliderSlyles}
          />
        )}
      </div>
      <div className="content-container">
        <div className="full-card__wrapper">
          <div className="card-info">
            {loadingStarted ? (
              <p className="loader">Loading...</p>
            ) : (
              <div>
                <div className="card__characterisric">
                  {newCardInfo.map((item, index) => {
                    return <Field item={item} key={index} />;
                  })}
                </div>
                <p className="item--description">
                  {cardDescription ? cardDescription : null}
                </p>

                {/* блок приватной и бизнес инфы */}
                {privateAndBusinessInfo.length !== 0 ? (
                  <table className="secret-info">
                    <tbody>
                      <tr className="secret__header">
                        <td>Информация для партнеров</td>
                      </tr>
                      {privateAndBusinessData.map((item, index) => {
                        const { text, name } = item;
                        const getValues = privateAndBusinessInfo.map((item) => {
                          return item[name];
                        });
                        return (
                          <tr className="secret__item" key={index}>
                            <td>{text}</td>
                            <td>{checkExisting(getValues)}</td>
                          </tr>
                        );
                      })}
                    </tbody>
                  </table>
                ) : null}
                {privateAndBusinessInfo.length !== 0 ? (
                  <Button
                    className="btn-reg"
                    text={"Скачать exel файл"}
                    onClick={() => exportExelDoc()}
                    style={{ margin: 0, maxWidth: "155px" }}
                  />
                ) : null}
                {privateAndBusinessInfo.length !== 0 ? (
                  <Button
                    className="btn-reg"
                    text={"Скачать PDF файл"}
                    onClick={() => exportPDFDoc()}
                    style={{ margin: 0, maxWidth: "155px" }}
                  />
                  ) : null}
              </div>
            )}
          </div>
          {cardCoordinates ? (
            <MapContainer
              data={[
                {
                  pathname: page,
                  name: null,
                  address: newCardInfo
                    ? // eslint-disable-next-line
                      newCardInfo.map((item) => {
                        if (item.type === "address") {
                          return item.text;
                        }
                      })
                    : null,
                  coordinates: cardCoordinates,
                },
              ]}
            />
          ) : null}
        </div>
      </div>
      {(!cardVideo || loadingStarted) ? (
        <p></p>
        ):(
      <div className="card-video">
        <iframe width="1196" height="673" src={cardVideo} frameBorder="0"
                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                allowFullScreen></iframe>
      </div>)
      }
      {complexData ? (
        <Complex complexData={complexData} loading={loadingStarted} />
      ) : null}
      <Feedback style={feedBackStyle} currentPage={config.urlForPage + page} />
      <Footer />
    </div>
  );
};

export default DetailedCard;

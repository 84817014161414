import React, { useState, useEffect } from "react";
import { useHistory } from "react-router-dom";
import Select from "react-select";

import Header from "../../AdminGeneric/Header/AdminHeader";
import Menu from "../../AdminGeneric/Menu/AdminMenu";
import RowHeader from "../../AdminGeneric/Table/RowHeader";
import Button from "../../../Components/Generic/Button/Button";
import Row from "../../AdminGeneric/Table/Row";

import { getAllComplexesRequest } from "../../../API/complexesActions";

import { customStyles } from "../SelectCustomStyles";
const styles = {
  bntStyle: {
    margin: "16px 0 16px 0",
  },
  rowStyle: {
    marginRight: "10%",
  },
};

const actionOptions = [
  { value: "change", label: "Изменить" },
  { value: "layouts", label: "Планировки" },
  { value: "promotions", label: "Акции" }
];

const Complex = (props) => {
  const [loadingStarted, toggleLoadingStart] = useState(false);
  const [requestObject, setRequestObject] = useState({
    list: {
      sort: {
        by: undefined,
        type: undefined,
      },
    },
    public: {},
  });

  const [allComplexes, setAllComplexes] = useState([]);
  const history = useHistory();

  useEffect(() => {
    toggleLoadingStart(true);
    getAllComplexesRequest(requestObject)
      .then((response) => {
        setAllComplexes(response.data);
      })
      .then(() => {
        toggleLoadingStart(false);
      });
  }, [requestObject]);

  // переход на изменение конкретного комплекса
  const selectedOption = (complex, index, event) => {
    if (event.value == 'layouts') {
      history.push({
        pathname: "/admin/complex/"+complex._id+"/layouts",
        state: {
          objectPublicType: "Жилой комплекс",
          objectId: complex._id,
          requestFunction: "getComplexById",
          submitFunction: "updateComplexRequest",
        },
      });
    }
    else if (event.value == 'promotions') {
      history.push({
        pathname: "/admin/complex/"+complex._id+"/promotions",
        state: {
          objectPublicType: "Жилой комплекс",
          objectId: complex._id,
          requestFunction: "getComplexById",
          submitFunction: "updateComplexRequest",
        },
      });
    }
    else {
      history.push({
        pathname: "/admin/edit-complex",
        state: {
          objectPublicType: "Жилой комплекс",
          objectId: complex._id,
          requestFunction: "getComplexById",
          submitFunction: "updateComplexRequest",
        },
      });
    }
  };

  // переход на добавление нового комплекса
  const addComplexLink = () => {
    history.push({
      pathname: "/admin/add-complex",
      state: {
        objectPublicType: "Жилой комплекс",
        requestFunction: "getComplexSample",
        submitFunction: "createComplexRequest",
      },
    });
  };

  const handleSelected = (filterGroup, filterName, filterNewValue) => {
    const newObject = JSON.parse(JSON.stringify(requestObject));

    newObject[filterGroup][filterName] = filterNewValue;
    setRequestObject(newObject);
  };

  const handleComplexDelete = (index) => {
    const newArr = allComplexes.filter(
      (item, complexindex) => complexindex !== index
    );
    setAllComplexes(newArr);
  };

  return (
    <div>
      <Header />
      <div className="admin-wrapper">
        <Menu />
        <div className="admin-content">
          <Button
            className="adminBtn--add"
            type="submit"
            text={"Добавить"}
            style={styles.bntStyle}
            onClick={() => addComplexLink()}
          />
          <RowHeader
            pageType={"complex"}
            state={requestObject}
            setState={setRequestObject}
            headerRowStyle={styles.rowStyle}
          />
          {loadingStarted ? (
            <p className="loader-admin">Loading...</p>
          ) : (
            allComplexes.map(function (item, index) {
              return (
                <Row
                  pageType={"complex"}
                  key={index}
                  index={index}
                  id={item._id}
                  publicId={item.publicId}
                  name={item.name}
                  date={item.readiness.date}
                  style={styles.rowStyle}
                  handleDelete={handleComplexDelete}
                >
                  <p></p> <p></p>
                  <Select
                    className="adminBtn--action"
                    placeholder={"Действия"}
                    styles={customStyles}
                    onChange={(event) => selectedOption(item, index, event)}
                    options={actionOptions}
                  />
                </Row>
              );
            })
          )}
          <Button
            className="adminBtn--delete"
            style={{
              background: "#7F8FA4",
              marginRight: 0,
              marginTop: "20px",
              width: "106px",
            }}
            text={"Показать еще"}
            onClick={() =>
              handleSelected("list", "amount", requestObject.list.amount + 10)
            }
          />
        </div>
      </div>
    </div>
  );
};

export default Complex;

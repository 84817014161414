import React, { useState } from "react";
import { useToasts } from "react-toast-notifications";
import FileInput from "./FileInput";

import Button from "../Button/Button";

import { createOrderRequest } from "../../../API/orderActions";
import validate from "../../../Validation/FormValidationRules";

import "./AppealForm.scss";

const style = {
  errorMessage: {
    color: "red",
    fontSize: "11px",
  },
  inputError: {
    borderColor: "1px solid red",
  },
};

const appealFormData = [
  {
    label: "Имя, фамилия",
    name: "name",
    type: "text",
    className: "item--name",
  },
  {
    label: "Электронная почта",
    name: "email",
    type: "email",
    className: "item--email",
  },
  {
    label: "Телефон",
    name: "phone",
    type: "text",
    placeholder: "+380",
    required: "required",
    icon: null,
    className: "item--phone",
  },
];

const ApForm = (props) => {
  const { addToast } = useToasts();
  const file = props.file;
  const onClose = props.onClose;
  const link =
    props.currentPage !== undefined ? props.currentPage : "Main page";
  const [errors, setErrors] = useState({});
  const defaultFormOptions = {
    link: link,
    name: ``,
    email: ``,
    phone: ``,
    text: ``,
    file: file
      ? {
          extension: ".pdf",
          base64: ``,
        }
      : undefined,
  };
  const [formData, setFormData] = useState(defaultFormOptions);

  const changeFormData = (name, value) => {
    setFormData({
      ...formData,
      [name]: value,
    });
  };

  // функция для рендера полей формы
  const renderFormData = () => {
    return appealFormData.map((item, index) => {
      return (
        <div key={index} className={"appeal-form__item " + item.className}>
          <div>
            <label className="item__label">{item.label}</label>
            <input
              className="item__field"
              name={item.name}
              required={item.required}
              type={item.type}
              value={formData[item.name]}
              placeholder={item.placeholder}
              onChange={(event) => {
                changeFormData(item.name, event.currentTarget.value);
              }}
            />
          </div>
          {errors[item.name] && (
            <p style={style.errorMessage}>{errors[item.name]}</p>
          )}
        </div>
      );
    });
  };

  // функция для рендера инпуста тип файл
  const renderFileData = () => {
    return (
      <div>
        <label className="item__label">Прикрепить резюме</label>
        <FileInput
          name={"base64"}
          type={file}
          text={"Click here to upload pdf"}
          group={formData[file]}
          id={"base64"}
          multiple={true}
          returnas={""}
          value={""}
          changeValue={changeFormData}
        />
      </div>
    );
  };

  // функция отправки формы
  const handleFormSubmit = (data, event) => {
    event.preventDefault();
    setErrors(validate(data));
    createOrderRequest(data)
      .then((response) => {
        addToast("Форма успешно отправлена", {
          appearance: "success",
          autoDismiss: true,
        });
        setTimeout(() => {
          // eslint-disable-next-line
          {
            file === true ? setFormData(defaultFormOptions) : onClose();
          }
        }, 1000);
      })
      .catch((error) => {
        addToast(
          "Отправить форму не удалось. Необходимо прикрепить резюме в формате pdf.",
          {
            appearance: "error",
            autoDismiss: true,
          }
        );
      });
  };

  return (
    <form
      noValidate
      className="appeal-form"
      onSubmit={(event) => handleFormSubmit(formData, event)}
    >
      {renderFormData()}
      {props.uploadFile ? renderFileData() : null}
      <div className="appeal-form__item item--text">
        <label className="item__label" htmlFor="text">
          {props.textarea}
        </label>
        <input
          className="item__field"
          name="text"
          as="textarea"
          value={formData.text}
          onChange={(event) => {
            changeFormData("text", event.target.value);
          }}
          style={props.styles}
        />
      </div>
      <div className="form__item item--btn">
        <Button className="btn-small" type="submit" text={"Отправить"} />
      </div>
    </form>
  );
};

export default ApForm;

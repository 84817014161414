import React, {useState, useEffect} from "react";
import {useHistory} from "react-router-dom";
import {Link} from "react-router-dom";
import Select from "react-select";
import XLSX from "xlsx";

import Header from "../../AdminGeneric/Header/AdminHeader";
import Menu from "../../AdminGeneric/Menu/AdminMenu";
import Button from "../../../Components/Generic/Button/Button";
import RowHeader from "../../AdminGeneric/Table/RowHeader";
import Row from "../../AdminGeneric/Table/Row";

import {getAllCardsRequest, getAllCardsCountRequest} from "../../../API/cardsAction";

import {customStyles} from "../SelectCustomStyles";
import "./Objects.scss";
import useGlobal from "../../../store";

const styles = {
    bntStyle: {
        margin: "16px 0 16px 0",
    },
    bnt2Style: {
        height: "36px",
        marginRight: "8px"
    },
    rowStyle: {
        //marginRight: "3.5%",
    },
};

const actionOptions = [{value: "change", label: "Изменить"}]

const filter = {
    is_admin: true,
    street: '',
    public: {},
    ranges: {}
}

const mapExcel = [
    {name: 'publicId', label: 'Артикул'},
    {name: 'name', label: 'Название'},
    {name: 'publicType', label: 'Тип'},
    {name: 'district', label: "Район"},
    {name: 'subDistrict', label: "Микрорайон"},
    {name: 'street', label: "Улица"},
    {name: 'price', label: "Цена"},
    {name: 'credit', label: "Рассрочка"},
    {name: 'publicUrgency', label: "Срочность"},
    {name: 'floor', label: 'Этаж'},
    {name: 'allFloors', label: 'Общая этажность'},
    {name: 'rooms', label: 'Кол-во комнат'},
    {name: 'space', label: 'Метраж'},
    {name: 'square', label: 'Квадрат'},
    {name: 'primaryClass', names: ['secondaryClass', 'primaryClass'], label: 'Класс жилья', group: "filters"},
    {name: 'condition', label: 'Ремонт', group: "filters"},
    {name: 'material', label: 'Материал', group: "filters"},
    {name: 'disposition', label: 'Расположение', group: "filters"},
    {name: 'description', label: 'Описание'},
    {name: 'realtor', label: 'Риелтор'},
    {name: 'terms', label: 'Условия сделки', group: "business"},
    {name: 'docs', label: 'Документы', group: "business"},
    {name: 'firstName', label: 'Имя владельца', group: "private"},
    {name: 'phone', label: 'Телефон', group: "private"},
    {name: 'flatNum', label: 'Номер квартиры', group: "private"},
    {name: 'houseNum', label: 'Номер дома / офиса', group: "private"},
];

const AdminMainPage = (props) => {
    const [globalState, globalActions] = useGlobal();
    const [loadingStarted, toggleLoadingStart] = useState(false);
    const [isPageChange, setIsPageChange] = useState(false);
    const [requestObject, setRequestObject] = useState({
        is_admin: true,
        list: {
            amount: 10,
            page: 1,
            sort: {
                by: undefined,
                type: undefined,
            }
        },
        public: {
            publicId: undefined
        },
    });
    const [cardsForRender, setCardsForRender] = useState([]);
    const [pagination, setPagination] = useState({
        count: 0,
        pages: 1,
    });
    const history = useHistory();

    const convertObjectForExcel = (item) => {
        var obj = [];
        mapExcel.forEach(function (itemMap) {
            const index = itemMap.label;
            obj[index] = '';
            if (itemMap.names && typeof (itemMap.names) == 'object') {
                for (let i in itemMap.names) {
                    if (itemMap.group) {
                        if (item[itemMap.group] && item[itemMap.group][itemMap.names[i]]) {
                            obj[index] = item[itemMap.group][itemMap.names[i]];
                        }
                    } else {
                        if (item[itemMap.names[i]]) {
                            obj[index] = item[itemMap.names[i]];
                        }
                    }
                }
            } else if (itemMap.group) {
                if (item[itemMap.group] && item[itemMap.group][itemMap.name]) {
                    obj[index] = item[itemMap.group][itemMap.name];
                }
            } else {
                if (item[itemMap.name]) {
                    obj[index] = item[itemMap.name];
                }
            }
        });
        return obj;
    }

    const exportExelDocObjects = () => {
        const dataForExelExport = [];
        const headerForExelExport = [];
        cardsForRender.forEach(function (item, index) {
            dataForExelExport.push(convertObjectForExcel(item));
            headerForExelExport.push(item.label);
        });
        let wb = XLSX.utils.book_new(); // создаем новый workbook (book_new () - служебная функция, вернет пустой объект воркбука)
        let exelDoc = XLSX.utils.json_to_sheet(dataForExelExport); // создаем worksheet для конвертации в xlsx - в кач аргумента - массив объектов
        XLSX.utils.book_append_sheet(wb, exelDoc, "cards"); // добавляем worksheet к workbook
        XLSX.writeFile(wb, "Объекты.xlsx");
    };

    const handleCardDelete = (index) => {
        const newArr = cardsForRender.filter(
            (item, cardindex) => cardindex !== index
        );
        setCardsForRender(newArr);
    };

    const handleChange = (event) => {
        setIsPageChange(false);

        if (document.getElementById("street").value !== "") {
            filter.street = document.getElementById("street").value;
        }

        filter.public = {};
        filter.ranges = {
            rooms: {},
            floor: {},
            allFloors: {},
            price: {}
        };
        if (!isNaN(parseInt(document.getElementById("publicId").value))) {
            filter.public.publicId = parseInt(document.getElementById("publicId").value);
        }
        if (!isNaN(parseInt(document.getElementById("roomsMin").value))) {
            filter.ranges.rooms.min = parseInt(document.getElementById("roomsMin").value);
        }
        if (!isNaN(parseInt(document.getElementById("roomsMax").value))) {
            filter.ranges.rooms.max = parseInt(document.getElementById("roomsMax").value);
        }
        if (!isNaN(parseInt(document.getElementById("floorMin").value))) {
            filter.ranges.floor.min = parseInt(document.getElementById("floorMin").value);
        }
        if (!isNaN(parseInt(document.getElementById("floorMax").value))) {
            filter.ranges.floor.max = parseInt(document.getElementById("floorMax").value);
        }
        if (!isNaN(parseInt(document.getElementById("allFloorsMin").value))) {
            filter.ranges.allFloors.min = parseInt(document.getElementById("allFloorsMin").value);
        }
        if (!isNaN(parseInt(document.getElementById("allFloorsMax").value))) {
            filter.ranges.allFloors.max = parseInt(document.getElementById("allFloorsMax").value);
        }
        const square = document.getElementById("square");
        if (square.selectedIndex > 0) {
            filter.public.square = parseInt(square.options[square.selectedIndex].value);
        }
        if (!isNaN(parseInt(document.getElementById("priceMin").value))) {
            filter.ranges.price.min = parseInt(document.getElementById("priceMin").value);
        }
        if (!isNaN(parseInt(document.getElementById("priceMax").value))) {
            filter.ranges.price.max = parseInt(document.getElementById("priceMax").value);
        }
        if (document.getElementById("phone").value !== "") {
            filter.public['private.phone'] = document.getElementById("phone").value;
        }
        if (document.getElementById("realtor").value !== "") {
            filter.public['realtor'] = document.getElementById("realtor").value;
        }
        if (document.getElementById("notfirst").checked) {
            if (!filter.ranges.floor.min || filter.ranges.floor.min < 2) {
                filter.ranges.floor.min = 2;
            }
        }
        if (document.getElementById("notlast").checked) {
            filter.public.notlast = true;
        }

        if (document.getElementById("houseNum").value !== "") {
            filter.public['private.houseNum'] = document.getElementById("houseNum").value;
        }
    }

    const handleClearFilter = (event) => {
        setIsPageChange(false);

        document.getElementById("object-form").reset();
        filter.public = {};
        filter.ranges = {};
        requestObject.public = {};
        requestObject.street = '';
        requestObject.ranges = {};
        requestObject.list.amount = 10;
        const newObject = JSON.parse(JSON.stringify(requestObject));
        setRequestObject(newObject);
    }

    const handleFilter = (event) => {
        setIsPageChange(false);

        event.preventDefault();
        requestObject.public = filter.public;
        requestObject.street = filter.street;
        requestObject.ranges = filter.ranges;
        requestObject.list.amount = 10;
        const newObject = JSON.parse(JSON.stringify(requestObject));
        setRequestObject(newObject);
    }

    const handleSelected = (filterGroup, filterName, filterNewValue) => {
        const newObject = JSON.parse(JSON.stringify(requestObject));

        newObject[filterGroup][filterName] = filterNewValue;
        setRequestObject(newObject);
    };

    const handleChangePage = (page) => {
        setIsPageChange(true);

        const newObject = JSON.parse(JSON.stringify(requestObject));
        newObject["list"]["page"] = page;
        setRequestObject(newObject);
    }

    useEffect(() => {
        /*getAllCardsCountRequest(requestObject).then((response) => {
          pagination.count = response.data;
          pagination.pages = Math.ceil(response.data/10);
          setPagination(pagination);
        })*/

        if (globalState.user.role !== 'admin') {
            actionOptions[0].label = 'Просмотр'
        }
    }, []);

    useEffect(() => {
        toggleLoadingStart(true);

        if (!isPageChange) {
            requestObject.list.page = 1;
            getAllCardsCountRequest(requestObject).then((response) => {
                pagination.count = response.data;
                pagination.pages = Math.ceil(response.data / 10);
                setPagination(pagination);

                return getAllCardsRequest(requestObject);
            }).then((response) => {
                setCardsForRender(response.data);
            }).then(() => {
                toggleLoadingStart(false);
            });
        } else {
            getAllCardsRequest(requestObject).then((response) => {
                setCardsForRender(response.data);
            }).then(() => {
                toggleLoadingStart(false);
            });
        }
    }, [requestObject]);

    const selectedOption = (item, index, event) => {
        history.push({
            pathname: "/admin/edit-object-type",
            state: {
                objectId: item._id,
                objectPublicType: item.publicType,
                requestFunction: "getCardById",
                submitFunction: "updateCardRequest",
            },
        });
    };

    return (
        <div className="admin-container">
            <Header/>
            <div className="admin-wrapper">
                <Menu/>
                <div className="admin-content">
                    <div className="admin-filter-form-buttons">
                        <Link to="/admin/add-object">
                            <Button
                                className="adminBtn--add"
                                type="submit"
                                text={"Добавить"}
                                style={styles.bntStyle}
                            />
                        </Link>
                        <Link to="/admin/import-objects">
                            <Button
                                className="adminBtn--add"
                                type="submit"
                                text={"Импорт"}
                                style={{
                                    background: "#7F8FA4",
                                    borderColor: "#7F8FA4"
                                }}
                            />
                        </Link>

                    </div>
                    <form onSubmit={handleFilter} id="object-form">
                        <div className="admin-filter-form">
                            <div className="admin-filter-form-item">
                                <label>Артикул</label>
                                <input
                                    id="publicId"
                                    type="number"
                                    placeholder="Артикул"
                                    onChange={handleChange}
                                />
                            </div>
                            <div className="admin-filter-form-item">
                                <label>Улица</label>
                                <input
                                    id="street"
                                    type="text"
                                    placeholder="Улица"
                                    onChange={handleChange}
                                />
                            </div>
                            <div className="admin-filter-form-item">
                                <label>№ дома</label>
                                <input
                                    id="houseNum"
                                    type="text"
                                    placeholder="№ дома"
                                    onChange={handleChange}
                                />
                            </div>
                            <div className="admin-filter-form-item">
                                <label>Квадрат</label>
                                <select
                                    id="square"
                                    onChange={handleChange}>
                                    <option value="0"></option>
                                    <option value="1">1</option>
                                    <option value="2">2</option>
                                    <option value="3">3</option>
                                    <option value="4">4</option>
                                    <option value="5">5</option>
                                    <option value="6">6</option>
                                </select>
                            </div>
                            <div className="admin-filter-form-item">
                                <label>Комнат</label>
                                <input
                                    id="roomsMin"
                                    className="admin-filter-2"
                                    type="number"
                                    placeholder="от"
                                    min="0"
                                    max="999"
                                    onChange={handleChange}
                                />
                                <input
                                    id="roomsMax"
                                    className="admin-filter-2"
                                    type="number"
                                    placeholder="до"
                                    min="0"
                                    max="999"
                                    onChange={handleChange}
                                />
                            </div>
                            <div className="admin-filter-form-item">
                                <label>Этаж</label>
                                <input
                                    id="floorMin"
                                    className="admin-filter-2"
                                    type="number"
                                    placeholder="от"
                                    min="0"
                                    max="999"
                                    onChange={handleChange}
                                />
                                <input
                                    id="floorMax"
                                    className="admin-filter-2"
                                    type="number"
                                    placeholder="до"
                                    min="0"
                                    max="999"
                                    onChange={handleChange}
                                />
                            </div>
                            <div className="admin-filter-form-item">
                                <label>Этажность</label>
                                <input
                                    id="allFloorsMin"
                                    className="admin-filter-2"
                                    type="number"
                                    placeholder="от"
                                    min="0"
                                    max="999"
                                    onChange={handleChange}
                                />
                                <input
                                    id="allFloorsMax"
                                    className="admin-filter-2"
                                    type="number"
                                    placeholder="до"
                                    min="0"
                                    max="999"
                                    onChange={handleChange}
                                />
                            </div>
                            <div className="admin-filter-form-item">
                                <label>Цена</label>
                                <input
                                    id="priceMin"
                                    className="admin-filter-2"
                                    type="number"
                                    placeholder="от"
                                    min="0"
                                    onChange={handleChange}
                                />
                                <input
                                    id="priceMax"
                                    className="admin-filter-2"
                                    type="number"
                                    placeholder="до"
                                    min="0"
                                    onChange={handleChange}
                                />
                            </div>
                            <div className="admin-filter-form-item">
                                <label>Телефон</label>
                                <input
                                    id="phone"
                                    type="text"
                                    placeholder="телефон владельца"
                                    onChange={handleChange}
                                />
                            </div>
                            <div className="admin-filter-form-item">
                                <label>Риелтор</label>
                                <input
                                    id="realtor"
                                    type="text"
                                    placeholder="Риелтор"
                                    onChange={handleChange}
                                />
                            </div>
                        </div>
                        <div className="admin-filter-form">
                            <div className="admin-filter-form-item">
                                <span>Не первый этаж</span>
                                <input
                                    id="notfirst"
                                    className="switch__btn"
                                    type="checkbox"
                                    onChange={handleChange}
                                />
                            </div>
                            <div className="admin-filter-form-item">
                                <span>Не последний этаж</span>
                                <input
                                    id="notlast"
                                    className="switch__btn"
                                    type="checkbox"
                                    onChange={handleChange}
                                />
                            </div>
                        </div>
                        <div className="admin-filter-form-buttons">
                            <Button
                                className="adminBtn--add"
                                type="submit"
                                text={"Применить"}
                                style={styles.bntStyle}
                            />
                            <Button
                                className="adminBtn--add"
                                style={{
                                    background: "#7F8FA4",
                                    borderColor: "#7F8FA4"
                                }}
                                text={"Очистить"}
                                onClick={handleClearFilter}
                            />
                            <Button
                                className="adminBtn--add"
                                style={{
                                    background: "#7F8FA4",
                                    width: "120px",
                                    borderColor: "#7F8FA4"
                                }}
                                text={"Экспорт Excel"}
                                onClick={exportExelDocObjects}
                            />
                        </div>
                    </form>

                    <div className="gl-table">
                        <RowHeader
                            pageType={"object"}
                            state={requestObject}
                            setState={setRequestObject}
                            headerRowStyle={styles.rowStyle}
                        />
                        {loadingStarted ? (
                            <p className="loader-admin">Loading...</p>
                        ) : (
                            cardsForRender.map(function (item, index) {
                                return (
                                    <Row
                                        pageType={"object"}
                                        key={item.publicId}
                                        index={index}
                                        id={item._id}
                                        publicId={item.publicId}
                                        name={item.name}
                                        date={item.createdDate}
                                        style={styles.rowStyle}
                                        handleDelete={handleCardDelete}
                                    >
                                        <p className="filter__item" style={styles.rowStyle}>
                                            {item.publicType}
                                        </p>

                                        <Button
                                            className="adminBtn--condition"
                                            style={
                                                item.urgency === false
                                                    ? {
                                                        background: "#36AF47",
                                                        display: "flex",
                                                        justifyContent: "center",
                                                    }
                                                    : {
                                                        background: "#ED1C24",
                                                        display: "flex",
                                                        justifyContent: "center",
                                                    }
                                            }
                                            text={item.urgency === false ? "Не срочно" : "Срочно"}
                                        />

                                        <p
                                            className="filter__item"
                                            style={(styles.rowStyle, {maxWidth: "70px"})}
                                        >
                                            {`$${item.price}`}
                                        </p>

                                        {/*<Select
                      id={item._id}
                      className="adminBtn--action"
                      placeholder={"Действия"}
                      styles={customStyles}
                      onChange={(event) => selectedOption(item, index, event)}
                      options={actionOptions}
                    />*/}

                                        <Link
                                            target="_blank"
                                            to={{
                                                pathname: "/admin/edit-object-type",
                                                search: new URLSearchParams({
                                                    objectId: item._id,
                                                    objectPublicType: item.publicType,
                                                    requestFunction: "getCardById",
                                                    submitFunction: "updateCardRequest",
                                                }).toString(),
                                            }}
                                        >
                                            <Button
                                                className="adminBtn--add"
                                                type="submit"
                                                text={"Изменить"}
                                                style={styles.bnt2Style}
                                            />
                                        </Link>

                                        {/*<Link to="/admin/edit-object-type" target="_blank">
                      <Button
                          className="adminBtn--add"
                          type="submit"
                          text={"Изменить"}
                          style={styles.bnt2Style}
                      />
                    </Link>*/}

                                        <p className="filter__item" style={styles.rowStyle}>
                                            {item.square}
                                        </p>
                                        <p className="filter__item" style={styles.rowStyle}>
                                            {item.searchStreet}
                                        </p>
                                        <p className="filter__item" style={styles.rowStyle}>
                                            {item.rooms}
                                        </p>
                                        <p className="filter__item" style={styles.rowStyle}>
                                            {item.floor}
                                        </p>
                                        <p className="filter__item" style={styles.rowStyle}>
                                            {item.allFloors}
                                        </p>
                                        <p className="filter__item" style={styles.rowStyle}>
                                            {item.space}{item.spaceLiving ? "/" + item.spaceLiving : ""}{item.spaceKitchen ? "/" + item.spaceKitchen : ""}
                                        </p>
                                        <p className="filter__item" style={styles.rowStyle}>
                                            {item.filters.material}
                                        </p>
                                        <p className="filter__item" style={styles.rowStyle}>
                                            {item.business.docs}
                                        </p>
                                        <p className="filter__item" style={styles.rowStyle}>
                                            {item.filters.secondaryClass}
                                        </p>
                                        <p className="filter__item" style={styles.rowStyle}>
                                            {item.filters.condition}
                                        </p>
                                        <p className="filter__item" style={styles.rowStyle}>
                                            {item.business.terms}
                                        </p>
                                        <p className="filter__item" style={
                                            item.active
                                                ? {
                                                    background: "#36AF47",
                                                    display: "flex",
                                                    justifyContent: "center",
                                                    color: "#fff"
                                                }
                                                : {
                                                    background: "#ED1C24",
                                                    display: "flex",
                                                    justifyContent: "center",
                                                    color: "#fff"
                                                }
                                        }>
                                            {item.active ? "On" : "Off"}
                                        </p>
                                        <p className="filter__item" style={styles.rowStyle}>
                                            {item.private.phone}
                                        </p>
                                    </Row>
                                );
                            })
                        )}
                    </div>

                    {pagination.pages && pagination.pages > 1 ? (
                        <div className="pagination">
                            {[...Array(pagination.pages)].map((x, i) =>
                                <div className={`pagination__item ${requestObject.list.page === (i + 1) ? "active" : ""}`} onClick={() => handleChangePage(i + 1)}>{i + 1}</div>
                            )}
                        </div>
                    ) : ('')}

                    {/*<Button
            className="adminBtn--delete"
            style={{
              background: "#7F8FA4",
              marginRight: 0,
              marginTop: "20px",
              width: "106px",
            }}
            text={"Показать еще"}
            onClick={() =>
              handleSelected("list", "amount", requestObject.list.amount + 10)
            }
          />*/}
                </div>
            </div>
        </div>
    );
};

export default AdminMainPage;

import React, { useState, useEffect } from "react";
import Dropdown from "./Dropdown";

const CustomSelect = ({
  group,
  name,
  parent,
  placeholder,
  value,
  changeValue,
  request,
}) => {
  const [options, setOptions] = useState([]);
  const [emptyfy, toggleEmptyfy] = useState(false);

  useEffect(() => {
    if (parent !== "") {
      request(parent || name)
        .then((response) => {
          const result = response.data.map((item) => {
            return {
              label: item.name ? item.name : item.publicName,
              value: item._id,
            };
          });
          return result;
        })
        .then((options) => {
          setOptions(options);
        });
    } else if (parent === "") {
      setOptions([]);
      toggleEmptyfy(!emptyfy);
    }
    // eslint-disable-next-line
  }, [parent]);

  const onChange = (selectedValue) => {
    return typeof value === "object"
      ? changeValue(group, {
          ...value,
          [name]: selectedValue,
        })
      : changeValue(name, selectedValue);
  };

  // ф-ция принимает три аргумента, массив объектов, value (объект с именем и id) для селекта и name полученные из пропсов
  const setPredefinedValue = (array, value, name) => {
    // проверяем, если value - объект, то берем id из объекта, либо сразу id
    const id = typeof value === "object" ? value[name] : value;
    // фильтруем массив - если id в объекте равен id из пропсов, то записываем в result массив с объекТОМ (в кот лежит label и value с id)
    // короче говоря мы по id выбираем нужный
    const result = array.filter((item) => item.value === id);
    return value ? result[0] : false; // если мы ничего не нашли или value пустая строка - возвращаем false
  };
  return parent === undefined ? (
    //ЕСЛИ РОДИТЕЛЯ НЕТ ВООБЩЕ - ПРОСТО ДРОПДАУН
    <Dropdown
      array={options}
      placeholder={placeholder}
      onChange={onChange}
      predefinedValue={setPredefinedValue(options, value, name)}
      disabled={false}
      emptyfy={emptyfy}
    />
  ) : //ЕСЛИ РОДИТЕЛЬ ЕСТЬ
  parent === "" ? ( //НО НЕ ОПРЕДЕЛЕН - (ПУСТАЯ СТРОКА)
    <Dropdown
      array={options}
      placeholder={placeholder}
      onChange={onChange}
      predefinedValue={setPredefinedValue(options, value, name)}
      disabled={true}
      emptyfy={emptyfy}
    />
  ) : (
    //ЕСЛИ РОДИТЕЛЬ ОПРЕДЕЛН
    <Dropdown
      array={options}
      placeholder={placeholder}
      onChange={onChange}
      predefinedValue={setPredefinedValue(options, value, name)}
      disabled={false}
      emptyfy={emptyfy}
    />
  );
};

export default CustomSelect;

import CustomSelect from "../CustomInputs/Select";
import CustomInput from "../CustomInputs/TextInput";
import CustomTextArea from "../CustomInputs/Textarea";
import CustomFileInput from "../CustomInputs/FileInput";
import CustomDate from "../CustomInputs/DateInput";

import {
  getDistrictsList,
  getSubDistrictsList,
} from "../../../API/filtersActions";

import {
  getBuildersList,
  getComplexPropertyByName,
} from "../../../API/complexesActions";
import CustomCheckbox from "../CustomInputs/Checkbox";

export const ComplexData = {
  nameGroup: [
    {
      label: "Название",
      placeholder: "Название",
      name: "name",
      type: "text",
      Component: CustomInput,
    },
  ],

  complexGroup: [
    {
      label: "Застройщик",
      placeholder: "Застройщик",
      name: "builder",
      Component: CustomSelect,
      link: "builders/",
      request: getBuildersList,
    },
  ],

  addressGroup: [
    {
      label: "Район",
      placeholder: "Район",
      name: "district",
      Component: CustomSelect,
      request: getDistrictsList,
    },

    {
      label: "Микрорайон",
      placeholder: "Микрорайон",
      name: "subDistrict",
      Component: CustomSelect,
      parent: "district",
      request: getSubDistrictsList,
    },

    {
      label: "Улица",
      placeholder: "Улица",
      name: "street",
      type: "text",
      Component: CustomInput,
    },
  ],

  priceGroup: [
    {
      label: "Цена",
      placeholder: "Цена",
      name: "price",
      type: "number",
      Component: CustomInput,
    },
    {
      name: "isNewComplex",
      placeholder: "Новый",
      type: "checkbox",
      Component: CustomCheckbox,
    },
    {
      name: "isComplete",
      placeholder: "Сданный",
      type: "checkbox",
      Component: CustomCheckbox,
    },
    {
      name: "isPromotion",
      placeholder: "Акционная продажа",
      type: "checkbox",
      Component: CustomCheckbox,
    },
    {
      name: "isRenovated",
      placeholder: "Квартиры с ремонтом",
      type: "checkbox",
      Component: CustomCheckbox,
    },
    {
      placeholder: "Рассрочка",
      name: "isCredit",
      type: "checkbox",
      Component: CustomCheckbox,
    },
    {
      placeholder: "Элитный",
      name: "isElite",
      type: "checkbox",
      Component: CustomCheckbox,
    },

    {
      placeholder: "Недорогой",
      name: "isInexpensive",
      type: "checkbox",
      Component: CustomCheckbox,
    },


  ],

  techDataGroup: [
    {
      label: "Технические детали",
      placeholder: "Технические детали",
      type: "text",
      name: "techDescription",
      Component: CustomInput,
    },
    {
      label: "Кол-во секций",
      placeholder: "Кол-во секций",
      type: "number",
      name: "sections",
      Component: CustomInput,
    },

    {
      label: "Территория (закрытая, паркинг)",
      placeholder: "Территория (закрытая, паркинг)",
      type: "text",
      name: "territory",
      Component: CustomInput,
    },

    {
      label: "Общая этажность",
      placeholder: "Общая этажность",
      type: "number",
      name: "allFloors",
      Component: CustomInput,
    },

    {
      label: "Высота потолков",
      placeholder: "Высота потолков",
      type: "number",
      name: "ceilingHeight",
      Component: CustomInput,
    },

    {
      label: "Наполнение (двери, радиаторы ..)",
      placeholder: "Наполнение (двери, радиаторы ..)",
      type: "text",
      name: "filling",
      Component: CustomInput,
    },
  ],

  plansImagesGroup: [
    {
      label: "Поэтажные планировки",
      name: "plans",
      type: "file",
      multiple: true,
      returnas: [],
      header: "Files:",
      text: "Click here to upload images. Total no more than 10MB",
      Component: CustomFileInput,
    },
  ],

  propertiesGroup: [
    {
      label: "Технология строительства",
      placeholder: "Технология",
      name: "technology",
      group: "properties",
      Component: CustomSelect,
      request: getComplexPropertyByName,
    },
    {
      label: "Материал стен",
      placeholder: "Материал стен",
      name: "walls",
      group: "properties",
      Component: CustomSelect,
      request: getComplexPropertyByName,
    },
    {
      label: "Отопление",
      placeholder: "Отопление",
      name: "heating",
      group: "properties",
      Component: CustomSelect,
      request: getComplexPropertyByName,
    },

    {
      label: "Утепление",
      placeholder: "Утепление",
      name: "warming",
      group: "properties",
      Component: CustomSelect,
      request: getComplexPropertyByName,
    },
    {
      label: "Стяжка",
      placeholder: "Стяжка",
      name: "screed",
      group: "properties",
      Component: CustomSelect,
      request: getComplexPropertyByName,
    },

    {
      label: "Межвартирные перегородки",
      placeholder: "Перегородки",
      name: "partition",
      group: "properties",
      Component: CustomSelect,
      request: getComplexPropertyByName,
    },
  ],

  descriptionGroup: [
    {
      label: "Описание",
      placeholder: "Текст",
      name: "description",
      type: "text",
      Component: CustomTextArea,
    },
  ],

  readinessDataGroup: [
    {
      label: "Сроки сдачи",
      name: "date",
      type: "date",
      group: "readiness",
      Component: CustomDate,
    },
  ],

  readinessDescriptionGroup: [
    {
      label: "Состояние строительства (описание)",
      placeholder: "Текст",
      name: "text",
      group: "readiness",
      type: "text",
      Component: CustomTextArea,
    },
  ],

  readinessImagesGroup: [
    {
      label: "Этапы строительства",
      name: "images",
      type: "file",
      multiple: true,
      group: "readiness",
      returnas: [],
      header: "Files:",
      text: "Click here to upload images. Total no more than 10MB",
      Component: CustomFileInput,
    },
  ],
};

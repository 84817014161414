export default class Execute {
    constructor(options) {
        this.options = options;
        this.cancel = false;
        this.rows = null;
    }

    start() {
        let next = () => this.process(next);

        if (this.options.start) {
            this.options.start.apply(this, [next]);
        }
    }

    process(next) {
        if (this.cancel || this.options.data.length === 0) {
            if (this.options.finish) {
                this.options.finish.apply(this);
            }
            return;
        }

        this.rows = this.options.data.splice(0, 100);

        if (this.options.process){
            this.options.process.apply(this, [next]);
        } else {
            next();
        }
    }
}
import React, { useState, useEffect } from "react";
import SVG from "react-inlinesvg";
import XLSX from "xlsx";
import html2canvas from 'html2canvas';
import jsPDF from 'jspdf';

import Header from "../../Components/Main/Header/Header";
import Footer from "../../Components/Main/Footer/Footer";
import Button from "../../Components/Generic/Button/Button";
import Feedback from "../../Components/Generic/Feedback/Feedback";

import SliderComp from "../../Components/Main/SliderComp/SliderComp";
import DetailedCardSlider from "../../Components/Generic/DetailedCardSlider/DatailedCardSlider";

import IconAddressSVG from "../../static/images/cardIcons/address_icon.svg";
import IconAreaSVG from "../../static/images/cardIcons/area_icon.svg";
import IconRoomSVG from "../../static/images/cardIcons/rooms_icon.svg";
import IconTypeSVG from "../../static/images/cardIcons/type_icon.svg";
import IconAllFloorsSVG from "../../static/images/cardIcons/allFloors_icon.svg";
import IconFloorSVG from "../../static/images/cardIcons/floor_icon.svg";
import IconMaterialSVG from "../../static/images/cardIcons/material_icon.svg";
import IconRepairSVG from "../../static/images/cardIcons/condition_icon.svg";
import IconUrgencySVG from "../../static/images/cardIcons/urgency_icon.svg";
import IconBuilderSVG from "../../static/images/cardIcons/builder_icon.svg";
import IconClassSVG from "../../static/images/cardIcons/class_icon.svg";
import IconComplexSVG from "../../static/images/cardIcons/complex_icon.svg";
import IconCreditSVG from "../../static/images/cardIcons/credit_icon.svg";
import IconDispositionSVG from "../../static/images/cardIcons/disposition_icon.svg";
import IconYardSVG from "../../static/images/cardIcons/yard_icon.svg";
import PromotionSVG from "../../static/images/promotion.svg";

import { getComplexById } from "../../API/complexesActions";
import config from "../../API/config";

import "./DetailedComplexCard.scss";
import ComplexCard from "./ComplexCard";

const feedBackStyle = {
  marginBottom: 0,
};

const detailedCardSliderSlyles = {
  height: "480px",
};

const checkExisting = (item) => {
  return item !== undefined ? item : "Нет данных";
};

const changeDateFormat = (date) => {
  const newDate = new Date(date);
  let day = newDate.getDate();
  if (day < 10) day = "0" + day;
  let month = newDate.getMonth() + 1;
  if (month < 10) month = "0" + month;
  let year = newDate.getFullYear();
  return year + "-" + month + "-" + day;
};

const formatPerM2 = (space, price) => {
  if (space < 0.01 || price < 0.01) return "0";

  return Math.round(parseFloat(price) * 100.0 / parseFloat(space) ) / 100.0;
}
const formatPromotionDate = (d, t) => {
   d = new Date(d);
   const now = new Date();

  let delta = Math.abs(d - now) / 1000;

// calculate (and subtract) whole days
  let days = Math.floor(delta / 86400);
  delta -= days * 86400;

// calculate (and subtract) whole hours
  let hours = Math.floor(delta / 3600) % 24;
  delta -= hours * 3600;

// calculate (and subtract) whole minutes
  let minutes = Math.floor(delta / 60) % 60;
  delta -= minutes * 60;

// what's left is seconds
  let seconds = parseInt(delta % 60);

  if (t == 'days') {
      return ((days < 10)?"0":"") +  days;
   }
  if (t == 'hours') {
    return ((hours < 10)?"0":"") +  hours;
  }
  if (t == 'minutes') {
    return ((minutes < 10)?"0":"") +  minutes;
  }
  return ((seconds < 10)?"0":"") +  seconds;
}

const convertAddress = (districtName, subDistrictName, street) => {
  return `${checkExisting(districtName)} ${checkExisting(
    subDistrictName
  )} ${checkExisting(street)}`;
};

const newRules = [
    {
      type: "name",
      decorator: null,
      name: "Характеристики ",
      icon: null,
      divider: ": ",
      className: "",
    },
    {
      type: "date",
      group: "readiness",
      decorator: null,
      name: "Срок сдачи ",
      icon: null,
      divider: " - ",
      className: "",
    },
    {
      type: "address",
      decorator: null,
      name: null,
      icon: IconAddressSVG,
      divider: null,
      className: "",
    },

    {
      type: "publicName",
      group: "builderName",
      decorator: null,
      name: "Компания застройщик",
      icon: IconBuilderSVG,
      divider: " - ",
      className: "",
    },
    {
      type: "allFloors",
      decorator: null,
      name: "Общая этажность",
      icon: IconAllFloorsSVG,
      divider: " - ",
      className: "",
    },
    {
      type: "sections",
      decorator: null,
      name: "Кол-во секций",
      icon: IconAreaSVG,
      divider: " - ",
      className: "",
    },
    {
      type: "ceilingHeight",
      decorator: null,
      name: "Высота потолков",
      icon: IconAreaSVG,
      divider: " - ",
      className: "",
    },
    {
      type: "techDescription",
      decorator: null,
      name: "Технические детали",
      icon: IconRoomSVG,
      divider: " - ",
      className: "",
    },
    {
      type: "territory",
      decorator: null,
      name: "Территория (закрытая, паркинг)",
      icon: IconRoomSVG,
      divider: " - ",
      className: "",
    },
    {
      type: "filling",
      decorator: null,
      name: "Наполнение (двери, радиаторы ..)",
      icon: IconRoomSVG,
      divider: " - ",
      className: "",
    },
    {
      type: "technology",
      group: "propertiesValues",
      decorator: null,
      name: "Технология строительства",
      icon: IconRoomSVG,
      divider: " - ",
      className: "",
    },
    {
      type: "walls",
      group: "propertiesValues",
      decorator: null,
      name: "Материал стен",
      icon: IconMaterialSVG,
      divider: " - ",
      className: "",
    },
    {
      type: "heating",
      group: "propertiesValues",
      decorator: null,
      name: "Отопление",
      icon: IconRoomSVG,
      divider: " - ",
      className: "",
    },
    {
      type: "warming",
      group: "propertiesValues",
      decorator: null,
      name: "Утепление",
      icon: IconRoomSVG,
      divider: " - ",
      className: "",
    },
    {
      type: "screed",
      group: "propertiesValues",
      decorator: null,
      name: "Стяжка",
      icon: IconRoomSVG,
      divider: " - ",
      className: "",
    },
    {
      type: "partition",
      group: "propertiesValues",
      decorator: null,
      name: "Межквартирные перегородки",
      icon: IconRoomSVG,
      divider: " - ",
      className: "",
    },
];

const privateAndBusinessData = [
  { name: "houseNum", text: "Номер дома" },
  { name: "flatNum", text: "Номер квартиры" },
  { name: "firstName", text: "Владелец" },
  { name: "phone", text: "Номер телефона" },
  { name: "terms", text: "Условия сделки" },
  { name: "docs", text: "Документация" },
];

const Field = ({ item }) => {
  const { decorator, name, icon, text, divider, className } = item;
  return (
    <p className={"characterisric__item" + className}>
      {icon ? <SVG className="item__icon" src={icon} /> : null}
      {name}
      {divider}
      {text}
      {decorator}
    </p>
  );
};

const DetailedComplexCard = (props) => {
  const [newCardInfo, setNewCardInfo] = useState([]);
  const [privateAndBusinessInfo, setPrivateAndBusinessInfo] = useState([]);
  const [cardDescription, setCardDescription] = useState();
  const [cardData, setCardData] = useState();
  const [currentItem, setCurrentItem] = useState();
  const [currentIndex, setCurrentIndex] = useState();

  const [loadingStarted, toggleLoadingStarted] = useState(false);
  const [cardImg, setCardImg] = useState();
  const [dataForExelExport, setDataForExelExport] = useState([]);
  const id = props.location.pathname.split("/")[2];
  const page = props.location.pathname;

  const converDataToObjectForExport = (char, data) => ({
    Характеристики: char,
    Данные: data,
  });

  const [currentCount, setCount] = useState(1);
  const timer = () => {
    if (currentCount > 10) {
      setCount(1);
    } else{
      setCount(currentCount + 1);
    }
  }

  useEffect(
    () => {
      const id = setInterval(timer, 1000);
      return () => clearInterval(id);
    },
    [currentCount]
  );

  useEffect(() => {
    toggleLoadingStarted(true);
    getComplexById(id)
      .then((response) => {
        let cardData = response.data;
        setCardData(cardData);

        let galleryImages = cardData.readiness.images;
        const images = galleryImages.map((item, index) => {
          return <DetailedCardSlider img={item} key={index} />;
        });
        setCardImg(images);

        return cardData;
      })
      .then((card) => {
        const type = card.type;
        const newCardData = newRules.map((item) => {
          if (item.type === "address") {
            const { districtName, subDistrictName, street } = card;
            return {
              ...item,
              text: convertAddress(districtName, subDistrictName, street),
            };
          }
          else if (item.type === "date") {
            const { type, group } = item;
            return {
              ...item,
              text: `${changeDateFormat(group ? card[group][type] : card[type])}`,
            };
          }
          else {
            const { type, group } = item;
            return {
              ...item,
              text: `${checkExisting(group ? card[group][type] : card[type])}`,
            };
          }
        });
        setNewCardInfo(newCardData);
        // описание (текcт ) карточки
        const description = card.description;
        setCardDescription(description);

        // приватная и бизнес инфа
        const secretInfo = card.private
          ? [{ ...card.private, ...card.business }]
          : [];
        setPrivateAndBusinessInfo(secretInfo);

        const returnArr = [secretInfo, newCardData, description];
        return returnArr;
      })
      .then((returnArr) => {
        const [secretInfo, newCardData, description] = returnArr;
        // клонируем массив с инфой о карточке (для дайнейего экспорта в exel)
        const clonedData = JSON.parse(JSON.stringify(newCardData));
        const newDataArr = clonedData.map((item, index) => {
          switch (item.type) {
            case "address":
              return converDataToObjectForExport("Адрес", item.text);
            case "space":
              return converDataToObjectForExport(
                item.name,
                item.text + item.decorator
              );
            case "price":
              return converDataToObjectForExport(
                "Цена",
                item.text + item.decorator
              );
            default:
              return converDataToObjectForExport(item.name, item.text);
          }
        });

        const newPrivateAndBusinessArr = privateAndBusinessData.map((item) => {
          const { text, name } = item;
          return secretInfo.length !== 0
            ? converDataToObjectForExport(text, secretInfo[0][name])
            : null;
        });

        const newDataForExcel = [
          ...newDataArr,
          converDataToObjectForExport("Описание", description),
          ...newPrivateAndBusinessArr,
        ];
        setDataForExelExport(newDataForExcel);
      })
      .then(() => {
        toggleLoadingStarted(false);
      });
    // eslint-disable-next-line
  }, []);

  const rowClick = (index, item) => {
    setCurrentIndex(index);
    setCurrentItem(item);
  }
  // функция конвертации в xlsx и экспорта
  const exportExelDoc = () => {
    let wb = XLSX.utils.book_new(); // создаем новый workbook (book_new () - служебная функция, вернет пустой объект воркбука)
    let exelDoc = XLSX.utils.json_to_sheet(dataForExelExport); // создаем worksheet для конвертации в xlsx - в кач аргумента - массив объектов
    XLSX.utils.book_append_sheet(wb, exelDoc, "card"); // добавляем worksheet к workbook
    XLSX.writeFile(wb, "Информация по объекту.xlsx");
  };

  const exportPDFDoc = () => {
    let id = "printtopdf";
    let iframe = document.getElementById(id);
    if (iframe) {document.body.removeChild(iframe);}
    iframe = document.createElement('iframe');
    iframe.id = id;
    iframe.width = "1010";
    iframe.height = "0";
    let s = '<!DOCTYPE html><html xmlns="http://www.w3.org/1999/xhtml"><head><title></title><style type="text/css">body, html, * {margin:0;padding:0;}body {width: 900px; padding:1cm;margin: 0 auto;}table, table td, table th{border:1px solid #000;border-collapse: collapse;}table td,table th{padding:5px;}</style></head><body>';
    s+='<table cellpadding="0" cellspacing="0" width="100%"><tr><th width="1%">Характеристики</th><th>Данные</th></tr>';
    dataForExelExport.forEach((item)=>{
      s+="<tr><td>"+item['Характеристики'].toString()+"</td><td>"+item['Данные'].toString()+"</td></tr>";
    });
    s += '</table></body></html>';
    document.body.appendChild(iframe);
    iframe.contentWindow.document.open();
    iframe.contentWindow.document.write(s);
    iframe.contentWindow.document.close();
    html2canvas(iframe.contentWindow.document.body,{
      scale: 1
    }).then(function (canvas) {

      var imgData = canvas.toDataURL('image/png');
      var imgWidth = 210;
      var pageHeight = 295;
      var imgHeight = canvas.height * imgWidth / canvas.width;
      var heightLeft = imgHeight;

      var doc = new jsPDF('p', 'mm');
      var position = 0;
      doc.addImage(imgData, 'PNG', 0, position, imgWidth, imgHeight);

      heightLeft -= pageHeight;

      while (heightLeft >= 0) {
        position = heightLeft - imgHeight;
        doc.addPage();
        doc.addImage(imgData, 'PNG', 0, position, imgWidth, imgHeight);
        heightLeft -= pageHeight;
      }

      doc.save('Информация по объекту.pdf');
      document.body.removeChild(iframe);
    });
  };

  return (
    <div className="page-container">
      <Header />
      <h1>{(cardData)?cardData.name:""}</h1>
      {(!loadingStarted && cardData && cardData.promotions)?
        <div className="card-info-promotions-wrapper">
          <div className="card-info-promotions">
              {
                (currentCount)? cardData.promotions.map((item, index) => {
                  if (new Date(item.startDate) > new Date() || new Date(item.finishDate) < new Date()) {
                      return;
                  }
                  return (
                    <div key={index}>
                      <SVG src={PromotionSVG} />
                      <i>
                        АКЦИЯ
                      </i>
                      <h3>{item.name}</h3>
                      <pre>{item.description}</pre>
                      <p>До окончания акции</p>
                      <div>
                        <strong>{formatPromotionDate(item.finishDate,'days')}<span>дней</span></strong>
                        <strong>:<span>&nbsp;</span></strong>
                        <strong>{formatPromotionDate(item.finishDate,'hours')}<span>часов</span></strong>
                        <strong>:<span>&nbsp;</span></strong>
                        <strong>{formatPromotionDate(item.finishDate,'minutes')}<span>минут</span></strong>
                        <strong>:<span>&nbsp;</span></strong>
                        <strong>{formatPromotionDate(item.finishDate,'seconds')}<span>секунд</span></strong>
                      </div>
                    </div>
                  );
                }):null
              }
          </div>
        </div>
        : null}
      <div className="main-bg">
        {loadingStarted ? (
          <p className="slider-loader">Loading...</p>
        ) : (
          <SliderComp
            dots={true}
            slidesToShow={1}
            components={cardImg}
            style={detailedCardSliderSlyles}
          />
        )}
      </div>
      <div className="content-container">
        <div className="full-card__wrapper">
          <div className="card-info-complex">
            {(!loadingStarted && cardData && cardData.layouts)?
              <div className="card-info-layouts-wrapper">
                <div className="card-info-layouts">
                  <div>
                  <div><div></div><div>Тип</div><div>Площадь</div><div>Цена</div></div>
                    {
                      cardData.layouts.map((item, index) => {
                        return (
                          <div key={index} className={(index === currentIndex)?"active__layout":""} onClick={() => rowClick(index, item)}>
                            <div><img src={item.logo.url} /></div>
                            <div>{item.name}</div>
                            <div>{item.space}</div>
                            <div>{item.price}$ <br/> {formatPerM2(item.space,item.price)}$ / <span>м<sup><small>2</small></sup></span></div>
                          </div>
                        );
                      })
                    }
                  </div>
                  <div>
                    <img src={(currentItem)?currentItem.logo.url:""} />
                  </div>
                </div>
              </div>
            : null}
            {loadingStarted ? (
              <p className="loader">Loading...</p>
            ) : (
              <div>
                <div className="card__characterisric__complex">
                  {newCardInfo.map((item, index) => {
                    return <Field item={item} key={index} />;
                  })}
                </div>
                <h2 align="center">{(cardData)?cardData.name:""}</h2>
                <p className="item--description">
                  {cardDescription ? cardDescription : null}
                </p>

                {/* блок приватной и бизнес инфы */}
                {privateAndBusinessInfo.length !== 0 ? (
                  <table className="secret-info">
                    <tbody>
                    <tr className="secret__header">
                      <td>Информация для партнеров</td>
                    </tr>
                    {privateAndBusinessData.map((item, index) => {
                      const { text, name } = item;
                      const getValues = privateAndBusinessInfo.map((item) => {
                        return item[name];
                      });
                      return (
                        <tr className="secret__item" key={index}>
                          <td>{text}</td>
                          <td>{checkExisting(getValues)}</td>
                        </tr>
                      );
                    })}
                    </tbody>
                  </table>
                ) : null}
                {privateAndBusinessInfo.length !== 0 ? (
                  <Button
                    className="btn-reg"
                    text={"Скачать exel файл"}
                    onClick={() => exportExelDoc()}
                    style={{ margin: 0, maxWidth: "155px" }}
                  />
                ) : null}
                {privateAndBusinessInfo.length !== 0 ? (
                  <Button
                    className="btn-reg"
                    text={"Скачать PDF файл"}
                    onClick={() => exportPDFDoc()}
                    style={{ margin: 0, maxWidth: "155px" }}
                  />
                ) : null}
              </div>
            )}
          </div>
        </div>
      </div>
      <Feedback style={feedBackStyle} currentPage={config.urlForPage + page} />
      <Footer />
    </div>
  );
};

export default DetailedComplexCard;

import React from "react";
import { NavLink } from "react-router-dom";

import "./AdminMenu.scss";

const AdminMenu = props => {
  return (
    <nav className="admin-nav">
      <ul className="admin-menu">
        <li className="admin-menu__item">
          <NavLink to="/admin" className="admin-menu__link">
            Объекты
          </NavLink>
        </li>
        <li className="admin-menu__item">
          <NavLink to="/admin/complex" className="admin-menu__link">
            Жилые комплексы
          </NavLink>
        </li>
        <li className="admin-menu__item">
          <NavLink to="/admin/users" className="admin-menu__link">
            Пользователи
          </NavLink>
        </li>
        <li className="admin-menu__item">
          <NavLink to="/admin/vacancies" className="admin-menu__link">
            Вакансии
          </NavLink>
        </li>
      </ul>
    </nav>
  );
};

export default AdminMenu;

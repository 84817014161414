import React from "react";
import Button from "../../../Components/Generic/Button/Button";

const styles = {
    btnStyle: {
        background: "linear-gradient(180deg, #EB0000 0%, #F40000 97.78%)",
        border: "1px solid #DC151D"
    },

    margin: {
        marginRight: "20px"
    }
};

const Buttons = ({isAdmin, disabled, textAdd, cancelInput}) => {
    return (
        <div className="admin-form__item item" style={{flexDirection: "row"}}>
            {/*{
            isAdmin ? (
                <Button
                    className="adminBtn--add"
                    type="submit"
                    disabled={disabled}
                    text={textAdd}
                    style={styles.margin}
                />
            ) : ''
        }*/}
            <Button
                className="adminBtn--add"
                type="submit"
                disabled={disabled}
                text={textAdd}
                style={styles.margin}
            />

            <Button
                className="adminBtn--add"
                type="button"
                text={"Отменить"}
                style={styles.btnStyle}
                onClick={cancelInput}
            />
        </div>
    );
};

export default Buttons;

import React from "react";

import Form from "../../Components/Main/Form/Form";
import Header from "../../Components/Main/Header/Header";
import Footer from "../../Components/Main/Footer/Footer";

const Registration = () => {
  return (
    <div className="page-container">
      <Header />
      <div className="center-wrapper">
        <Form
          type={"registration"}
          btnText={"Зарегистрироваться"}
          linkText={"Назад"}
          formHeader={"Регистрация"}
          submitToGlobal={"registerUser"}
          goToPage={"goBack"}
        />
      </div>
      <Footer />
    </div>
  );
};
export default Registration;

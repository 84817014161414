import React, { useEffect, useState } from "react";
import Select from "react-select";

import { customStyles } from "../SelectCustomStyles";

const Filter = ({
  link,
  name,
  label,
  request,
  onChangeFilter,
  defaultSelected,
  emptyfyAll,
}) => {
  const [options, setOptions] = useState([]);
  const [value, setValue] = useState([]);

  useEffect(() => {
    request(link)
      .then((response) => {
        const options = response.data.map((item) => {
          return {
            label: item.publicName,
            value: item._id,
          };
        });

        setOptions(options);
        return response;
      })
      .then((response) => {
        const ifDefault = response.data.filter(
          ({ _id }) => _id === defaultSelected
        );

        if (ifDefault.length > 0)
          setValue({
            label: ifDefault[0].publicName,
            value: ifDefault[0]._id,
          });
      });
    // eslint-disable-next-line
  }, [defaultSelected]);

  useEffect(() => {
    onChangeFilter(value, name);
    // eslint-disable-next-line
  }, [value]);

  useEffect(() => {
    return () => {
      setValue([]);
    };
  }, [emptyfyAll]);

  return (
    <div className="select__input">
      <label className="select__input__label">{label}</label>
      <Select
        cacheOptions
        options={options}
        value={value}
        placeholder={"Выберите"}
        isClearable
        onChange={(selectedValue) => {
          setValue(selectedValue);
        }}
        styles={customStyles}
      />
    </div>
  );
};

export default Filter;

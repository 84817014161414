import React from "react";

const CustomInput = ({
  group,
  value,
  type,
  placeholder,
  name,
  changeValue,
}) => {
  return (
    <input
      className="admin-item__field"
      type={type}
      name={name}
      placeholder={placeholder}
      value={typeof value === "object" ? value[name] : value}
      onChange={(event) =>
        typeof value === "object"
          ? changeValue(group, { ...value, [name]: event.target.value })
          : changeValue(name, event.target.value)
      }
    />
  );
};

export default CustomInput;

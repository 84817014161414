import React from "react";
import { Link } from "react-router-dom";
// import Button from "../../../Components/Generic/Button/Button";
import Logo from "../../Generic/Logo/Logo";

import "./Footer.scss";

const logoPhoneStyle = {
  display: "none",
};

function Footer() {
  return (
    <div className="footer">
      <div className="footer-b">
        <div className="footer-b__content">
          <div className="content__info">
            <Logo style={logoPhoneStyle} />
            <p className="content__info__text">АН Парламент ваш доверенный консультант на рынке недвижимости г.Одесса</p>
          </div>
          <div className="content__nav ">
            <Link to="/" className="content__nav__link">
              Парламент
            </Link>
            <Link to="/flats" className="content__nav__link">
              Квартиры
            </Link>
            <Link to="/primary" className="content__nav__link">
              Новострои
            </Link>
            <Link to="/houses" className="content__nav__link">
              Дома и участки
            </Link>
            <Link to="/commercial" className="content__nav__link">
              Коммерческая недвижимость
            </Link>
          </div>
          <div className="content__cont">
            <Link className="content__cont__link" to="/company">
              О компании
            </Link>
            <Link className="content__cont__link" to="/contacts">
              Контакты
            </Link>
          </div>
          <div className="content__address">
            <p>
              Филиал №1 Бочарова 62 <br></br>
              <br></br>+(380) 93-232-39-90 +(380) 98-250-71-81 +(380)
              48-736-80-70
            </p>
          </div>
        </div>
        {/* <Button
          className="btn-small"
          type="submit"
          text={"Войти как партнер"}
        /> */}
      </div>
      <div className="footer-s">
        <div className="footer-s__content">
          <div>
            <p>Copyright © 2020 All rights reserved.</p>
          </div>
          <div className="content__terms-policy">
            <p>Terms & Conditions</p>
            <p>Privacy Policy</p>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Footer;

import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";

import GoogleMap from "google-map-react";
import supercluster from 'points-cluster';

import "./Map.scss";

// маркер и при наведении на него - появление инфобокса с названием карточки
const MarkerInfo = ({ name, price, backgroundImage, pathname }) => {
  const [hover, setHover] = useState(false);
  const [click, setClick] = useState(false);
  const showInfoboxOnHoverOrNot = () => {
      return (
        <div
          className="infobox"
          style={hover ? { visibility: "visible" } : { visibility: "hidden" }}
        >
          {hover ? <p>{price ? '$' + price: ''}</p> : null}
        </div>
      );
  };

  const showInfoboxObjectOnHoverOrNot = () => {
    return (
      <div
        className="infobox-object"
        style={click ? { visibility: "visible" } : { visibility: "hidden" }}
        onMouseLeave={() => setClick(false)}
      >
        {click ? <div><div><Link to={pathname}><img alt="#" src={backgroundImage}></img></Link><Link to={pathname}><span>{name}</span></Link></div><hr /><p>{price ? 'Цена: $' + price : ''}</p></div> : null}
      </div>
    );
  };

  return (
    <div
      onMouseEnter={() => {setHover(true);setClick(false)}}
      onMouseLeave={() => setHover(false)}
    >
      <div className="marker" onMouseDown={() => {setHover(false);setClick(true)}}></div>

      {showInfoboxOnHoverOrNot()}
      {showInfoboxObjectOnHoverOrNot()}
    </div>
  );
};


const ClusterMarker = ({ points }) => {
  const [hover, setHover] = useState(false);
  const [pointsCount, setPointsCount] = useState(points.length);

  const showInfoboxOnHoverOrNot = () => {
      return (
        <div
          className="infobox-cluster"
          style={hover ? { visibility: "visible" } : { visibility: "hidden" }}
        >
          {hover ? <p>+{pointsCount}</p> : null}
        </div>
      );
  };

  return (
    <div
      onMouseEnter={() => setHover(true)}
      onMouseLeave={() => setHover(false)}
    >
        <div className="marker-cluster"><p>{pointsCount}</p></div>

      {showInfoboxOnHoverOrNot()}
    </div>
  );
}

const MapContainer = ({ data }) => {
  const [selectedPlace, setSelectedPlace] = useState([]);
  const [options, setOptions] = useState({
    mapOptions: {
      center: {lat: 46.586, lng: 30.799},
      zoom: 11,
      bounds: undefined
    }
  });
  const [clusters, setClusters] = useState([]);


  useEffect(() => {
    setSelectedPlace(data.map((item, index) => {
      const { coordinates, name, price, backgroundImage, pathname } = item;
      return {
        id: index,
        lat: coordinates.latitude,
        lng: coordinates.longitude,
        name,
        price,
        backgroundImage,
        pathname
      };
    }));
    //setSelectedPlace(data);
  }, [data]);


  const getClusters = (mapOptions) => {
      /*
      let radius = 50;
      if (mapOptions.zoom == 11) {
        radius = 20;
      }
      else if (mapOptions.zoom == 12) {
        radius = 5;
      }
      else if (mapOptions.zoom == 13) {
        radius = 1;
      }
      else if (mapOptions.zoom >= 14) {
        radius = 0.01;
      }
       */
      const clusters = supercluster(selectedPlace, {
        /*minZoom: 0,
        maxZoom: 16,
         */
        //radius: radius,
      });

      return clusters(mapOptions);
  };

  const createClusters = (mapOptions) => {
    if (mapOptions.bounds){
      setClusters(getClusters(mapOptions).map(({ wx, wy, numPoints, points }) => ({
        lat: wy,
        lng: wx,
        numPoints,
        id: `${numPoints}_${points[0].id}`,
        points,
      })));
    } else {
      setClusters([]);
    }
  };

  const handleMapChange = ({ center, zoom, bounds }) => {
    createClusters({
      center,
      zoom,
      bounds
    });
  };

  return (
    <div className="map-container">
      <GoogleMap
        bootstrapURLKeys={{ key: "AIzaSyANl0l7X8xblLYXxlBglFCWvZuDfypYcRU" }}
        onChange={handleMapChange}
        options={{ gestureHandling: 'greedy'}}
        defaultCenter={options.mapOptions.center}
        defaultZoom={options.mapOptions.zoom}
      >
        {clusters
          ? clusters.map((item, index) => {
            if (item.numPoints === 1) {
              return (
                <MarkerInfo
                  className="marker"
                  key={item.id}
                  id={item.id}
                  lat={item.points[0].lat}
                  lng={item.points[0].lng}
                  name={item.points[0].name}
                  price={item.points[0].price}
                  backgroundImage={item.points[0].backgroundImage}
                  pathname={item.points[0].pathname}
                />
              );
            }
            return (
              <ClusterMarker
                className="marker"
                key={item.id}
                lat={item.lat}
                lng={item.lng}
                points={item.points}
              />
            );
          })
          : null}
      </GoogleMap>
    </div>
  );
};

export default React.memo(MapContainer);

import React, { useState } from "react";
import SVG from "react-inlinesvg";
import Modal from "react-modal";
import Button from "../Button/Button";
import AppealForm from "../AppealForm/AppealForm";

import IconCloseSVG from "../../../static/images/iconClick_actions/close.svg";

const closeStyles = {
  position: "absolute",
  top: "16px",
  right: "24px",
};

const btnStyle = {
  color: "white",
};

Modal.setAppElement("#root");

const ModalWindow = ({ currentPage }) => {
  const [modalIsOpen, setModalIsOpen] = useState(false);

  const openModal = () => {
    setModalIsOpen(true);
  };

  const closeModal = () => {
    setModalIsOpen(false);
  };

  return (
    <div>
      <Button
        id="feedback-modal"
        className="btn-big"
        text={"Связаться"}
        onClick={openModal}
        style={btnStyle}
      />
      <Modal
        isOpen={modalIsOpen}
        onRequestClose={closeModal}
        className="modal"
        contentLabel="Example Modal"
      >
        <SVG src={IconCloseSVG} onClick={closeModal} style={closeStyles} />
        <AppealForm
          header={"Задайте интересующий Вас вопрос"}
          textarea={"Ваш вопрос"}
          currentPage={currentPage}
          uploadFile={false}
          file={false}
          onClose={closeModal}
        />
      </Modal>
    </div>
  );
};

export default ModalWindow;

import React, {useState, useEffect} from "react";
import useWindowWidth from "../../../../CustomHook/useWindowWidth";
import Filter from "./Filter";

import {getFilterByNameRequest} from "../../../../API/filtersActions";
import {getBuildersList} from "../../../../API/complexesActions";
import "../SelectForm.scss";

const pageFilter = {
    secondary: [
        {
            link: "material",
            name: "material",
            label: "Материал дома",
            request: getFilterByNameRequest,
        },

        {
            link: "secondaryClass",
            name: "class",
            label: "Класс недвижимости",
            request: getFilterByNameRequest,
        },

        {
            link: "condition",
            name: "condition",
            label: "Ремонт",
            request: getFilterByNameRequest,
        },
    ],
    primary: [
        {
            link: "builder",
            name: "builder",
            label: "Застройщик",
            request: getBuildersList,
        },
        {
            link: "material",
            name: "material",
            label: "Материал дома",
            request: getFilterByNameRequest,
        },

        {
            link: "primaryClass",
            name: "primaryClass",
            label: "Класс жилья",
            request: getFilterByNameRequest,
        },

        {
            link: "condition",
            name: "condition",
            label: "Ремонт",
            request: getFilterByNameRequest,
        },
    ],
    house: [
        {
            link: "material",
            name: "material",
            label: "Материал дома",
            request: getFilterByNameRequest,
        },

        {
            link: "condition",
            name: "condition",
            label: "Ремонт",
            request: getFilterByNameRequest,
        },
    ],
    commercial: [
        {
            link: "disposition",
            name: "disposition",
            label: "Расположение",
            request: getFilterByNameRequest,
        },

        {
            link: "condition",
            name: "condition",
            label: "Ремонт",
            request: getFilterByNameRequest,
        },
    ],
};

const RenderFilterCategories = ({type, handleSelected, emptyfyAll}) => {
    let [storageSelected, setStorageSelected] = useState({});
    const [categoriesFilters, setCategoriesFilters] = useState({});
    const windowWidth = useWindowWidth();

    useEffect(() => {
        for (let key in categoriesFilters) {
            //КОСТЫЛЬ
            if (key === "builder") {
                handleSelected("public", key, categoriesFilters[key]);
            } else {
                handleSelected("filters", key, categoriesFilters[key]);
            }
        }
        // eslint-disable-next-line
    }, [categoriesFilters]);

    const onChangeFilter = (event, name) => {
        if (event) {
            setCategoriesFilters({...categoriesFilters, [name]: event.value});
        } else {
            setCategoriesFilters({...categoriesFilters, [name]: undefined});
        }
    };

    useEffect(() => {
        if (sessionStorage.getItem('filter')) {
            storageSelected = JSON.parse(sessionStorage.getItem('filter'));
            setStorageSelected(storageSelected)
        }
    }, []);

    useEffect(() => {
        return () => {
            for (let key in categoriesFilters) {
                if (key === "builder") {
                    handleSelected("public", key, undefined);
                } else {
                    handleSelected("filters", key, undefined);
                }
            }
            setCategoriesFilters({});
        };
        // eslint-disable-next-line
    }, [emptyfyAll]);

    return (
        <form className="select select--categories">
            {windowWidth > 1254
                ? pageFilter[type].map((item, index) => {
                    return (
                        <Filter
                            key={index}
                            link={item.link}
                            name={item.name}
                            label={item.label}
                            request={item.request}
                            onChangeFilter={onChangeFilter}
                            emptyfyAll={emptyfyAll}
                            defaultSelected={
                                //КОСТЫЛЬ
                                storageSelected !== undefined && Object.keys(storageSelected).length > 0
                                    ? item.name === "builder"
                                        ? storageSelected.public.builder
                                        : storageSelected.filters[item.name]
                                            ? storageSelected.filters[item.name]
                                            : null
                                    : null
                            }
                        />
                    );
                })
                : pageFilter[type]
                    .filter((item, index) => index < 3)
                    .map((item, index) => {
                        return (
                            <Filter
                                key={index}
                                link={item.link}
                                name={item.name}
                                label={item.label}
                                request={item.request}
                                onChangeFilter={onChangeFilter}
                                defaultSelected={
                                    //КОСТЫЛЬ
                                    storageSelected !== undefined && Object.keys(storageSelected).length > 0
                                        ? item.name === "builder"
                                            ? storageSelected.public.builder
                                            : storageSelected.filters[item.name]
                                                ? storageSelected.filters[item.name]
                                                : null
                                        : null
                                }
                                emptyfyAll={emptyfyAll}
                            />
                        );
                    })}
        </form>
    );
};

export default RenderFilterCategories;

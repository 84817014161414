import React, { useState, useEffect } from "react";
import { CopyToClipboard } from "react-copy-to-clipboard";
import { Link } from "react-router-dom";
import { useToasts } from "react-toast-notifications";
import SVG from "react-inlinesvg";
import useWindowWidth from "../../CustomHook/useWindowWidth";

import Button from "../../Components/Generic/Button/Button";

import { addToFavouritesRequest } from "../../API/userActions";

import IconAddressSVG from "../../static/images/cardIcons/address_icon.svg";
import IconAreaSVG from "../../static/images/cardIcons/area_icon.svg";
import IconThumpUpSVG from "../../static/images/iconClick_actions/thumb_up.svg";
import IconShareSVG from "../../static/images/iconClick_actions/share.svg";

import "./ComplexCard.scss";

const ComplexCard = (props) => {
  const [copiedUrl, setCopiedUrl] = useState(false);
  const { addToast } = useToasts();
  const windowWidth = useWindowWidth();
  const link = props.link;
  const card = props.card;
  const description = card.description;
  // const publicId = props.card.publicId;
  const id = props.card._id;
  const cardBg = props.card.bg;
  const urlToCopy = window.location.href + "/" + id;

  const addToFavourites = () => {
    addToFavouritesRequest({ id: id })
      .then((response) => {
        addToast("Объект успешно добавлен в избранное", {
          appearance: "success",
          autoDismiss: true,
        });
      })
      .catch((error) => {
        addToast(
          "Добавить объект в избранное не удалось. Объект уже есть в избранном",
          {
            appearance: "error",
            autoDismiss: true,
          }
        );
      });
  };

  useEffect(() => {
    setTimeout(() => {
      setCopiedUrl(false);
    }, 3000);
  }, [copiedUrl]);

  return (
    <div
      className="card__complex"
      style={windowWidth > 576 ? props.cardStyles : { flexDirection: "column" }}
    >
      <div className="card__img" style={props.cardImgStyles}>
        <img className="img-bg" alt="#" src={cardBg}></img>
      </div>
      <div className="card__characterisric">
        <p className="characterisric__item item--name">
          {card.name ? card.name : "Не указано"}
        </p>
        <p className="characterisric__item item--price">{card.price}$/
          <span>
              м
              <sup>
                <small>2</small>
              </sup>
            </span>
        </p>
        <div className="card__additional">
          <p className="characterisric__item item--address">
            {card.subDistrict ? card.subDistrict : null}, ул.{" "}
            {card.street ? card.street : null}
          </p>
        </div>
        <div className="details">
          <Link
            to={{
              pathname: `${link}/${id}`,
              params: { id },
            }}
          >
            <Button className="btn-small" text={"Подробнее"} />
          </Link>
          <div>
            <SVG
              style={{ cursor: "pointer" }}
              className="icon"
              src={IconThumpUpSVG}
              onClick={() => addToFavourites()}
            />
            <CopyToClipboard text={urlToCopy} onCopy={() => setCopiedUrl(true)}>
              <SVG
                style={{ cursor: "pointer" }}
                className="icon"
                src={IconShareSVG}
                onClick={() =>
                  addToast("Ссылка на объект скопирована в буфер обмена", {
                    appearance: "success",
                    autoDismiss: true,
                  })
                }
              />
            </CopyToClipboard>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ComplexCard;

import React from "react";
import useGlobalHook from "use-global-hook";

import * as actions from "../actions";

const initialState = {
  user: {
    role: undefined,
    isLoggedIn: false,
    isSavedToken: "",
    control: undefined,
    data: {},
  },
  page: {
    activePage: ``,
    changePage: false,
  },
};

const useGlobal = useGlobalHook(React, initialState, actions);

export default useGlobal;

import React from "react";
import SVG from "react-inlinesvg";
import Button from "../../../Components/Generic/Button/Button";

import IconFilterUpSVG from "../../../static/images/iconClick_actions/filter_up.svg";
import IconFilterDownSVG from "../../../static/images/iconClick_actions/filter_down.svg";

import "./Row.scss";

const rowHeaderData = {
  complex: [
    {
      name: "publicId",
      text: "Артикул",
      iconUp: IconFilterUpSVG,
      iconDown: IconFilterDownSVG,
    },

    {
      name: "name",
      text: "Название",
      iconUp: IconFilterUpSVG,
      iconDown: IconFilterDownSVG,
    },

    {
      name: "createdDate",
      text: "Дата",
      iconUp: IconFilterUpSVG,
      iconDown: IconFilterDownSVG,
    },
  ],

  object: [
    {
      name: "publicId",
      text: "Артикул",
      iconUp: IconFilterUpSVG,
      iconDown: IconFilterDownSVG,
      className: " row-header--id",
    },

    {
      name: "type",
      text: "Тип",
      iconUp: IconFilterUpSVG,
      iconDown: IconFilterDownSVG,
    },

    {
      name: "active",
      text: "Активность",
      iconUp: null,
      iconDown: null,
    },
    {
      name: "phone",
      text: "Телефон",
      iconUp: null,
      iconDown: null,
    },

    {
      name: "price",
      text: "Цена",
      iconUp: IconFilterUpSVG,
      iconDown: IconFilterDownSVG,
      className: " row-header--price",
    },

    {
      name: "square",
      text: "Квадрат",
      className: " row-header--square",
    },
    {
      name: "searchStreet",
      text: "Дн/Доб",
      className: " row-header--searchStreet",
    },
    {
      name: "rooms",
      text: "Комнат",
      className: " row-header--rooms",
    },
    {
      name: "floor",
      text: "Этаж",
      className: " row-header--floor",
    },
    {
      name: "allFloors",
      text: "Этажность",
      className: " row-header--allFloors",
    },
    {
      name: "space",
      text: "Метраж",
      className: " row-header--space",
    },
    {
      name: "material",
      text: "Материал",
      className: " row-header--material",
    },
    {
      name: "docs",
      text: "Документы",
      className: " row-header--material",
    },
    {
      name: "secondaryClass",
      text: "Класс жилья",
      className: " row-header--material",
    },
    {
      name: "condition",
      text: "Ремонт",
      className: " row-header--material",
    },
    {
      name: "terms",
      text: "Усл. сделки",
      className: " row-header--material",
    },

    {
      name: "createdDate",
      text: "Дата",
      iconUp: IconFilterUpSVG,
      iconDown: IconFilterDownSVG,
      className: " row-header--date",
    },
    {
      name: "state",
      text: "Состояние",
      iconUp: null,
      iconDown: null,
    },
  ],

  user: [
    {
      name: "publicId",
      text: "Номер",
      iconUp: IconFilterUpSVG,
      iconDown: IconFilterDownSVG,
    },

    {
      name: "name",
      text: "Имя",
      iconUp: IconFilterUpSVG,
      iconDown: IconFilterDownSVG,
    },

    {
      name: "phone",
      text: "Телефон",
      iconUp: IconFilterUpSVG,
      iconDown: IconFilterDownSVG,
    },

    {
      name: "createdDate",
      text: "Дата",
      iconUp: IconFilterUpSVG,
      iconDown: IconFilterDownSVG,
    },
    {
      name: "state",
      text: "Доступ",
      iconUp: null,
      iconDown: null,
    },
  ],

  vacancy: [
    {
      name: "publicId",
      text: "Номер",
      iconUp: IconFilterUpSVG,
      iconDown: IconFilterDownSVG,
    },

    {
      name: "name",
      text: "Вакансия",
      iconUp: IconFilterUpSVG,
      iconDown: IconFilterDownSVG,
    },

    {
      name: "createdDate",
      text: "Дата",
      iconUp: IconFilterUpSVG,
      iconDown: IconFilterDownSVG,
    },

    {
      name: "state",
      text: "Статус",
      iconUp: null,
      iconDown: null,
    },
  ],
};

const sortFunction = (event, sortBy, state, setState) => {
  const list = state.list;
  const sort = state.list.sort;

  if (sort.by !== sortBy) {
    setState({ ...state, list: { ...list, sort: { by: sortBy, type: 1 } } });
    return;
  }
  if (sort.type === 1) {
    setState({
      ...state,
      list: { ...list, sort: { by: sortBy, type: -1 } },
    });
    return;
  }
  if (sort.type === -1) {
    setState({
      ...state,
      list: { ...list, sort: { by: undefined, type: undefined } },
    });
    return;
  }
};

const changeSortIconsColor = (sortBy, state, sortType) => {
  const sort = state.list.sort;

  const result =
    sort.by === sortBy
      ? sort.type === sortType
        ? { fill: "black" }
        : { fill: "grey" }
      : { fill: "grey" };

  return result;
};

const RowHeader = ({ pageType, state, setState, style, headerRowStyle }) => {
  const renderHeaderData = () => {
    return rowHeaderData[pageType].map((item, index) => {
      return (
        <div
          className={"filter__item " + item.className}
          style={headerRowStyle}
          key={index}
          onClick={(event) => sortFunction(event, item.name, state, setState)}
        >
          <div className="filter__icons">
            {item.iconUp ? (
              <SVG
                src={item.iconUp}
                style={changeSortIconsColor(item.name, state, 1)}
              />
            ) : null}
            {item.iconDown ? (
              <SVG
                src={item.iconDown}
                style={changeSortIconsColor(item.name, state, -1)}
              />
            ) : null}
          </div>
          {item.name === "state" ? (
            <Button className="adminBtn--condition" text={item.text} />
          ) : (
            <p style={{ cursor: "pointer" }}>{item.text}</p>
          )}
        </div>
      );
    });
  };

  return (
    <div className="header-row" style={{
      maxWidth: "initial",
      minWidth: "100%",
      width: "initial",
    }}>
      {renderHeaderData()}
      <p className="fake-filter__first"></p>
      <p className="fake-filter__second"></p>
    </div>
  );
};

export default RowHeader;

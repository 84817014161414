import React from "react";

const CustomCheckbox = ({ value, type, checked, name, changeValue, placeholder = "Срочность"}) => {
  return (
    <div className="admin-switch">
      <label className="admin-switch__label">{placeholder}</label>
      <input
        className="switch__btn"
        type={type}
        name={name}
        value={value}
        checked={checked}
        onChange={(event) => changeValue(name, event.target.checked)}
      />
    </div>
  );
};

export default CustomCheckbox;

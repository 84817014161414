import React from "react";

import SliderComp from "../../Components/Main/SliderComp/SliderComp";

import "./Complex.scss";

const complexSliderSlyles = {
  height: "480px",
};

const Complex = ({ complexData, loading }) => {
  const getStagesImages = () => {
    const stagesImages = complexData.readiness.images;
    return stagesImages.map((item, index) => {
      return <img alt="#" src={item} key={index} />;
    });
  };

  const getPlansImages = () => {
    const plansImages = complexData.plans;
    return plansImages.map((item, index) => {
      return <img className="plans__img" alt="#" src={item} key={index} />;
    });
  };

  const changeDateFormat = (date) => {
    const newDate = new Date(date);
    let day = newDate.getDate();
    if (day < 10) day = "0" + day;
    let month = newDate.getMonth() + 1;
    if (month < 10) month = "0" + month;
    let year = newDate.getFullYear();
    return day + "." + month + "." + year;
  };

  return (
    <div className="complex__wrapper">
      {loading ? (
        <p className="slider-loader">Loading...</p>
      ) : (
        <div>
          <div className="content-container">
            <div className="complex__info">
              <h2 className="complex__header">О ЖК</h2>
              <p className="complex__text">{complexData.description}</p>
              <table className="complex__property">
                <tbody>
                  <tr className="property__item">
                    <td>Технология строительства</td>
                    <td>{complexData.properties.technology}</td>
                  </tr>
                  <tr className="property__item">
                    <td>Технические детали</td>
                    <td>{complexData.techDescription}</td>
                  </tr>
                  <tr className="property__item">
                    <td>Территория (закрытая, паркинг)</td>
                    <td>{complexData.territory}</td>
                  </tr>
                  <tr className="property__item">
                    <td>Кол-во секций</td>
                    <td>{complexData.sections}</td>
                  </tr>
                  <tr className="property__item">
                    <td>Этажность дома</td>
                    <td>{complexData.allFloors}</td>
                  </tr>
                  <tr className="property__item">
                    <td>Материал стен</td>
                    <td>{complexData.properties.walls}</td>
                  </tr>
                  <tr className="property__item">
                    <td>Внутреннее наполнение</td>
                    <td>{complexData.filling}</td>
                  </tr>
                  <tr className="property__item">
                    <td>Отопление</td>
                    <td>{complexData.properties.heating}</td>
                  </tr>
                  <tr className="property__item">
                    <td>Утепление</td>
                    <td>{complexData.properties.warming}</td>
                  </tr>
                  <tr className="property__item">
                    <td>Стяжка</td>
                    <td>{complexData.properties.screed}</td>
                  </tr>
                  <tr className="property__item">
                    <td>Межвартирные перегородки</td>
                    <td>{complexData.properties.partition}</td>
                  </tr>
                  <tr className="property__item">
                    <td>Высота потолков</td>
                    <td>{complexData.ceilingHeight}</td>
                  </tr>
                </tbody>
              </table>
            </div>
            <div className="complex__stages">
              <div className="complex__info">
                <h2 className="complex__header ">Этапы строительства</h2>
                <p className="complex__text">{complexData.readiness.text}</p>
                <p className="complex__text">
                  Сроки сдачи - {changeDateFormat(complexData.readiness.date)}
                </p>
              </div>
              <div className="stages__slider">
                <SliderComp
                  dots={true}
                  slidesToShow={1}
                  components={getStagesImages()}
                  style={complexSliderSlyles}
                />
              </div>
            </div>
          </div>
          <div className="complex__plans">
            <h2 className="complex__header complex__header--plans">
              Поэтажные планировки
            </h2>
            <div className="plans__slider">
              <SliderComp
                dots={true}
                slidesToShow={1}
                components={getPlansImages()}
                style={complexSliderSlyles}
              />
            </div>
          </div>
        </div>
      )}
    </div>
  );
};
export default Complex;

import React, {useEffect, useState} from "react";
import { useToasts } from "react-toast-notifications";
import { useHistory } from "react-router-dom";
import Header from "../../AdminGeneric/Header/AdminHeader";
import Menu from "../../AdminGeneric/Menu/AdminMenu";
import Breadcrumbs from "../../../Breadcrumbs/Breadcrumbs";
import Button from "../../../Components/Generic/Button/Button";
import {getComplexLayoutsRequest, deleteComplexLayoutRequest} from "../../../API/complexesActions";
import Select from "react-select";
import {customStyles} from "../SelectCustomStyles";

const styles = {
  bntStyle: {
    margin: "16px 0 16px 0",
  }
};
const actionOptions = [
  { value: "change", label: "Изменить" },
];

const Row = (props) => {
  const {children} = props;
  return (
    <div className="row-object">
      {children[0]}
      {children[1]}
      {children[2]}
      {children[3]}
    </div>
  );
};

const ComplexLayouts = (props) => {
  const id = props.location.pathname.split("/")[3];
  const history = useHistory();
  const [requestObject, setRequestObject] = useState({
  });

  const [loadingStarted, toggleLoadingStart] = useState(false);
  const [layoutsForRender, setLayoutsForRender] = useState([]);
  const { addToast } = useToasts();

  const addLayoutLink = () => {
    history.push({
      pathname: "/admin/complex/" + id + "/layouts/add",
      state: {
        objectId: id,
        itemId: null
      },
    });
  };
  const selectedOption = (item, index, event) => {
    history.push({
      pathname: "/admin/complex/"+id+"/layouts/edit",
      state: {
        objectId: id,
        itemId: index,
      },
    });
  }

  const getLayouts = () => {
    toggleLoadingStart(true);
    getComplexLayoutsRequest(id,{}).then((response) => {
      if (response.data) {
        setLayoutsForRender(response.data);
        toggleLoadingStart(false);
      }
    }).catch(()=>{
      toggleLoadingStart(false);
    })
  }

  const handleDelete = (index) => {
    toggleLoadingStart(true);
    deleteComplexLayoutRequest(id,index).then(() => {
      addToast("Успешно удалено", {
        appearance: "success",
        autoDismiss: true,
      });
      getLayouts();
    }).catch(()=>{
      addToast("Ошибка удаления", {
        appearance: "error",
        autoDismiss: true,
      });
      toggleLoadingStart(false);
    })
  };

  useEffect(() => {
    getLayouts();
  }, [requestObject]);

  return (
    <div>
      <Header />
      <div className="admin-wrapper">
        <Menu />
        <div className="admin-content">
          <Breadcrumbs />
          <Button
            className="adminBtn--add"
            type="submit"
            text={"Добавить"}
            style={styles.bntStyle}
            onClick={() => addLayoutLink()}
          />
          <div className="header-row">
            <div className="filter__item"><p>Тип</p></div>
            <div className="filter__item"><p>Площадь</p></div>
            <div className="filter__item"><p>Цена</p></div>
          </div>

          {loadingStarted ? (
            <p className="loader-admin">Loading...</p>
          ) : (
            layoutsForRender.map((item, index) => {
              return (
                <Row
                  key={index}
                  index={index}
                >
                  <p className="filter__item" >
                    {item.name}
                  </p>
                  <p className="filter__item" >
                    {item.space}
                  </p>
                  <p className="filter__item">
                    {item.price}
                  </p>
                  <Button
                    className="adminBtn--delete"
                    type="submit"
                    text={"Удалить"}
                    onClick={() => handleDelete(index)}
                  />
                </Row>
              );
            })
          )}
        </div>
      </div>
    </div>
  );
}

export default ComplexLayouts;

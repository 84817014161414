import React from "react";
import Select from "react-select";

import { customStyles } from "../SelectCustomStyles";

const Filter = ({ value, options, onChange }) => {
  return (
    <Select
      placeholder={"Выберите"}
      isClearable
      value={value}
      options={options}
      onChange={(event) => {
        onChange(event);
      }}
      styles={customStyles}
    />
  );
};

export default Filter;

import React from "react";

import Form from "../../Components/Main/Form/Form";
import Header from "../../Components/Main/Header/Header";
import Footer from "../../Components/Main/Footer/Footer";

const Login = () => {
  return (
    <div className="page-container">
      <Header />
      <div className="center-wrapper">
        <Form
          type={"login"}
          btnText={"Войти"}
          linkText={"Зарегистрироваться"}
          formHeader={"Войти в личный кабинет"}
          submitToGlobal={"loginUser"}
          goToPage={"goRegister"}
        />
      </div>
      <Footer />
    </div>
  );
};

export default Login;

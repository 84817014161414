import React, { useState, useEffect } from "react";
import "../SelectForm.scss";

const CheckboxFilter = ({ handleSelected, emptyfyAll }) => {
  const [checked, setChecked] = useState(undefined);

  useEffect(() => {
    handleSelected("public", "urgency", checked);
    // eslint-disable-next-line
  }, [checked]);

  useEffect(() => {
    return () => {
      setChecked(undefined);
      handleSelected("public", "urgency", undefined);
    };
    // eslint-disable-next-line
  }, [emptyfyAll]);

  const changeValue = () => {
    setChecked(checked ? undefined : true);
  };

  return (
    <div className="switch">
      <label className="switch__label">Срочность</label>

      <input
        className="switch__btn"
        type="checkbox"
        checked={checked}
        onChange={changeValue}
      />
    </div>
  );
};

export default CheckboxFilter;

import React from "react";

const CustomDate = ({ group, value, type, name, changeValue }) => {

  const changeDateFormat = (date) => {
    const newDate = new Date(date);
    let day = newDate.getDate();
    if (day < 10) day = "0" + day;
    let month = newDate.getMonth() + 1;
    if (month < 10) month = "0" + month;
    let year = newDate.getFullYear();
    return year + "-" + month + "-" + day;
  };

  const dateValue = changeDateFormat(value[name]);

  return (
    <input
      className="admin-item__field"
      type={type}
      name={name}
      value={dateValue}
      onChange={(event) => {
        typeof value === "object"
          ? changeValue(group, { ...value, [name]: event.target.value })
          : changeValue(name, event.target.value);
      }}
    />
  );
};

export default CustomDate;

import React, {useEffect, useState} from "react";
import { useHistory } from "react-router-dom";
import { useToasts } from "react-toast-notifications";
import Header from "../../AdminGeneric/Header/AdminHeader";
import Menu from "../../AdminGeneric/Menu/AdminMenu";
import Breadcrumbs from "../../../Breadcrumbs/Breadcrumbs";
import Buttons from "../../AdminGeneric/Buttons/Buttons";
import TextInput from "../../Forms/CustomInputs/TextInput";
import FileInput from "../../Forms/CustomInputs/FileInput";
import Textarea from "../../Forms/CustomInputs/Textarea";
import {addComplexLayoutRequest, getComlextLayoutByIdRequest} from "../../../API/complexesActions";

const AddComplexLayout = (props) => {
  const id = props.location.state.objectId;
  const itemId = props.location.state.itemId;
  const [formData, setFormData] = useState({
    name: '',
    description: '',
    space: 0,
    price: 0,
    logo: '',
  });
  const [loadingStarted, toggleLoadingStart] = useState(false);
  const { addToast } = useToasts();
  const history = useHistory();
  const goToLayouts = () => {
    history.push("/admin/complex/" + id + "/layouts");
  };

  // функция отправки формы
  const handleFormSubmit = (data, event) => {
    event.preventDefault();
     if (itemId !== null) {
       data.itemId = itemId;
     }
    addComplexLayoutRequest(id, data).then(()=>{
      addToast("Планировка успешно обновлена", {
        appearance: "success",
        autoDismiss: true,
      });
      return new Promise((resolve) => {
        setTimeout(() => {
          resolve(() => goToLayouts());
        }, 1000);
      });
    })
    .then((goToLayouts) => {
      goToLayouts();
    }).catch(()=>{
      addToast(
        "Ошибка. Проверьте правильность заполнения полей формы. Они не должны быть пустыми.",
        {
          appearance: "error",
          autoDismiss: true,
        }
      );
    });
  };

  useEffect(() => {
    if (itemId !== null) {
      toggleLoadingStart(true);
      getComlextLayoutByIdRequest(id, itemId).then((response) => {
        setFormData(response.data);
        toggleLoadingStart(false);
      }).catch(() => {
        addToast(
          "Ошибка чтения планировки",
          {
            appearance: "error",
            autoDismiss: true,
          }
        );
        setTimeout(() => {
          goToLayouts();
        }, 1000);
      });
    } else {
      setFormData(formData);
    }
    // eslint-disable-next-line
  }, []);

  const changeFormData = (name, value) => {
    setFormData({
      ...formData,
      [name]: value,
    });
  };

  return (
    <div>
      <Header />
      <div className="admin-wrapper">
        <Menu />
        <div className="admin-content">
          <Breadcrumbs />
          {loadingStarted ? (
            <p className="loader-admin">Loading...</p>
          ) : (
            <form
              className="admin-form"
              onSubmit={(event) => handleFormSubmit(formData, event)}
            >
              <div className="admin-item__wrapper admin-item__wrapper--custom">
                <div className="admin-form__item item">
                  <label className="admin-item__label">Тип</label>
                    <TextInput
                      className="admin-item__field"
                      type="text" placeholder="Введите тип" name="name" value={formData.name} changeValue={changeFormData}/>
                </div>
              </div>

              <div className="admin-item__wrapper admin-item__wrapper--custom">
                <div className="admin-form__item item">
                  <label className="admin-item__label">Площадь</label>
                  <TextInput
                    className="admin-item__field"
                    type="number" placeholder="Введите площадь" name="space" value={formData.space} changeValue={changeFormData}/>
                </div>
              </div>

              <div className="admin-item__wrapper admin-item__wrapper--custom">
                <div className="admin-form__item item">
                  <label className="admin-item__label">Цена</label>
                  <TextInput
                    className="admin-item__field"
                    type="number" placeholder="Введите цену" name="price" value={formData.price} changeValue={changeFormData}/>
                </div>
              </div>

              <div className="admin-item__wrapper admin-item__wrapper--custom">
                <div className="admin-form__item item">
                  <label className="admin-item__label">Изображение</label>
                  <FileInput
                    className="admin-item__field"
                    type="file" text="Нажмите для загрузки изображения"
                    multiple={false} returnas="" header="File:"
                    name="logo" value={formData.logo} changeValue={changeFormData}/>
                </div>
              </div>

              <div className="admin-item__wrapper admin-item__wrapper--custom">
                <div className="admin-form__item item">
                  <label className="admin-item__label">Описание</label>
                  <Textarea
                    className="admin-item__field"
                    type="text" placeholder="Описание" name="description" value={formData.description} changeValue={changeFormData}/>
                </div>
              </div>

              <Buttons
                disabled={null}
                textAdd={
                  itemId === null ? "Добавить" : "Изменить"
                }
              />
            </form>
          )}
        </div>
      </div>
    </div>
  );
}

export default AddComplexLayout;

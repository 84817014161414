export const setActivePage = (store, link) => {
  const newState = { ...store.state };
  newState.page.activePage = link;
  store.actions.page.setChangeStatus(true);
  store.setState({ ...newState });
};

export const setChangeStatus = (store, status) => {
  const newState = { ...store.state };
  newState.page.changePage = status;
  store.setState({ ...newState });
};

import { registerRequest, loginRequest, getSelfData } from "../API/userActions";
const myStorage = window.localStorage;

// регистрация
export const registerUser = (store, data, error, cbError) => {
  registerRequest(data)
    .then((response) => {
      if (response.data !== undefined) {
        store.actions.page.setActivePage(`/login`);
      } else {
        cbError({
          ...error,
          responseData: `Неверный ответ сервера`,
        });
      }
    })
    .catch((error) => {
      cbError({
        ...error,
        connect: `Ошибка: ${error}`,
      });
    });
};

// вход
export const loginUser = (store, data, error, cbError) => {
  loginRequest(data)
    .then((response) => {
      if (response.data !== undefined) {
        myStorage.setItem("access-token", response.data.token);
        const newState = { ...store.state };
        newState.user.role = response.data.role;
        newState.user.isLoggedIn = true;
        newState.user.isSavedToken = response.data.token;
        newState.user.data = response.data;
        store.setState({ ...newState });
        store.actions.page.setActivePage(`/flats`);
      } else {
        cbError({
          ...error,
          responseData: `Неверный ответ сервера`,
        });
      }
    })
    .catch((error) => {
      if (error.response !== undefined) {
        cbError({
          ...error,
          loginPassword: `Неверный номер телефона или пароль`,
        });
      } else {
        cbError({
          ...error,
          connect: `Ошибка: ${error}`,
        });
      }
    });
};

// выход
export const logoutUser = (store) => {
  myStorage.removeItem("access-token");
  const newState = { ...store.state };
  newState.user.isLoggedIn = false;
  newState.user.isSavedToken = "";
  store.setState({ ...newState });
  store.actions.page.setActivePage("/");
};

export const userToggleLoginOnLoad = (store) => {
  const token = myStorage["access-token"];

  const newState = JSON.parse(JSON.stringify(store.state));
  newState.user.isLoggedIn = true;
  newState.user.isSavedToken = token;

  store.setState(newState);
};

// проверяем можно ли пользователю в админку
export const checkUSerIsAdmin = (store) => {
  getSelfData(myStorage["access-token"])
    .then((response) => {
      const newState = { ...store.state };
      newState.user.role = response.data.role;
      newState.user.control = response.data.control;
      store.setState({ ...newState });
    })
    .catch((error) => {
      const newState = { ...store.state };
      newState.user.control = false;
      store.setState({ ...newState });
    });
};

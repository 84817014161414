import axios from "axios";

import config from "./config";

const cardEndpoint = "cards/";

const myStorage = window.localStorage;

// создание карточки
export const createCardRequest = (data) => {
  const token = myStorage.getItem("access-token");
  if (!token) return;
  return axios.post(config.url + cardEndpoint + "create", data, {
    headers: { Authorization: "Bearer " + token },
  });
};

// получение детализированной карточки
export const getDetailedCardRequest = (id) => {
  const token = myStorage.getItem("access-token");
  if (token) {
    return axios.get(config.url + cardEndpoint + id, {
      headers: { Authorization: "Bearer " + token },
    });
  } else {
    return axios.get(config.url + cardEndpoint + id);
  }
};

// получение всех карточек
export const getAllCardsRequest = (data) => {
  const token = myStorage.getItem("access-token");
  if (token) {
    return axios.post(config.url + cardEndpoint, data, {
      headers: { Authorization: "Bearer " + token },
    });
  } else {
    return axios.post(config.url + cardEndpoint, data);
  }
};

export const getAllCardsCountRequest = (data) => {
  const token = myStorage.getItem("access-token");
  if (token) {
    return axios.post(config.url + cardEndpoint + 'count', data, {
      headers: { Authorization: "Bearer " + token },
    });
  } else {
    return axios.post(config.url + cardEndpoint, data);
  }
};

export const getAllCardsRequestMap = (data) => {
  const token = myStorage.getItem("access-token");
  if (token) {
    return axios.post(config.url + cardEndpoint + "map", data, {
      headers: { Authorization: "Bearer " + token },
    });
  } else {
    return axios.post(config.url + cardEndpoint, data);
  }

};


//получить карточку по id (options - raw) для update (не доступна для юзера)
export const getCardById = (cardId) => {
  const token = myStorage.getItem("access-token");
  if (!token) return;
  return axios.get(config.url + cardEndpoint + cardId, {
    params: { raw: true },
    headers: { Authorization: "Bearer " + token },
  });
};

// обновить данные в карточке
export const updateCardRequest = (data) => {
  const token = myStorage.getItem("access-token");
  if (!token) return;
  return axios.put(config.url + cardEndpoint, data, {
    headers: {
      "Content-Type": "application/json",
      Authorization: "Bearer " + token,
    },
  });
};

// удаление карточки
export const deleteCardRequest = (cardId) => {
  const token = myStorage.getItem("access-token");
  if (!token) return;
  return axios.delete(config.url + cardEndpoint + cardId, {
    headers: { Authorization: "Bearer " + token },
  });
};

// получение sample карточки (поля которые должны быть в карточке в зависимости от типа)
export const getCardSample = (cardType) => {
  const token = myStorage.getItem("access-token");
  if (!token) return;
  return axios.get(config.url + cardEndpoint + "sample", {
    params: { type: cardType },
    headers: { Authorization: "Bearer " + token },
  });
};

// импорт карточек
export const importCardRequest = (data) => {
  const token = myStorage.getItem("access-token");
  if (!token) return;
  return axios.post(config.url + cardEndpoint + "import", data, {
    headers: { Authorization: "Bearer " + token },
  });
};

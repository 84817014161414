import React from "react";
import Header from "../../Components/Main/Header/Header";
import Footer from "../../Components/Main/Footer/Footer";
import AppealForm from "../../Components/Generic/AppealForm/AppealForm";

import AboutCompImg from "../../static/images/test-aboutComp.png";

import "./Vacancy.scss";

const Vacancy = (props) => {
  const vacancy = props.location.state;

  return (
    <div className="page-container">
      <Header />
      <div className="vacancy-wrapper">
        <div>
          <img className="full-vacancy-img" alt="#" src={AboutCompImg}></img>
        </div>
        <div className="full-vacancy">
          <h2 className="full-vacancy__header">{vacancy.name}</h2>
          <p className="full-vacancy__text">{vacancy.text}</p>
        </div>
      </div>
      <div className="vacancy-wrapper vacancy-wrapper--medium">
        <div className="vacancy-form">
          <h2 className="vacancy-form__header">
            Хочешь стать частью команды? Заполняй!
          </h2>
          <AppealForm
            header={"Связаться с нами"}
            textarea={"Комментарий"}
            styles={{ minHeight: "60px" }}
            uploadFile={true}
            file={true}
          />
        </div>
        <div className="advantages">
          <h2 className="advantages__header">Почему мы лучшие</h2>
          <ol className="advantages__list">
            <li className="list__item">
              Lorem ipsum dolor sit amet, consectetur adipiscing elit,
            </li>
            <li className="list__item">
              Sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.
            </li>
            <li className="list__item">
              Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris
              nisi ut aliquip ex ea commodo consequat.
            </li>
            <li className="list__item">
              Duis aute irure dolor in reprehenderit in voluptate velit esse
              cillum dolore eu fugiat nulla pariatur.
            </li>
            <li className="list__item">
              Excepteur sint occaecat cupidatat non proident, sunt in culpa qui
              officia deserunt mollit anim id est laborum.
            </li>
          </ol>
        </div>
      </div>

      <Footer />
    </div>
  );
};

export default Vacancy;

import React from "react";
import { Link } from "react-router-dom";
import SVG from "react-inlinesvg";

import IconLogoSVG from "../../../static/images/logo.svg";

import "./Logo.scss";

function Logo(props) {
  return (
    <div className="logo" style={props.logoStyle}>
      <Link to="/">
        <SVG className="logo__svg" src={IconLogoSVG} />
      </Link>
      <p className="logo__tel" style={props.style}>
        +(380) 48-736-80-70
      </p>
    </div>
  );
}

export default Logo;

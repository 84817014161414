import React from "react";
import Slider from "react-slick";

import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

import "./SliderComp.scss";

function PrevArrow(props) {
  const { onClick } = props;
  return <div className="arrow-prev" onClick={onClick} />;
}

function NextArrow(props) {
  const { onClick } = props;
  return <div className="arrow-next" onClick={onClick} />;
}

const SliderComp = (props) => {
  const settings = {
    dots: props.dots,
    infinite: true,
    speed: 500,
    slidesToShow: props.slidesToShow,
    slidesToScroll: 1,
    prevArrow: <PrevArrow />,
    nextArrow: <NextArrow />,
    responsive: [
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 1,
        },
      },
    ],
  };

  return (
    <div>
      <Slider style={props.style} {...settings}>
        {props.components}
      </Slider>
    </div>
  );
};

export default SliderComp;

import React, { useState } from "react";
import { NavLink } from "react-router-dom";
import SVG from "react-inlinesvg";

import LargeMenu from "./LargeMenu";

import useGlobal from "../../../store";
import useWindowWidth from "../../../CustomHook/useWindowWidth";

import IconUserSVG from "../../../static/images/user.svg";
import IconSandwich from "../../../static/images/sandwich_icon.svg";
import IconSandwichOpened from "../../../static/images/sandwich_icon-opened.svg";

import "./Menu.scss";

const Menu = () => {
  // eslint-disable-next-line
  const [globalState, globalActions] = useGlobal();
  const [visible, setVisible] = useState(false);
  const [hover, setHover] = useState(false);
  const [active, setActive] = useState(false);
  const windowWidth = useWindowWidth();

  const toggleMenuVisibility = () => {
    setVisible(!visible);
  };

  return (
    <div className="nav-wrapper">
      <nav
        className="nav"
        style={
          !visible && windowWidth < 992
            ? { visibility: "hidden" }
            : visible && windowWidth < 992
            ? { visibility: "visible" }
            : { visibility: "visible" }
        }
      >
        <ul className="menu">
          <li
            className="menu__item"
            onMouseEnter={() => (windowWidth > 992 ? setHover("flats") : null)}
            onMouseLeave={() => (windowWidth > 992 ? setHover(false) : null)}
          >
            {windowWidth > 992 ? (
              <NavLink to="/flats" className="menu__link">
                Квартиры
              </NavLink>
            ) : (
              <span
                className="menu__link"
                onClick={() => setActive(active ? false : "flats")}
              >
                Квартиры
              </span>
            )}

            <LargeMenu
              hover={windowWidth > 992 ? hover : null}
              type={"flats"}
              active={active}
            />
          </li>

          <li
            className="menu__item"
            onMouseEnter={() =>
              windowWidth > 992 ? setHover("primary") : null
            }
            onMouseLeave={() => (windowWidth > 992 ? setHover(false) : null)}
          >
            {windowWidth > 992 ? (
              <NavLink to="/primary" className="menu__link">
                Новострои
              </NavLink>
            ) : (
              <span
                className="menu__link"
                onClick={() => setActive(active ? false : "primary")}
              >
                Новострои
              </span>
            )}

            <LargeMenu
              hover={windowWidth > 992 ? hover : null}
              type={"primary"}
              active={active}
            />
          </li>

          <li
            className="menu__item menu__item--houses"
            onMouseEnter={() => (windowWidth > 992 ? setHover("house") : null)}
            onMouseLeave={() => (windowWidth > 992 ? setHover(false) : null)}
          >
            {windowWidth > 992 ? (
              <NavLink to="/houses" className="menu__link">
                Дома <br></br> и участки
              </NavLink>
            ) : (
              <span
                className="menu__link"
                onClick={() => setActive(active ? false : "house")}
              >
                Дома <br></br> и участки
              </span>
            )}

            <LargeMenu
              hover={windowWidth > 992 ? hover : null}
              type={"house"}
              active={active}
            />
          </li>

          <li
            className="menu__item menu__item--prop"
            onMouseEnter={() =>
              windowWidth > 992 ? setHover("commercial") : null
            }
            onMouseLeave={() => (windowWidth > 992 ? setHover(false) : null)}
          >
            {windowWidth > 992 ? (
              <NavLink to="/commercial" className="menu__link">
                Коммерческая <br></br> недвижимость
              </NavLink>
            ) : (
              <span
                className="menu__link"
                onClick={() => setActive(active ? false : "commercial")}
              >
                Коммерческая <br></br> недвижимость
              </span>
            )}

            <LargeMenu
              hover={windowWidth > 992 ? hover : null}
              type={"commercial"}
              active={active}
            />
          </li>

          <li className="menu__item">
            <NavLink to="/company" className="menu__link">
              О компании
            </NavLink>
          </li>

          <li className="menu__item">
            <NavLink to="/contacts" className="menu__link">
              Контакты
            </NavLink>
          </li>

          <li className="menu__item menu__item--login">
            <NavLink
              className="menu__link menu__link--login"
              to={globalState.user.isLoggedIn ? "/account" : "/login"}
            >
              <SVG className="menu__link__svg" src={IconUserSVG} />
            </NavLink>
          </li>
        </ul>
      </nav>
      {visible ? (
        <SVG
          className="sandwich__svg"
          src={IconSandwichOpened}
          onClick={toggleMenuVisibility}
          style={
            windowWidth > 992 || !visible
              ? { display: "none" }
              : { display: "block" }
          }
        />
      ) : (
        <SVG
          className="sandwich__svg"
          src={IconSandwich}
          onClick={toggleMenuVisibility}
          style={windowWidth > 992 ? { display: "none" } : { display: "block" }}
        />
      )}
    </div>
  );
};

export default Menu;

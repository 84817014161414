import CustomInput from "../CustomInputs/TextInput";
import CustomTextArea from "../CustomInputs/Textarea";

export const VacancyData = {
  nameGroup: [
    {
      label: null,
      placeholder: "Название",
      name: "name",
      type: "text",
      Component: CustomInput,
    },
  ],

  textGroup: [
    {
      label: "Текст",
      placeholder: "Текст",
      name: "text",
      type: "text",
      Component: CustomTextArea,
    },
  ],
};

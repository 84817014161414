import React, { useState, useEffect } from "react";

import Header from "../../Components/Main/Header/Header";
import Footer from "../../Components/Main/Footer/Footer";
import SliderComp from "../../Components/Main/SliderComp/SliderComp";
import VacancySlides from "../../Components/Generic/VacancySlides/VacancySlides";

import { getAllVacanciesRequest } from "../../API/vacanciesAction";

import AboutCompImg1 from "../../static/images/480_1.jpg";
import AboutCompImg2 from "../../static/images/480_2.jpg";

import "./AboutComp.scss";

const sliderVacancySlyles = {
  height: "270px",
};

const AboutComp = () => {
  // eslint-disable-next-line
  const [loadingStarted, toggleLoadingStart] = useState(false);
  const [allVacancies, setAllVacancies] = useState([]);
  // eslint-disable-next-line
  const [requestObject, setRequestObject] = useState({
    list: {
      amount: 10,
    },
    public: { active: true },
  });

  useEffect(() => {
    toggleLoadingStart(true);
    getAllVacanciesRequest(requestObject)
      .then((response) => {
        const data = response.data;
        const vacancies = data.map((item, index) => {
          return <VacancySlides key={index} vacancy={item} />;
        });
        setAllVacancies(vacancies);
      })
      .then(() => {
        toggleLoadingStart(false);
      });
    // eslint-disable-next-line
  }, []);

  return (
    <div className="page-container">
      <Header />
      <div className="content-container">
        <div className="company-wrapper">
          <div className="about-company">
            <h2 className="about-company__header">О нас</h2>
            <div className="about-company__text">
              <p><strong>Мы специалисты которые:</strong></p>
              <p><strong>Пристально наблюдают за ценами и спросом</strong></p>
              <p>Мы поможем сделать оценку недвижимости и подобрать самый оптимальный вариант для продажи или покупки.</p>
              <p><strong>Предоставляют лучшую рекламу</strong></p>
              <p>Ваше предложение будет размещено в нашей внутренней обширной клиентской базе, и оно появится на всех интернет ресурсах АН Парламент. Наши специалисты позаботятся о продвижении вашей недвижимости среди широкой аудитории покупателей.</p>
              <p><strong>Соблюдаем формальности</strong></p>
              <p>Мы поможем вам разобраться во всех наиболее важных формальностях сделки, контролируя подписание предварительного договора или организацию нотариального акта.</p>
              <p><strong>Работаем «под ключ»</strong></p>
              <p>Мы присутствуем при передаче ключей — самом приятном моменте процесса продажи недвижимости. Мы готовим необходимые документы и помогаем в расчете, пока вы наслаждаетесь завершением успешной сделки.</p>
            </div>
          </div>
          <div>
            <img className="img" alt="#" src={AboutCompImg1}></img>
          </div>
        </div>
        <div className="company-wrapper">
          <div className="about-company">
            <h2 className="about-company__header">Школа риелторов</h2>
            <div className="about-company__text">
              <p>Наша команда собрана из лучших специалистов сферы. Даже если вы не имеете опыта работы, но горите желанием погрузится в этот увлекательный и обширный мир недвижимости, то вы можете попробовать свои силы у нас.</p>
              <p>На базе АН Парламент открыта школа риелторов которая помогает освоить все азы профессии. Основой для успеха будет ваше желание и способности освоить накопленный опыт и знания команды, которая всегда поможет в нелегком пути обучения.</p>
            </div>
          </div>
          <div>
            <img className="img" alt="#" src={AboutCompImg2}></img>
          </div>
        </div>
        <div className="container">
          <div className="vacancy">
            <h2 className="vacancy__header">Вакансии</h2>
            <SliderComp
              dots={false}
              slidesToShow={2}
              components={allVacancies}
              style={sliderVacancySlyles}
            />
          </div>
        </div>
      </div>
      <Footer />
    </div>
  );
};

export default AboutComp;

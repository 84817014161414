import axios from "axios";

import config from "./config";

const filtersEndpoint = "filters/";
const rangesEndpoint = "ranges/";

// получение фильтра по названию
export const getFilterByNameRequest = (name) => {
  return axios.get(config.url + filtersEndpoint + name);
};

// получение списка микрорайонов
export const getSubDistrictsList = (id) => {
  return axios.get(config.url + "districts/" + id);
};

// получение списка районов
export const getDistrictsList = () => {
  return axios.get(config.url + "districts");
};

// получение списка улиц
export const getStreetsList = (districtId, subDistrictId) => {
  // eslint-disable-next-line
  if(subDistrictId) {
    return axios.get(config.url + "streets?" + "district=" + districtId + "&sub=" + subDistrictId);
  }
  return axios.get(config.url + "streets?" + "district=" + districtId);
};

// получение списка улиц комплексов
export const getStreetsComplexList = (districtId, subDistrictId) => {
  // eslint-disable-next-line
  return axios.get(config.url + "streets/complex?" + "district=" + districtId + "&sub=" + subDistrictId);
};

// получение range по названию
export const getRangeByNameRequest = (name, type) => {
  // eslint-disable-next-line
  return axios.get(config.url + rangesEndpoint + name + (type ? "?" + "type=" + type : ""));
};

import React, { useState, useEffect } from "react";
import Select from "react-select";

import Button from "../../../Components/Generic/Button/Button";
import Header from "../../AdminGeneric/Header/AdminHeader";
import Menu from "../../AdminGeneric/Menu/AdminMenu";
import RowHeader from "../../AdminGeneric/Table/RowHeader";
import Row from "../../AdminGeneric/Table/Row";

import {
  getAllUsersRequest,
  updateUserRequest,
} from "../../../API/userActions";

import { customStyles } from "../SelectCustomStyles";

const styles = {
  table: {
    marginTop: "64px",
  },
  rowStyle: {
    marginRight: "3%",
  },
};

const userStatusVariations = [
  {
    role: "admin",
    text: "Админ",
    style: { background: "#716F6F", display: "flex", justifyContent: "center" },
  },

  {
    role: "partner",
    text: "Партнер",
    style: { background: "#D97D34", display: "flex", justifyContent: "center" },
  },

  {
    role: "manager",
    text: "Менеджер",
    style: { background: "#36AF47", display: "flex", justifyContent: "center" },
  },

  {
    role: "user",
    text: "Пользователь",
    style: { background: "#2F80ED", display: "flex", justifyContent: "center" },
  },
];

const accessOptions = [
  { value: "user", label: "Пользователь" },
  { value: "admin", label: "Админ" },
  { value: "partner", label: "Партнер" },
  { value: "manager", label: "Менеджер" },
];

const Users = () => {
  const [loadingStarted, toggleLoadingStart] = useState(false);
  const [allUsers, setAllUsers] = useState([]);
  const [requestObject, setRequestObject] = useState({
    list: {
      sort: {
        by: undefined,
        type: undefined,
      },
    },
    public: {},
  });

  useEffect(() => {
    toggleLoadingStart(true);
    getAllUsersRequest(requestObject)
      .then((response) => {
        setAllUsers(response.data);
      })
      .then(() => {
        toggleLoadingStart(false);
      });
  }, [requestObject]);

  const handleSelected = (filterGroup, filterName, filterNewValue) => {
    const newObject = JSON.parse(JSON.stringify(requestObject));
    newObject[filterGroup][filterName] = filterNewValue;
    setRequestObject(newObject);
  };

  const selectedAccess = (user, index, event) => {
    // Создаем нового юзера с ролью выбранной в селекте
    const newUser = {
      ...user,
      role: event.value,
    };
    // Вызываем запрос на обновление статусаб если все ок -
    //  перезаписываем юзера с новой ролью вместо предыдущего
    updateUserRequest(user._id, newUser)
      .then(() => {
        const newUsersArr = JSON.parse(JSON.stringify(allUsers)); //клонируем объект и разрываем связь с предыдущим
        newUsersArr[index] = newUser;
        setAllUsers(newUsersArr);
      })
      .catch((err) => console.log(err));
  };

  const handleUserDelete = (index) => {
    const newArr = allUsers.filter((item, userindex) => userindex !== index);
    setAllUsers(newArr);
  };

  const determineStatus = (role) => {
     // eslint-disable-next-line
    return userStatusVariations.map((item, index) => {
      if (role === item.role) {
        return (
          <Button
            className="adminBtn--condition"
            key={index}
            style={item.style}
            text={item.text}
          />
        );
      }
    });
  };

  return (
    <div>
      <Header />
      <div className="admin-wrapper">
        <Menu />
        <div className="admin-content">
          <RowHeader
            style={styles.table}
            pageType={"user"}
            state={requestObject}
            setState={setRequestObject}
          />
          {loadingStarted ? (
            <p className="loader-admin">Loading...</p>
          ) : (
            allUsers.map(function (item, index) {
              const role = item.role;
              return (
                <Row
                  pageType={"user"}
                  key={index}
                  index={index}
                  id={item._id}
                  publicId={item.publicId}
                  name={item.name}
                  date={item.createdDate}
                  handleDelete={handleUserDelete}
                >
                  <p className="filter__item">{item.phone}</p>
                  {determineStatus(role)}
                  <p></p>
                  <Select
                    className="adminBtn--action"
                    placeholder={"Доступ"}
                    styles={customStyles}
                    onChange={(event) => selectedAccess(item, index, event)}
                    options={accessOptions}
                  />
                </Row>
              );
            })
          )}
          <Button
            className="adminBtn--delete"
            style={{
              background: "#7F8FA4",
              marginRight: 0,
              marginTop: "20px",
              width: "106px",
            }}
            text={"Показать еще"}
            onClick={() =>
              handleSelected("list", "amount", requestObject.list.amount + 10)
            }
          />
        </div>
      </div>
    </div>
  );
};

export default Users;

import React from "react";
import SVG from "react-inlinesvg";

import Header from "../../Components/Main/Header/Header";
import MapContainer from "../../Components/Main/Map/MapContainer";
import Feedback from "../../Components/Generic/Feedback/Feedback";
import Footer from "../../Components/Main/Footer/Footer";

import IconFacebookSVG from "../../static/images/socials/facebook_icon.svg";
import IconInstaSVG from "../../static/images/socials/insta_icon.svg";
import IconTelegaSVG from "../../static/images/socials/telega_icon.svg";
import IconYouTubeSVG from "../../static/images/socials/youtube_icon.svg";
import IconScSVG from "../../static/images/socials/sc_icon.svg";

import config from "../../API/config";

import "./Contacts.scss";

const Contacts = (props) => {
  const page = props.location.pathname;
  const address = "Одесса, Бочарова 62";

  return (
    <div className="page-container">
      <Header />
      <div className="content-container">
        <div className="contacts-wrapper">
          <div className="contacts">
            <h2 className="contacts__header">Связаться с нами</h2>
            <p className="contacts__text">
              Вы можете связаться с нами удобным для вас способом. Мы оперативно отвечаем по всем каналам связи будь то переписка в социальных сетях или звонок в пол первого ночи =)
            </p>
            <div className="contacts__numbers">
              <p className="tel">+(380) 93-232-39-90</p>
              <p className="tel">+(380) 98-250-71-81</p>
              <p className="tel">+(380) 48-736-80-70</p>
            </div>
            <div className="contacts__social">
              <SVG className="social__icon" src={IconFacebookSVG} />
              <SVG className="social__icon" src={IconInstaSVG} />
              <SVG className="social__icon" src={IconTelegaSVG} />
              <SVG className="social__icon" src={IconYouTubeSVG} />
              <SVG className="social__icon" src={IconScSVG} />
            </div>
          </div>
          <div>
            <MapContainer
              address={[{ pathname: page, name: null, address: address }]}
            />
          </div>
        </div>
      </div>
      <Feedback currentPage={config.urlForPage + "/contacts"} />
      <Footer />
    </div>
  );
};

export default Contacts;

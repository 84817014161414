import React, { useState, useEffect } from "react";
import { useHistory } from "react-router-dom";
import SVG from "react-inlinesvg";
import { useToasts } from "react-toast-notifications";

import useGlobal from "../../../store";

import Button from "../../Generic/Button/Button";
import IconShowPassSVG from "../../../static/images/iconClick_actions/show_password.svg";

import "./Form.scss";

import { changeUserPersonalData } from "../../../API/userActions";
import validate from "../../../Validation/FormValidationRules";

const pageFormData = {
  login: [
    {
      label: "Номер телефона",
      name: "phone",
      type: "text",
      placeholder: "+380",
      icon: null,
    },
    {
      label: "Пароль",
      name: "password",
      type: "password",
      icon: IconShowPassSVG,
    },
  ],
  registration: [
    {
      label: "Имя",
      name: "name",
      type: "text",
      icon: null,
    },
    {
      label: "Электронная почта",
      name: "email",
      type: "email",
      placeholder: "email@gmail.com",
      icon: null,
    },
    {
      label: "Номер телефона",
      name: "phone",
      type: "text",
      placeholder: "+380",
      icon: null,
    },
    {
      label: "Пароль",
      name: "password",
      type: "password",
      icon: IconShowPassSVG,
    },
  ],
  personalAccount: [
    {
      label: "Имя",
      name: "name",
      type: "text",
      icon: null,
    },
    {
      label: "Электронная почта",
      name: "email",
      type: "text",
      icon: null,
    },
    {
      label: "Номер телефона",
      name: "phone",
      type: "text",
      placeholder: "+380",
      icon: null,
    },
    {
      label: "Пароль",
      naming: "password",
      type: "password",
      icon: IconShowPassSVG,
    },
  ],
};

const style = {
  errorMessage: {
    color: "red",
    fontSize: "11px",
    marginBottom: "10px",
  },
};

const Form = (props) => {
  const {
    type,
    btnText,
    linkText,
    formHeader,
    submitToGlobal,
    goToPage,
    formValues,
    btnStyle,
  } = props;
  const history = useHistory();
  const { addToast } = useToasts();
  // eslint-disable-next-line
  const [globalState, globalActions] = useGlobal();
  const [showPassword, setShowPassword] = useState(true);
  const [formData, setFormData] = useState({});
  const [errors, setErrors] = useState({});
  const [responseEror, setResponseError] = useState({
    loginPassword: false,
    connect: false,
    responseData: false,
  });

  const errorMsgLoginPassword = responseEror.loginPassword ? (
    <p style={style.errorMessage}>{responseEror.loginPassword}</p>
  ) : null;
  const errorMsgConnect = responseEror.connect ? (
    <p style={style.errorMessage}>{responseEror.connect}</p>
  ) : null;
  const errorMsgResponse = responseEror.responseData ? (
    <p style={responseEror.errorMessage}>{responseEror.responseData}</p>
  ) : null;

  useEffect(() => {
    if (formValues) setFormData(formValues);
  }, [formValues]);

  const goToPageHandle = () => {
    if (goToPage === "goBack") {
      history.goBack();
    } else if (goToPage === "goRegister") {
      history.push("/registration");
    } else if (goToPage === "cancelInput") {
      window.location.reload();
    }
  };

  const changeFormData = (name, value) => {
    setFormData({
      ...formData,
      [name]: value,
    });
  };

  // тогл показа пароля при клике на кнопку
  const onShowPassword = () => {
    setShowPassword(!showPassword);
  };

  const onLogout = () => {
    globalActions.user.logoutUser();
  };

  // рендерим поля формы
  const renderFormData = () => {
    return pageFormData[type].map((item, index) => {
      return (
        <div key={index}>
          <div className="form__item item">
            <label className="item__label">{item.label}</label>
            <input
              className="item__field "
              name={item.name}
              required
              type={
                item.type === "password" && showPassword ? "password" : "text"
              }
              value={formData[item.name] ? formData[item.name] : ""}
              placeholder={item.placeholder}
              onChange={(event) => {
                changeFormData(item.name, event.currentTarget.value);
              }}
            />
            {item.icon ? (
              <SVG
                className="field__icon"
                style={{ cursor: "pointer" }}
                src={item.icon}
                onClick={onShowPassword}
              />
            ) : null}
          </div>
          {errors[item.name] && (
            <p style={style.errorMessage}>{errors[item.name]}</p>
          )}
        </div>
      );
    });
  };

  // функция отправки формы
  const handleFormSubmit = (data, event) => {
    event.preventDefault();
    if (submitToGlobal === "loginUser") {
      setErrors(validate(data));
      globalActions.user.loginUser(data, responseEror, setResponseError);
    } else if (submitToGlobal === "registerUser") {
      setErrors(validate(data));
      globalActions.user.registerUser(data, responseEror, setResponseError);
    } else if (submitToGlobal === "changeUserData") {
      // изменение данных пользователя в форме в персон кабинете
      changeUserPersonalData(formData.id, data)
        .then((response) => {
          console.log(response);
          addToast("Данные успешно изменены", {
            appearance: "success",
            autoDismiss: true,
          });
        })
        .catch((error) => {
          console.log(error);
          addToast("Изменить данные не удалось", {
            appearance: "error",
            autoDismiss: true,
          });
        });
    }
  };

  return (
    <form
      noValidate
      className="form"
      onSubmit={(event) => handleFormSubmit(formData, event)}
    >
      <h2 className="form__header">{formHeader}</h2>
      {renderFormData()}
      {errorMsgLoginPassword}
      {errorMsgConnect}
      {errorMsgResponse}
      <div className="account__btns">
        <Button
          className="btn-log"
          type="submit"
          text={btnText}
          style={btnStyle}
        />
        <Button
          className="btn-reg"
          type="button"
          text={linkText}
          onClick={() => goToPageHandle()}
          style={btnStyle}
        />
        {type === "personalAccount" ? (
          <Button
            className="btn-reg"
            type="button"
            onClick={onLogout}
            text={"Выйти"}
            style={btnStyle}
          />
        ) : null}
      </div>
    </form>
  );
};

export default Form;

import axios from "axios";

import config from "./config";

const usersEndpoint = "users/";

const myStorage = window.localStorage;

// запрос на регистрацию
export const registerRequest = (data) => {
  return axios.post(config.url + usersEndpoint + "register", data);
};

// запрос на логин
export const loginRequest = (data) => {
  return axios.post(config.url + usersEndpoint + "authenticate", data);
};

// получение данных пользователя в личном кабинете
export const getUserPersonalData = () => {
  const token = myStorage.getItem("access-token");
  if (!token) return;
  return axios.get(config.url + usersEndpoint + "self", {
    headers: { Authorization: "Bearer " + token },
  });
};

// перепроверка данных текущего пользователя - совпадает ли токен и можно ли ему в админку
export const getSelfData = (token) => {
  return axios.get(config.url + usersEndpoint + "self", {
    headers: { Authorization: "Bearer " + token },
  });
};

// изменение данных пользователя в личном кабинете
export const changeUserPersonalData = (id, formValues) => {
  const token = myStorage.getItem("access-token");
  if (!token) return;
  return axios.put(config.url + usersEndpoint + "id/" + id, formValues, {
    headers: { Authorization: "Bearer " + token },
  });
};

//получение списка всех юзеров
export const getAllUsersRequest = (data) => {
  const token = myStorage.getItem("access-token");
  if (!token) return;
  return axios.post(config.url + usersEndpoint, data, {
    headers: { Authorization: "Bearer " + token },
  });
};
//удаление юзера
export const deleteUserRequest = (id) => {
  const token = myStorage.getItem("access-token");
  if (!token) return;
  return axios.delete(config.url + usersEndpoint + "id/" + id, {
    headers: { Authorization: "Bearer " + token },
  });
};
//обновление роли доступа юзера
export const updateUserRequest = (userId, data) => {
  const token = myStorage.getItem("access-token");
  if (!token) return;
  return axios.put(config.url + usersEndpoint + "id/" + userId, data, {
    headers: { Authorization: "Bearer " + token },
  });
};

// добавление пользователем карточек в избранное (отображается в личном кабинете)
export const addToFavouritesRequest = (data) => {
  const token = myStorage.getItem("access-token");
  if (!token) return;
  return axios.post(config.url + usersEndpoint + "favorites/", data, {
    headers: { Authorization: "Bearer " + token },
  });
};

// удаление объекта избранного из личного кабинета
export const deleteUserFavoritesRequest = (data) => {
  const token = myStorage.getItem("access-token");
  if (!token) return;
  return axios.delete(config.url + usersEndpoint + "favorites/", {
    headers: { Authorization: "Bearer " + token },
    params: { id: data },
  });
};

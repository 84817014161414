import React, { useState, useEffect } from "react";
import { useHistory } from "react-router-dom";
import Breadcrumbs from "../../Breadcrumbs/Breadcrumbs";
import { useToasts } from "react-toast-notifications";

import Header from "../AdminGeneric/Header/AdminHeader";
import Menu from "../AdminGeneric/Menu/AdminMenu";
import Buttons from "../AdminGeneric/Buttons/Buttons";

import { ComplexData } from "./Data/ComplexData";

import {
  getComplexById,
  getComplexSample,
  updateComplexRequest,
  createComplexRequest,
} from "../../API/complexesActions";

const {
  nameGroup,
  complexGroup,
  addressGroup,
  priceGroup,
  techDataGroup,
  plansImagesGroup,
  propertiesGroup,
  descriptionGroup,
  readinessDataGroup,
  readinessDescriptionGroup,
  readinessImagesGroup,
} = ComplexData;

const styles = {
  marginBottom: "15px",
};

const AddOrEditComplex = (props) => {
  const [formData, setFormData] = useState();
  const [id, setId] = useState();
  const [type, setType] = useState();
  const [requestFunction, setRequestFunction] = useState();
  const [submitFunction, setSubmitFunction] = useState();
  const [receivedData, toggleReceivedData] = useState(false);
  const [loadingStarted, toggleLoadingStart] = useState(false);
  const { addToast } = useToasts();
  const history = useHistory();

  useState(() => {
    setId(props.location.state.objectId);
    setType(props.location.state.objectPublicType);
    setRequestFunction(props.location.state.requestFunction);
    setSubmitFunction(props.location.state.submitFunction);
  });

  const goToComplex = () => {
    history.push("/admin/complex");
  };

  const changeFormData = (name, value) => {
    setFormData({
      ...formData,
      [name]: value,
    });
  };

  useEffect(() => {
    toggleLoadingStart(true);
    if (requestFunction === "getComplexById") {
      getComplexById(id)
        .then((response) => {
          setFormData(response.data);
          toggleReceivedData(true);
        })
        .then(() => {
          toggleLoadingStart(false);
        });
    } else if (requestFunction === "getComplexSample") {
      getComplexSample()
        .then((response) => {
          setFormData(response.data);

          toggleReceivedData(true);
        })
        .then(() => {
          toggleLoadingStart(false);
        });
    }
    // eslint-disable-next-line
  }, []);

  // функция для рендера полей формы (в том числе и селектов)
  const renderInputForm = (array) => {
    return array.map(
      (
        { group, Component, label, name, parent, placeholder, type, request },
        index
      ) => {
        // проверяем наличие группы - если есть, берем значение которое лежит в группе, либо сразу значение formData
        const isExist = group ? formData[group][name] : formData[name];
        return isExist !== undefined ? (
          <div className="admin-form__item item" key={index}>
            <label className="admin-item__label">{label}</label>
            <Component
              key={index}
              parent={formData[parent]}
              type={type}
              placeholder={placeholder}
              group={group}
              name={name}
              checked={formData[name]}
              value={group ? formData[group] : formData[name]}
              // если есть группа - возьми то что записано по этой группе в formData если нет - возьми значение
              changeValue={changeFormData}
              request={request}
            />
          </div>
        ) : null;
      }
    );
  };

  // функция для рендера инпута тип файл (туда попадают картинки)
  const renderImages = (array) => {
    return array.map(
      (
        {
          Component,
          type,
          label,
          name,
          group,
          multiple,
          header,
          text,
          returnas,
        },
        index
      ) => {
        const isExist = group ? formData[group][name] : formData[name];
        return isExist !== undefined ? (
          <div className="admin-form__item item" key={index}>
            <label className="admin-item__label">{label}</label>
            <Component
              name={name}
              type={type}
              group={group}
              text={text}
              header={header}
              id={name}
              multiple={multiple}
              returnas={returnas}
              value={group ? formData[group] : formData[name]}
              changeValue={changeFormData}
            />
          </div>
        ) : null;
      }
    );
  };

  // функция отправки формы
  const handleFormSubmit = (data, event) => {
    event.preventDefault();
    if (submitFunction === "updateComplexRequest") {
      updateComplexRequest(data)
        .then((response) => {
          addToast("Комплекс успешно изменен", {
            appearance: "success",
            autoDismiss: true,
          });
          return new Promise((resolve) => {
            setTimeout(() => {
              resolve(() => goToComplex());
            }, 1000);
          });
        })
        .then((goToComplex) => {
          goToComplex();
        })
        .catch((error) => {
          addToast(
            "Изменить комплекс не удалось. Проверьте правильность заполнения полей формы. Они не должны быть пустыми.",
            {
              appearance: "error",
              autoDismiss: true,
            }
          );
        });
    } else if (submitFunction === "createComplexRequest") {
      createComplexRequest(data)
        .then((response) => {
          addToast("Комплекс успешно добавлен", {
            appearance: "success",
            autoDismiss: true,
          });
          return new Promise((resolve) => {
            setTimeout(() => {
              resolve(() => goToComplex());
            }, 1000);
          });
        })
        .then((goToComplex) => {
          goToComplex();
        })
        .catch((error) => {
          addToast(
            "Добавить комплекс не удалось. Проверьте правильность заполнения полей формы. Они не должны быть пустыми.",
            {
              appearance: "error",
              autoDismiss: true,
            }
          );
        });
    }
  };

  return (
    <div>
      <Header />
      <div className="admin-wrapper">
        <Menu />
        <div className="admin-content">
          <Breadcrumbs />
          {loadingStarted ? (
            <p className="loader-admin">Loading...</p>
          ) : (
            <form
              className="admin-form"
              onSubmit={(event) => handleFormSubmit(formData, event)}
            >
              <div className="admin-item__wrapper">
                <div className="custom-select">
                  <div className="custom-select__wrapper">
                    <p
                      className="custom-select__label"
                      style={{ width: "100%" }}
                    >
                      {type}
                    </p>
                  </div>
                </div>
              </div>
              <div className="admin-item__wrapper">
                {receivedData ? renderInputForm(nameGroup) : null}
              </div>
              <div className="admin-item__wrapper">
                {receivedData ? renderInputForm(complexGroup) : null}
              </div>
              <label className="admin-item__label admin-item__label--prev">
                Адресс
              </label>
              <div className="admin-item__wrapper admin-item__wrapper--custom">
                {receivedData ? renderInputForm(addressGroup) : null}
              </div>
              <div className="admin-item__wrapper">
                {receivedData ? renderInputForm(priceGroup) : null}
              </div>
              <label className="admin-item__label admin-item__label--prev">
                Технические данные
              </label>
              <div className="admin-item__wrapper">
                {receivedData ? renderInputForm(techDataGroup) : null}
              </div>
              <div style={styles}>
                {receivedData ? renderImages(plansImagesGroup) : null}
              </div>
              <label className="admin-item__label admin-item__label--prev">
                Дополнительная информация
              </label>
              <div className="admin-item__wrapper">
                {receivedData ? renderInputForm(propertiesGroup) : null}
              </div>
              <div style={styles}>
                {receivedData ? renderInputForm(descriptionGroup) : null}
              </div>
              <div style={styles}>
                {receivedData ? renderImages(readinessImagesGroup) : null}
              </div>
              <div className="admin-item__wrapper">
                {receivedData ? renderInputForm(readinessDataGroup) : null}
              </div>
              <div style={styles}>
                {receivedData
                  ? renderInputForm(readinessDescriptionGroup)
                  : null}
              </div>
              <Buttons
                textAdd={
                  requestFunction === "getComplexById" ? "Изменить" : "Добавить"
                }
                cancelInput={goToComplex}
                disabled={null}
              />
            </form>
          )}
        </div>
      </div>
    </div>
  );
};
export default AddOrEditComplex;

import React, { useEffect } from "react";
import { Switch, Route, useHistory } from "react-router-dom";
import useGlobal from "./store";

import MainPage from "./Pages/MainPage/MainPage";
import CardsAndFilters from "./Pages/CardsAndFilters/CardsAndFilters";
import MapAndFilters from "./Pages/Map/Map";
import DetailedCard from "./Pages/CardsAndFilters/DetailedCard";
import DetailedComplexCard from "./Pages/CardsAndFilters/DetailedComplexCard";
import AboutComp from "./Pages/AboutComp/AboutComp";
import Vacancy from "./Pages/AboutComp/Vacancy";
import Contacts from "./Pages/Contacts/Contacts";
import Login from "./Pages/Login/Login";
import Registration from "./Pages/Registration/Registration";
import Account from "./Pages/PersonalAccout/PersonalAccout";
import Objects from "./Admin/Pages/Objects/Objects";
import ImportObjects from "./Admin/Pages/Objects/ImportObjects";
import AddObject from "./Admin/Pages/Objects/AddObject";
import AddOrEditObjectType from "./Admin/Forms/AddOrEditObjectType";
import Complex from "./Admin/Pages/Complex/Complex";
import ComplexLayouts from "./Admin/Pages/Complex/ComplexLayouts";
import AddComplexLayout from "./Admin/Pages/Complex/AddComplexLayout";
import AddOrEditComplex from "./Admin/Forms/AddOrEditComplex";
import Vacancies from "./Admin/Pages/Vacancies/Vacancies";
import AddOrEditVacancy from "./Admin/Forms/AddOrEditVacancy";
import Users from "./Admin/Pages/Users/Users";
import ProtectedRoute from "./ProtectedRoute";
import NoMatch from "./Pages/NoMatch";
import BreadcrumbsRoute from "./Breadcrumbs/BreadcrumbsRoute";
import ComplexAndFilters from "./Pages/CardsAndFilters/ComplexAndFilters";

import "./styles/style.scss";
import AddComplexPromotion from "./Admin/Pages/Complex/AddComplexPromotion";
import ComplexPromotions from "./Admin/Pages/Complex/ComplexPromotions";

const App = () => {
  const [globalState, globalActions] = useGlobal();
  const { activePage, changePage } = globalState.page;

  const history = useHistory();

  useEffect(() => {
    if (changePage) {
      history.push(activePage);
      globalActions.page.setChangeStatus(false);
    }
    // eslint-disable-next-line
  }, [activePage]);

  useEffect(() => {
    globalActions.user.checkUSerIsAdmin();
    // eslint-disable-next-line
  }, []);

  return (
    <div>
      <Switch>
        {BreadcrumbsRoute.map((route, i) => (
          <Route key={route} {...route} />
        ))}
      </Switch>
      <Switch>
        <Route exact path="/" component={MainPage} />
        <Route
          key="map"
          exact
          path="/map"
          render={(props) => (
            <MapAndFilters
              link={`/flats`}
              state={props.location.state}
            />
          )}
        />
        <Route
          key="flats"
          exact
          path="/flats"
          render={(props) => (
            <CardsAndFilters
              type={"secondary"}
              publicType={"вторичка"}
              link={`/flats`}
              state={props.location.state}
            />
          )}
        />
        <Route path="/flats/:id" component={DetailedCard} />
        <Route
          key="primary"
          exact
          path="/primary"
          render={(props) => (
            <CardsAndFilters
              type={"primary"}
              link={`/primary`}
              builder={props.location.state}
              state={props.location.state}
            />
          )}
        />
        <Route path="/primary/:id" component={DetailedCard} />
        <Route
          key="complex"
          exact
          path="/complex"
          render={(props) => (
            <ComplexAndFilters
              type={"complex"}
              link={`/complex`}
              builder={props.location.state}
              state={props.location.state}
            />
          )}
        />
        <Route path="/complex/:id" component={DetailedComplexCard} />
        <Route
          key="houses"
          exact
          path="/houses"
          render={(props) => (
            <CardsAndFilters type={"house"} link={`/houses`} />
          )}
        />
        <Route path="/houses/:id" component={DetailedCard} />
        <Route
          key="commercial"
          exact
          path="/commercial"
          render={(props) => (
            <CardsAndFilters type={"commercial"} link={`/commercial`} />
          )}
        />
        <Route path="/commercial/:id" component={DetailedCard} />
        <Route exact path="/company" component={AboutComp} />
        <Route path="/company/vacancy" component={Vacancy} />
        <Route path="/contacts" component={Contacts} />
        <Route exact path="/login" component={Login} />
        <Route exact path="/registration" component={Registration} />
        <Route exact path="/account" component={Account} />
        <ProtectedRoute exact path="/admin" component={Objects} />
        <ProtectedRoute path="/admin/add-object" component={AddObject} />
        <ProtectedRoute path="/admin/import-objects" component={ImportObjects} />
        <ProtectedRoute
          path="/admin/add-object-type"
          component={AddOrEditObjectType}
        />
        <ProtectedRoute
          path="/admin/edit-object-type"
          component={AddOrEditObjectType}
        />
        <ProtectedRoute
          path="/admin/add-complex"
          component={AddOrEditComplex}
        />
        <ProtectedRoute
          path="/admin/edit-complex"
          component={AddOrEditComplex}
        />
        <ProtectedRoute exact path="/admin/complex" component={Complex} />
        <Route path="/admin/complex/:id/layouts/add" component={AddComplexLayout} />
        <Route path="/admin/complex/:id/layouts/edit" component={AddComplexLayout} />
        <Route path="/admin/complex/:id/layouts" component={ComplexLayouts} />
        <Route path="/admin/complex/:id/promotions/add" component={AddComplexPromotion} />
        <Route path="/admin/complex/:id/promotions" component={ComplexPromotions} />
        <ProtectedRoute exact path="/admin/users" component={Users} />
        <ProtectedRoute exact path="/admin/vacancies" component={Vacancies} />
        <ProtectedRoute
          exact
          path="/admin/add-vacancy"
          component={AddOrEditVacancy}
        />
        <ProtectedRoute
          exact
          path="/admin/edit-vacancy"
          component={AddOrEditVacancy}
        />

        <Route path="*" component={NoMatch} />
      </Switch>
    </div>
  );
};

export default App;

import React, { useEffect } from "react";
import ReactDOM from "react-dom";
import { BrowserRouter as Router } from "react-router-dom";
import { ToastProvider } from "react-toast-notifications";

import UseScrollToTop from "./CustomHook/useScrollToTop";
import App from "./App";

import useGlobal from "./store";

const GlobalStateWrapper = () => {
    // eslint-disable-next-line
    const [globalState, globalActions] = useGlobal();

    useEffect(() => {
        if (window.localStorage.getItem("access-token")) {
            globalActions.user.userToggleLoginOnLoad();
        }
    }, [globalActions.user]);

    //console.log(process.env.REACT_APP_ENV);

    return (
        <Router>
            <ToastProvider>
                <UseScrollToTop />
                <App />
            </ToastProvider>
        </Router>
    );
};

ReactDOM.render(<GlobalStateWrapper />, document.getElementById("root"));

import React, { useState, useEffect, useRef } from "react";
import SVG from "react-inlinesvg";

import IconArrowSVG from "../../../static/images/dropdown_arrow.svg";
import IconDeleteSVG from "../../../static/images/dropdown_delete.svg";

const Raw = ({ label, value, onSelect, style }) => {
  return (
    // знает какой label и value выбран из замыкания (функция принимает их в кач аргументов)
    <li
      onClick={() => {
        onSelect(label, value);
      }}
    >
      {label}
    </li>
  );
};

const Dropdown = ({
  array,
  placeholder,
  onChange,
  predefinedValue,
  disabled,
  emptyfy,
}) => {
  const defaultSelect = {
    label: placeholder,
    value: undefined,
  };

  const [open, toggleOpen] = useState(false);
  const [selected, setSelected] = useState(defaultSelect);
  const ref = useRef(); // создаем реф, кототрый  добавим на элемент вне которого хотим отлавливать клики

  useEffect(() => {
    if (predefinedValue) {
      if (predefinedValue.value === selected.value) return; // если predefinedValue из пропсов совпадает с defaultSelect то ничего не делай (не надо делать рендер стейта)
      setSelected(predefinedValue); // а если отличается, запиши в стейт то что прилетело в predefinedValue
    }
    // eslint-disable-next-line
  }, [predefinedValue]);

  const handleSelect = (label, value) => {
    setSelected({ label, value });
    toggleOpen(false);
    onChange(value);
  };

  // вызываем в юзефекте функцию с return - это значит что юзеефект отработает сначала первый раз после рендера компонента, а потом
  // еще раз, нолько в конце жизненного цикла компонента (т.е. при измннении emptify)
  useEffect(() => {
    return () => {
      handleSelect(placeholder, "");
    };
    // eslint-disable-next-line
  }, [emptyfy]);

  // закрыть селект по клику на любую область вне селекта
  const useOnClickOutside = (ref, handler) => {
    useEffect(() => {
      const listener = (event) => {
        // ничего не далаем если клик по рефу и его потомкам
        if (!ref.current || ref.current.contains(event.target)) {
          // node.contains - вернет true\false указыв., является ли узел потомком текущего узла
          return;
        }

        handler(event);
      };
      document.addEventListener("mousedown", listener);
      document.addEventListener("touchstart", listener);

      return () => {
        document.removeEventListener("mousedown", listener);
        document.removeEventListener("touchstart", listener);
      };
    }, [ref, handler]);
  };

  useOnClickOutside(ref, () => toggleOpen(false));

  return (
    <div className="custom-select">
      <div className="custom-select__wrapper" ref={ref}>
        <p
          className="custom-select__label"
          onClick={() => (disabled ? null : toggleOpen(!open))}
        >
          {selected.label}
        </p>

        <SVG
          className="custom-select__icon"
          src={IconDeleteSVG}
          onClick={() => handleSelect(placeholder, "")} // при клике возвращаем значение в исходное (сбрасываем выбранный фильтр)
        />
        <SVG
          className="custom-select__icon"
          src={IconArrowSVG}
          onClick={() => toggleOpen(!open)} // при клике открываем/закрываем выпадашку
        />

        {open ? (
          <ul className="custom-select__list">
            {array.map(({ label, value }, index) => (
              <Raw
                label={label}
                value={value}
                key={index}
                onSelect={handleSelect}
              />
            ))}
          </ul>
        ) : null}
      </div>
    </div>
  );
};

export default Dropdown;
